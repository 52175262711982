import { useStore } from '@mirai/data-sources';
import { dateCalc, dateFormat, getDateFormat, parseDate, useLocale } from '@mirai/locale';
import { ServiceCountry, ServiceUser } from '@mirai/services';
import {
  Action,
  Button,
  Form,
  InputDate,
  InputOption,
  InputSelect,
  InputText,
  Notification,
  styles,
  Text,
  View,
} from '@mirai/ui';
import React, { useEffect, useState } from 'react';

import { L10N } from './Signup.l10n';
import * as style from './Signup.module.css';
import Skeleton from './Signup.Skeleton';
import { NotificationRequiredFields, TextRequiredFields } from '../__shared__';
import { getCountryCode, testPassword } from '../helpers';

const Signup = (others) => {
  const {
    set,
    value: { club = {}, hotel = {}, skeleton = false },
  } = useStore();
  const { locale, translate } = useLocale();

  const [busy, setBusy] = useState(false);
  const [countries, setCountries] = useState({});
  const [form, setForm] = useState({ subscribed: true });
  const [formError, setFormError] = useState({});
  const [requiredFields, setRequiredFields] = useState();
  const [response, setResponse] = useState();
  const [responseError, setResponseError] = useState();

  const [language, country] = locale.split('-');
  const formatDate = getDateFormat(locale);

  useEffect(() => {
    set({ signup: form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    (async () => {
      const next = (await ServiceCountry.list(locale)) || {};

      setCountries(next);
      setForm({ ...form, country: next[country] });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const handleSubmit = async () => {
    const hasErrors = Object.keys(formError).length !== 0;
    if (hasErrors) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return setRequiredFields(formError);
    }

    setBusy(true);
    setRequiredFields();
    setResponseError(undefined);

    const response = await ServiceUser.signup({
      ...form,
      country: getCountryCode(form.country, countries),
      dateOfBirth: dateFormat(parseDate(form.dateOfBirth, formatDate), { format: 'DD/MM/YYYY' }),
      idClub: club.id,
      idHotel: hotel.id,
      language,
    }).catch((error) => {
      setResponseError(error);
    });

    setResponse(response);
    setBusy(false);
  };

  const hasRequiredFields = Object.keys(requiredFields || {}).length > 0;

  return !skeleton ? (
    <View role="signup" tag="signup" className={style.container}>
      <View wide className={style.header}>
        <Text bold headline level={2}>
          {translate(L10N.TITLE, { clubName: club.name })}
        </Text>
        <Text className={style.description}>{translate(L10N.DESCRIPTION, { clubName: club.name })}</Text>
      </View>

      {hasRequiredFields && <NotificationRequiredFields form="signup" values={requiredFields} />}

      {!response && (
        <Form
          validateOnMount
          onChange={setForm}
          onError={setFormError}
          testId={others.testId}
          className={styles(style.form, others.className)}
        >
          <TextRequiredFields />
          <InputText
            id="signup-firstName"
            name="firstName"
            label={translate(L10N.FIRSTNAME_LABEL)}
            required
            showRequired
            success={form.firstName?.length && !formError.firstName}
            value={form.firstName}
          />
          <InputText
            id="signup-lastName"
            name="lastName"
            label={translate(L10N.LASTNAME_LABEL)}
            required
            showRequired
            success={form.lastName?.length && !formError.lastName}
            value={form.lastName}
          />
          <InputText
            id="signup-email"
            name="email"
            error={form.email?.length && !!formError.email}
            label={translate(L10N.EMAIL_LABEL)}
            required
            showRequired
            success={form.email?.length && !formError.email}
            type="email"
            value={form.email}
          />
          <InputDate
            id="signup-dateOfBirth"
            name="dateOfBirth"
            error={form.dateOfBirth?.length && !!formError.dateOfBirth}
            hint={
              form.dateOfBirth?.length && formError.dateOfBirth?.max ? translate(L10N.DATEOFBIRTH_ERROR) : undefined
            }
            format={formatDate}
            label={translate(L10N.DATEOFBIRTH_LABEL)}
            max={dateFormat(dateCalc(new Date(), -18, 'years'), { format: formatDate })}
            required
            showRequired
            success={form.dateOfBirth?.length && !formError.dateOfBirth}
            type="inputDate"
            value={form.dateOfBirth}
          />
          <InputSelect
            id="signup-country"
            name="country"
            label={translate(L10N.COUNTRY_LABEL)}
            placeholder={translate(L10N.COUNTRY_PLACEHOLDER)}
            options={Object.values(countries)}
            required
            showRequired
            success={form.country?.length && !formError.country}
            value={form.country}
          />
          <InputText
            id="signup-password"
            name="password"
            error={form.password?.length && formError.password?.test}
            hint={translate(L10N.PASSWORD_HINT)}
            label={translate(L10N.PASSWORD_LABEL)}
            required
            showRequired
            success={form.password?.length && !formError.password}
            test={testPassword}
            type="password"
            value={form.password}
          />

          <InputOption
            name="subscribed"
            checked={form.subscribed}
            label={translate(L10N.SUBSCRIBED_LABEL)}
            small
            value={form.subscribed}
          />
          <InputOption
            id="signup-privacy"
            name="privacy"
            checked={form.privacy}
            label={translate(L10N.PRIVACY_LABEL, {
              clubName: club.name || '$club',
              conditionsLink: (
                <Action
                  inline
                  href={club.url?.conditions}
                  target="_blank"
                  rel="noreferrer"
                  small
                  className={style.link}
                >
                  {translate(L10N.PRIVACY_CONDITIONS_LINK)}
                </Action>
              ),
              privacyPolicyLink: (
                <Action
                  inline
                  href={club.url?.privacy}
                  target="_blank"
                  rel="noreferrer"
                  small
                  underline
                  className={style.link}
                >
                  {translate(L10N.PRIVACY_POLICY_LINK)}
                </Action>
              ),
            })}
            required
            showRequired
            small
            value={form.privacy}
            className={style.privacy}
          />

          {!busy && responseError && (
            <Notification error className={style.notification}>
              {translate(L10N.NOTIFICATION_ERROR)}
            </Notification>
          )}

          <Button busy={busy} large wide onPress={handleSubmit} className={style.button} testId="signup-button">
            {translate(L10N.CTA)}
          </Button>
        </Form>
      )}

      {!busy && response && (
        <Notification large success className={style.notification}>
          <Text bold>{translate(L10N.SUCCESS_TITLE)}</Text>
          <Text>{translate(L10N.SUCCESS_MESSAGE)}</Text>
        </Notification>
      )}
    </View>
  ) : (
    <Skeleton />
  );
};

Signup.displayName = 'Mirai:Core:Signup';

Signup.propTypes = {};

export { Signup };
