import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Button, InputSelect, Modal, Notification, ScrollView, styles, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getCaption } from './helpers';
import { L10N } from './ModalExtras.l10n';
import * as style from './ModalExtras.module.css';
import { getGuestLabel } from '../../../helpers';
import { EVENT } from '../../../helpers';
import { getLabelTaxes } from '../../helpers';
import { PriceDetails } from '../Item/components';

const ModalExtras = ({
  busy,
  cart = [],
  extras: propExtras = [],
  taxesType,
  visible = false,
  onCancel = () => {},
  onSubmit = () => {},
  ...others
}) => {
  const { isMobile } = useDevice();
  const { currencyFormat, translate } = useLocale();
  const {
    value: { currency, finder = {}, hotel: { accommodationType: hotelAccommodationType, variants = {} } = {} } = {},
  } = useStore();

  const [extras, setExtras] = useState(propExtras);

  useEffect(() => {
    if (visible) {
      setExtras(propExtras);
      Event.publish(EVENT.METRICS, { id: 'RATES:EXTRAS' });
    }
  }, [propExtras, visible]);

  const handleChange = ({ id, field, value } = {}) => {
    // ! Why parse Number()? should come from the service.
    setExtras(extras.map((extra) => ({ ...extra, [field]: extra.id === id ? parseInt(value) : extra[field] })));
  };

  const handleSelect = (id) => {
    const next = extras.map(({ selected = false, ...extra }) => ({
      ...extra,
      selected: extra.id === id ? !selected : selected,
    }));

    setExtras(next);

    Event.publish(EVENT.METRICS, {
      id: `RATES:EXTRAS:${
        next.filter(({ selected }) => selected).length > extras.filter(({ selected }) => selected).length
          ? 'ADD'
          : 'REMOVE'
      }`,
    });
  };

  const handleSubmit = () => {
    onSubmit(
      extras
        .filter((item) => item.selected || item.included)
        .map(({ amount, baseValue, nights, id, included, valueAmount, valueNights }) => ({
          id,
          included,
          amount: parseInt(valueAmount || amount),
          nights: parseInt(valueNights || nights),
          value: baseValue[valueAmount || amount][valueNights || nights]?.price,
        })),
    );
  };

  const hasSelected = extras.filter((item) => !!item.selected).length > 0;
  const total = cart.reduce((value, { price } = {}) => value + price, 0);
  const totalExtras = extras
    .filter((item) => item.selected)
    .reduce(
      (total, { amount, nights, valueAmount, valueNights, value = {} } = {}) =>
        total + (value[valueAmount || amount][valueNights || nights]?.price || 0),
      0,
    );
  const { place: { id: [id] = [] } = {} } = finder;
  const { accommodationType = hotelAccommodationType } = variants[id] || {};
  const standaloneCard = extras.length === 1;
  const currencyFormatProps = { currency, maximumFractionDigits: 0, minimumFractionDigits: 0 };
  const { testId } = others;

  return (
    <Modal
      fit
      title={translate(L10N.LABEL_ENHANCED_YOUR_STAY)}
      visible={visible}
      onClose={onCancel}
      className={style.modal}
      testId={testId}
    >
      <ScrollView scrollIndicator scrollTo={visible ? 0.5 : undefined} snap={false} className={style.scrollView}>
        <View row className={style.container}>
          {extras.map(
            ({
              accommodationThreshold = 0,
              amount = 0,
              conditions,
              frequency,
              guestThreshold = 0,
              guestType,
              id,
              image,
              included = false,
              inputGuests = false,
              inputNights = false,
              nights = 0,
              selected = false,
              text,
              title,
              type,
              value = {},
              valueAmount = amount,
              valueNights = nights,
            } = {}) => {
              const { price: total } = value[valueAmount][valueNights] || {};

              return (
                <View
                  key={id}
                  wide
                  className={styles(
                    style.card,
                    standaloneCard && style.standalone,
                    hasSelected && selected ? style.selected : undefined,
                  )}
                >
                  <View className={style.image} style={{ backgroundImage: `url(${image})` }} />

                  <View wide className={[style.gap, style.offset, style.anchor]}>
                    <Text bold>{title}</Text>
                    {text && <Text small>{text}</Text>}

                    {accommodationThreshold > 0 && (
                      <Notification inline small warning>
                        {translate(L10N.LABEL_APPLIES_SELECTED_ROOMS, { value: accommodationThreshold })}
                      </Notification>
                    )}

                    {guestThreshold > 0 && (
                      <Notification inline small warning>
                        {translate(L10N.LABEL_APPLIES_INDIVIDUALS_RESERVATION, { value: guestThreshold })}
                      </Notification>
                    )}

                    {conditions && (
                      <Text light small={!isMobile} tiny={isMobile}>
                        {conditions}
                      </Text>
                    )}

                    {(inputGuests || inputNights) && (
                      <View row className={styles(style.gap, style.inputs)}>
                        {inputGuests && (
                          <InputSelect
                            capitalize
                            name="guests"
                            label={getGuestLabel({ plural: true, type: guestType }, translate)}
                            options={Array.from({ length: amount }).map((_, index) => index + 1)}
                            value={valueAmount?.toString()}
                            onChange={(value) => handleChange({ id, field: 'valueAmount', value })}
                          />
                        )}
                        {inputNights && (
                          <InputSelect
                            name="nights"
                            label={translate(L10N.LABEL_NIGHTS)}
                            options={Array.from({ length: nights }).map((_, index) => index + 1)}
                            value={valueNights?.toString()}
                            onChange={(value) => handleChange({ id, field: 'valueNights', value })}
                          />
                        )}
                      </View>
                    )}
                  </View>

                  <View row className={[style.offset, style.spaceBetween]}>
                    <View>
                      <Text bold headline>
                        {total ? currencyFormat({ ...currencyFormatProps, value: total }) : translate(L10N.LABEL_FREE)}
                      </Text>
                      <Text light small>
                        {getCaption(
                          {
                            accommodationType,
                            amount,
                            frequency,
                            guestType,
                            inputGuests,
                            type,
                            valueAmount,
                            valueNights,
                          },
                          translate,
                        )}
                      </Text>
                    </View>

                    <Button
                      small
                      secondary={!selected && !included}
                      onPress={!included ? () => handleSelect(id) : undefined}
                    >
                      {translate(included ? L10N.LABEL_INCLUDED : selected ? L10N.ACTION_REMOVE : L10N.ACTION_ADD)}
                    </Button>
                  </View>
                </View>
              );
            },
          )}
        </View>
      </ScrollView>

      <View row className={style.footer}>
        {total > 0 && (
          <View className={style.total}>
            <Text bold headline level={isMobile ? 3 : 2}>
              {currencyFormat({ ...currencyFormatProps, value: total + totalExtras })}
            </Text>

            {!!taxesType && (
              <Text light={!isMobile} small={!isMobile} tiny={isMobile}>
                {translate(getLabelTaxes(taxesType))}
              </Text>
            )}

            <PriceDetails
              extras={extras.filter(({ selected }) => selected)}
              cart={cart}
              metrics="RATES:EXTRAS"
              taxesType={taxesType}
              {...others}
              className={style.priceDetails}
            />
          </View>
        )}

        <Button busy={busy} onPress={handleSubmit} testId={testId ? `${testId}-submit` : undefined}>
          {translate(hasSelected ? L10N.ACTION_RESERVE : L10N.ACTION_NEXT)}
        </Button>
      </View>
    </Modal>
  );
};

ModalExtras.propTypes = {
  busy: PropTypes.bool,
  cart: PropTypes.arrayOf(PropTypes.shape({})),
  extras: PropTypes.arrayOf(PropTypes.shape({})),
  taxesType: PropTypes.number,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

ModalExtras.displayName = 'Mirai:Core:Rates:ModalExtras';

export { ModalExtras };
