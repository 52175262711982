.cvv,
.expire {
  margin-right: calc(var(--mirai-ui-space-XS) * 2);
  max-width: calc(calc(var(--mirai-ui-input-font-size) * 6.25) + calc(var(--mirai-ui-input-text-padding-x) * 2));
}

.card,
.name {
  min-width: 100%;
}

.form {
  border: 0;
  display: flex;
  flex-flow: wrap;
  margin: 0;
  padding: 0;
}

.form > div {
  margin-bottom: var(--mirai-ui-space-XS);
  margin-top: var(--mirai-ui-space-XS);
}
