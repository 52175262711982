import { useLocale } from '@mirai/locale';
import { InputDate, InputText, Form } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { L10N } from './Card.l10n';
import * as style from './Card.module.css';
import { formatValues, getCardConfig, testCard, testCVV, testExpire } from './helpers';

const DATE_FORMAT = 'MM/YY';

const Card = ({
  cards = [],
  error: { card: cardError } = {},
  showCVV = true,
  showErrors = false,
  onChange = () => {},
  onError = () => {},
  ...others
}) => {
  const { translate } = useLocale();

  const [form, setForm] = useState({});
  const [formError, setFormError] = useState({});

  useEffect(() => {
    onChange(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    onError({ ...formError });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formError]);

  const handleChange = (values) => setForm(formatValues(values, cards));

  const { testId } = others;
  const { maxLength, placeholder } = getCardConfig(form.card);

  return (
    <Form
      autoComplete="off"
      showErrors={showErrors}
      tag="fieldset"
      validateOnMount
      onChange={handleChange}
      onError={setFormError}
      className={style.form}
      testId={testId}
    >
      <InputText
        error={(form.card?.length > 0 && !!formError.card) || cardError}
        hint={cardError}
        id="checkout-card"
        label={translate(L10N.LABEL_CARD_NUMBER)}
        maxLength={maxLength.card}
        name="card"
        placeholder={placeholder.card}
        required
        showRequired
        success={form.card?.length > 0 && !formError.card}
        value={form.card}
        test={(value) => testCard(value, cards)}
        className={style.card}
        testId={testId ? `${testId}-card` : undefined}
      />
      <InputText
        error={form.name?.length === 0 && !!formError.name && !formError.card}
        id="checkout-name"
        label={translate(L10N.LABEL_CARD_NAME)}
        minLength={3}
        maxLength={100}
        name="name"
        required
        showRequired
        success={form.name?.length > 0 && !formError.name}
        value={form.name}
        className={style.name}
        testId={testId ? `${testId}-name` : undefined}
      />
      <InputDate
        error={form.expire?.length > 0 && !!formError.expire}
        format={DATE_FORMAT}
        id="checkout-expire"
        label={translate(L10N.LABEL_EXPIRATION)}
        name="expire"
        maxLength={5}
        placeholder={`${translate(L10N.LABEL_MONTH_FORMAT)}/${translate(L10N.LABEL_YEAR_FORMAT)}`}
        required
        showRequired
        success={form.expire?.length > 0 && !formError.expire}
        test={testExpire}
        type="inputDate"
        value={form.expire}
        className={style.expire}
        testId={testId ? `${testId}-expire` : undefined}
      />
      {showCVV && (
        <InputText
          error={form.CVV?.length > 0 && !!formError.CVV}
          id="checkout-CVV"
          label={translate(L10N.LABEL_CVV)}
          maxLength={maxLength.CVV}
          name="CVV"
          placeholder={placeholder.CVV}
          required
          showRequired
          success={form.CVV?.length > 0 && !formError.CVV}
          value={form.CVV}
          test={(CVV) => testCVV(CVV, maxLength.CVV)}
          className={style.cvv}
          testId={testId ? `${testId}-CVV` : undefined}
        />
      )}
    </Form>
  );
};

Card.displayName = 'Mirai:Payments:Card';

Card.propTypes = {
  cards: PropTypes.array,
  error: PropTypes.shape({
    card: PropTypes.string,
  }),
  showCVV: PropTypes.bool,
  showErrors: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
};

export { Card };
