const ACCOMMODATION_TYPE = {
  DEFAULT: 0,
  ROOM: 1,
  APARTMENT: 2,
  PITCH: 3,
  ACCOMMODATION: 4,
  VILLA: 5,
};

const AUTH_TYPE = {
  MIRAI: 1,
  PESTANA: 3,
  SENATOR: 2,
};

const CALENDAR_FORMAT = 'DD/MM/YYYY';

const COOKIE = {
  CART: 'CART_COOKIE',
  PESTANA: '_jwt',
  VALUES: 'BOOKING_VALUES',
};

const ERROR = {
  NOT_AVAILABLE: 4,
  PARAMETER_ERROR: 3,
  UNKNOWN: 1,
};

const EVENT = {
  CORE_READY: 'core:ready',
};

const TYPE = {
  CHAIN: 'chain',
  HOTEL: 'hotel',
  GUEST: 1,
  ADULT: 2,
  CHILD: 3,
};

const TYPE_COMMISSION = {
  [TYPE.HOTEL]: '02040',
  [TYPE.CHAIN]: '02080',
};

const FINDER_STORAGE_KEY = 'MIRAI:FINDER';

const MIN_STAY_TYPE = {
  CHECK_IN: 1,
  STAY: 2,
};

const PERFORMANCE = {
  ANALYTICS: 1,
  GOOGLE_ADS: 2,
  GTM: 3,
  MIRAI: 4,
  FACEBOOK: 5,
  THE_HOTEL_NETWORK: 6,
  AFFILIRED: 7,
  BING: 8,
  COMPARE: 9,
};

const TAX_DEFAULT = 1;

const TAX_FREE = 2;

const TAX_PARTIAL = 3;

const TAXES = [TAX_DEFAULT, TAX_FREE, TAX_PARTIAL];

const TAXES_TYPE = {
  ALL_EXCLUDED: 1,
  ALL_INCLUDED: 2,
  FEES_INCLUDED: 3,
  FEES_EXCLUDED: 4,
  FEES_INCLUDED_TAXES_EXCLUDED: 5,
  TAXES_INCLUDED: 6,
  TAXES_EXCLUDED: 7,
  TAXES_INCLUDED_FEES_EXCLUDED: 8,
};

const TRACKING = {
  adwordsTracking: PERFORMANCE.GOOGLE_ADS,
  affiliredTracking: PERFORMANCE.AFFILIRED,
  analyticsTracking: PERFORMANCE.ANALYTICS,
  bingTracking: PERFORMANCE.BING,
  compareTracking: PERFORMANCE.COMPARE,
  facebookTracking: PERFORMANCE.FACEBOOK,
  gtmTracking: PERFORMANCE.GTM,
  miraiTracking: PERFORMANCE.MIRAI,
  thnTracking: PERFORMANCE.THE_HOTEL_NETWORK,
};

const HOTELS_TEST_AB = [
  //ALmendros 501764
  '100169498', //Hotel
  '100377884', //Apartamentos
  //

  //Climia 500445
  '100145346', // Climia Belroy
  '100136122', // Climia Benidorm Plaza
  //

  //Garden 501404
  '100376543', // Garden Playanatural Hotel & Spa
  '100376550', // Tropic Garden Hotel Apartamentos
  '100376546', // Playa Garden Selection Hotel & Spa
  '100376970', // Nivia Born Boutique Hotel
  '100376538', // Alcudia Garden Aparthotel
  '100376549', // Green Garden Aparthotel
  '100376540', // Cala Millor Garden Hotel
  '100376544', // HYB Eurocalas
  '100376539', // Garden Holiday Village
  //

  //HM Hotels 500384
  '100376262', // HM Balanguera
  '100157742', // HM Ayron Park - Adults Only
  '100377543', // HM Palma Blanc
  '100122193', // HM Gran Fiesta
  '100376266', // Villas HM Paraíso del Mar
  '100376265', // Villas HM Palapas del Mar
  //

  //CM hotels 500045
  '100171892', // CM Mallorca Palace
  '100150560', // CM Playa del Moro
  '100123073', // CM Castell de Mar
  //

  //
  '100377473', // Casa Chinitas
  '87793081', // Brisa
  '100130702', // Don Jaime 54
  '100377431', // Boat Haus
  '100377035', // Lumberjack
  '100131215', // Marina Resort Benidorm
  '100375988', // Sunborn Gibraltar
  '100378835', // Ramada
  '100122077', // Club Mac Alcudia
  '10406219', // Liabeny
  '100169479', // Bécquer
  '100378957', // Albir Playa Hotel & Spa
  '100375723', // Suite Villa María
  '100144572', // Monte Puertatierra
  '100130250', // Monte Triana
  '100378742', // La Isla y El Mar
  '100378762', // Villas Alondra
  '100377788', // Vista Sol Punta Cana
  '100379657', //Encantada Tulum (Hotel)
  '100379658', //Radhoo Tulum (Hotel)
  '100379659', //Nest Tulum (Hotel)
  '100379660', //Bespoke (Hotel)
  '100375601', // Monte Carmelo. NO RATES
  '10573148', // Colón. NO RATES
  '100379778',
  '100379779',
  '100379780',
  '47652756',
  '88798235',
  '94274752',
  '100363512',
  '100375508',
  '100375509',
  '100378112',
  '100378762',
];

export {
  ACCOMMODATION_TYPE,
  AUTH_TYPE,
  CALENDAR_FORMAT,
  COOKIE,
  ERROR,
  EVENT,
  FINDER_STORAGE_KEY,
  HOTELS_TEST_AB,
  MIN_STAY_TYPE,
  PERFORMANCE,
  TAX_DEFAULT,
  TAXES_TYPE,
  TAXES,
  TRACKING,
  TYPE_COMMISSION,
  TYPE,
};
