import { useLocale } from '@mirai/locale';
import { Button, Icon, Input, Pressable, styles, ScrollView, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { calcColumns, normalizeString } from './helpers';
import { L10N } from './Places.l10n';
import * as style from './Places.module.css';
import { ICON } from '../../../helpers';

const Places = ({ autoColumn = true, dataSource = [], text, value, onChange = () => {}, onSubmit, ...others }) => {
  const device = useDevice();
  const { translate } = useLocale();

  const [columns, setColumns] = useState();
  const [search, setSearch] = useState();

  useEffect(() => {
    const { isMobile } = device;

    if (!isMobile && autoColumn)
      setColumns(autoColumn ? calcColumns({ dataSource, device, hasSubmit: !!onSubmit }) : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoColumn, device, dataSource]);

  const { testId } = others;

  const results = search
    ? dataSource.filter((item = {}) => normalizeString(JSON.stringify(item)).includes(normalizeString(search)))
    : dataSource;

  return (
    <View role="places" className={styles(style.container, onSubmit && style.withSubmit)}>
      {dataSource.length >= 10 && (
        <View row className={style.header}>
          <Icon value={ICON.SEARCH} />
          <Input
            placeholder={`${translate(L10N.ACTION_SEARCH)}...`}
            type="search"
            value={search}
            onChange={setSearch}
            className={style.input}
          />
        </View>
      )}

      {results.length > 0 ? (
        <ScrollView
          snap
          tag="ul"
          className={styles(style.scrollview, columns && style.columns, columns && style[`columns${columns}`])}
          testId={testId}
        >
          {results.map((item = {}, index) => {
            const { id: [idHotel] = [], isHotel, level, title, value: itemValue } = item;

            return React.createElement(
              itemValue ? Pressable : View,
              {
                key: `place:${index}`,
                tag: 'li',
                className: styles(style.item, itemValue ? itemValue === value && style.selected : style.head),
                ...(itemValue ? { onPress: () => onChange(item) } : { row: true }),
                ...(isHotel ? { ['data-hotel-id']: idHotel } : undefined),
                testId: testId ? `${testId}-item-${index}` : undefined,
              },
              <Text action={level !== 0} bold={!isHotel} markdown={false} className={style.title}>
                {'\u00A0'.repeat(level)}
                {title}
              </Text>,
            );
          })}
        </ScrollView>
      ) : null}

      {onSubmit && (
        <View className={style.footer}>
          <Button disabled={!value} onPress={onSubmit} testId={testId ? `${testId}-submit` : undefined}>
            {text || translate(L10N.ACTION_SEARCH)}
          </Button>
        </View>
      )}
    </View>
  );
};

Places.displayName = 'Mirai:Core:Finder:Places';

Places.propTypes = {
  autoColumn: PropTypes.bool,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.arrayOf(PropTypes.string).isRequired,
      isHotel: PropTypes.bool,
      level: PropTypes.number,
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ),
  text: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export { Places };
