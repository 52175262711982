import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../helpers';
import { Primitive } from '../Primitive';
import style from './Checkbox.module.css';

const Checkbox = ({ checked = false, children, disabled, error, name, value = '', onChange = () => {}, ...others }) => {
  const handleChange = (event) => {
    if (disabled) return;

    const {
      target: { checked },
    } = event;
    onChange(checked, event);
  };

  return (
    <Primitive
      role={others.role}
      tag="checkbox"
      className={styles(style.checkbox, others.className)}
      style={others.style}
    >
      <Primitive
        checked={checked}
        disabled={disabled}
        name={name}
        tag="input"
        type="checkbox"
        value={value}
        onChange={handleChange}
        testId={others.testId}
        className={styles(checked && style.checked, error && !checked && !disabled && style.error, style.input)}
        aria-label={others['aria-label']}
      />
      {children}
    </Primitive>
  );
};

Checkbox.displayName = 'Primitive:Checkbox';

Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
};

export { Checkbox };
