import { useLocale } from '@mirai/locale';
import { Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { Extras as ExtrasBase } from '../../Rates/components/ModalExtras/Extras';
import { L10N } from '../Checkout.l10n';
import * as style from '../Checkout.module.css';

const Extras = ({ dataSource: { repositories: { extras = [] } = {} } = {}, onChange }) => {
  const { isDesktop } = useDevice();
  const { translate } = useLocale();

  return extras?.length > 0 ? (
    <View id="extras" className={style.box}>
      <Text bold headline={isDesktop}>
        {translate(L10N.LABEL_ENHANCED_YOUR_STAY)}
      </Text>
      <ExtrasBase extras={extras} horizontal onChange={onChange} />
    </View>
  ) : null;
};

Extras.displayName = 'Mirai:Core:Checkout:Extras';

Extras.propTypes = {
  dataSource: PropTypes.shape({}),
  onChange: PropTypes.func,
};

export { Extras };
