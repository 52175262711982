import { request } from '@mirai/data-sources';

import { ERROR, STATUS } from './constants';

export const checkAvailability = ({ session = {}, ...others } = {}) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const params = Object.entries({
      accessClubToken: session?.authorization,
      applyClubDiscount: others.applyClubDiscount,
      checkin: others.checkin,
      clientCode: others.clientCode,
      device: others.device,
      extras: others.extras,
      from: others.from,
      googlecrawler: others.googlecrawler,
      home: others.home,
      hotelDomain: others.hotelDomain,
      hsri: others.hsri,
      idtokenprovider: others.idtokenprovider,
      lang: others.lang,
      nights: others.nights,
      offersAppliedBySelectedRoom: others.offersAppliedBySelectedRoom,
      roomNumberBySelectedRoom: others.roomNumberBySelectedRoom,
      rooms: others.rooms,
      securepage: others.securepage,
      serverNameForSSL: others.serverNameForSSL,
      totalPrice: others.totalPrice,
    }).filter(([, value]) => value !== undefined);

    const response = await request({
      endpoint: `/request_is_possible_prereserve_V2.json?${new URLSearchParams(params).toString()}`,
      hostname: process.env.SERVICE_BOOKING,
    }).catch(reject);

    if (!response) return reject(ERROR.UNKNOWN);

    const { status, data } = response;

    if (status !== STATUS.OK) {
      const { globalFormErrors: global = [], fieldFormErrors: field = [] } = data || {};

      return reject(global.length > 0 ? ERROR.NOT_AVAILABLE : field.length > 0 ? ERROR.PARAMETER_ERROR : ERROR.UNKNOWN);
    }

    resolve({});
  });
