import { dateDiff, dateFormat, isDate, translate as translateBase } from '@mirai/locale';

import { CALENDAR_FORMAT as format } from '../../helpers';
import { L10N } from '../Deals.l10n';
import { calcNightWithDeals } from '../helpers';

export const calcTooltips = ({
  focus,
  from,
  highlights,
  minNightsWithDeals,
  rangeMinDays = 1,
  to,
  translate = translateBase,
} = {}) => {
  if (!isDate(from) || (focus && !isDate(focus)) || (to && !isDate(to))) return;

  const value = {};

  if (from && !to && (rangeMinDays > 1 || minNightsWithDeals > 1)) {
    value[dateFormat(from, { format })] = {
      text: translate(minNightsWithDeals ? L10N.LABEL_MIN_NIGHTS_DEALS : L10N.LABEL_MIN_NIGHTS, {
        value: minNightsWithDeals || rangeMinDays,
      }),
      pressable: false,
    };
  }

  if (to && minNightsWithDeals && calcNightWithDeals(from, to, highlights) < minNightsWithDeals) {
    value[dateFormat(to, { format })] = {
      text: translate(L10N.LABEL_INFO_PRICES, { value: minNightsWithDeals }),
      pressable: false,
    };
  } else if (!to && from && focus && focus > from) {
    const { days } = dateDiff(from, focus);

    if (days >= minNightsWithDeals || rangeMinDays) {
      value[dateFormat(focus, { format })] = {
        text: translate(L10N.LABEL_NIGHTS, { value: days }),
        pressable: false,
      };
    }
  }

  return value;
};
