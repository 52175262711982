body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.container {
  align-items: center;
  background-color: var(--mirai-ui-content-background);
  padding: var(--mirai-ui-space-M);
  min-height: calc(100vh - var(--mirai-ui-space-XXL));
  width: 100vw;
}

.content {
  align-items: flex-start;
  gap: var(--mirai-ui-space-M);
  max-width: var(--mirai-ui-breakpoint-content);
  width: 100%;
}

.content > * {
  flex: 1;
}

.content > * > *:not(:last-child) {
  margin-bottom: var(--mirai-ui-space-M);
}

.content > .side {
  z-index: var(--mirai-ui-layer-M);
}

.box {
  background-color: var(--mirai-ui-base);
  border-radius: var(--mirai-ui-border-radius);
  box-shadow: 0 0 1px var(--mirai-ui-content-light);
  gap: var(--mirai-ui-space-M);
  padding: var(--mirai-ui-space-M);
}

.caption {
  color: var(--mirai-ui-content-light);
  margin-top: var(--mirai-ui-space-XS);
}

.wide {
  width: 100%;
}

.error {
  margin-top: var(--mirai-ui-space-XL);
}

/* == PARTIALS ============================================================== */
/* -- <Summary> ------------------------------------------------------------- */
.linkAgency {
  display: inline;
  margin-left: var(--mirai-ui-space-XS);
}

/* -- <PriceBreakdown> ------------------------------------------------------ */
.priceBreakdown {
  border-top: solid 1px var(--mirai-ui-content-border);
}

.priceBreakdown .footer {
  text-align: center;
}

/* -- <Modal> ---------------------------------------------------------------- */
.modal {
  padding: var(--mirai-ui-space-M);
}

.modal .buttons {
  gap: var(--mirai-ui-space-M);
  margin-top: var(--mirai-ui-space-M);
}

.modal .buttons ui-button {
  width: 100%;
}

/* -- <FormCancel> ---------------------------------------------------------- */
.cancel {
  margin-bottom: var(--mirai-ui-space-M);
}

/* -- <FormResend> ---------------------------------------------------------- */
.description {
  margin-bottom: var(--mirai-ui-space-S);
}

/* -- <Payment> ------------------------------------------------------------- */
.notificationPayment .action {
  color: var(--mirai-ui-accent);
}

/* S */
@media only screen and (max-width: 480px) {
  /* -- Temporal fix -------------------------------------------------------- */
  [data-role='booking'] [data-role='menu-content'] {
    position: relative;
  }

  /* -- <Payment> ------------------------------------------------------------- */
  .notificationPayment {
    gap: var(--mirai-ui-space-S);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  /* -- Temporal fix -------------------------------------------------------- */
  [data-role='booking'] [data-role='menu-content'] {
    position: relative;
  }

  /* -- <Payment> ------------------------------------------------------------- */
  .notificationPayment {
    gap: var(--mirai-ui-space-M);
  }

  .notificationPayment .action {
    min-width: fit-content;
  }
}

/* M */
@media only screen and (min-width: 481px) and (max-width: 1179px) {
  /* -- <Menu> -------------------------------------------------------------- */
  .menu {
    border-top: solid 1px var(--mirai-ui-content-border);
    background-color: var(--mirai-ui-base);
    bottom: 0;
    left: 0;
    padding: var(--mirai-ui-space-S);
    position: fixed;
    right: 0;
  }
}

/* L */
@media only screen and (min-width: 1180px) {
  .content > .side {
    max-width: calc(var(--mirai-ui-breakpoint-S) - var(--mirai-ui-space-M) * 4);
    position: sticky;
    top: var(--mirai-ui-space-M);
  }

  .error {
    max-width: 50vw;
  }

  /* -- <Menu> -------------------------------------------------------------- */
  .menu ui-menu-content {
    position: relative !important;
  }

  .menu ui-menu-content > * {
    box-shadow: 0 0 1px var(--mirai-ui-content-light);
  }
}

@media only screen {
  .print {
    display: none;
  }
}

@media print {
  .noPrint {
    display: none !important;
  }

  .content {
    flex-direction: column;
  }

  .content > * {
    width: 100%;
  }

  .box {
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding: 0 0 var(--mirai-ui-space-XS) 0;
  }

  .box h2,
  .box h3 {
    font-size: var(--mirai-ui-font-size-action);
    line-height: var(--mirai-ui-line-height-action);
  }

  .box span {
    font-size: var(--mirai-ui-font-size-small);
    line-height: var(--mirai-ui-line-height-small);
  }

  .box .priceLabel {
    margin-bottom: 0;
    margin-top: var(--mirai-ui-space-XS);
  }

  .box .price {
    font-size: var(--mirai-ui-font-size-action);
    line-height: var(--mirai-ui-line-height-action);
  }

  .item {
    margin-bottom: var(--mirai-ui-space-L);
  }

  .caption {
    margin-top: 0;
  }
}
