.button {
  margin-top: var(--mirai-ui-space-L);
}

/*  Session.Login.jsx */
.login {
  align-items: center;
  gap: var(--mirai-ui-space-XS);
}

/*  Session.Account.jsx */
.account {
  align-items: center;
}

.account .avatar {
  background-color: var(--mirai-ui-accent);
  border-radius: 50%;
  height: var(--mirai-ui-space-L);
  justify-content: center;
  overflow: hidden;
  width: var(--mirai-ui-space-L);
  min-width: var(--mirai-ui-space-L);
}

.account .avatar > * {
  color: var(--mirai-ui-base);
}

.account .texts {
  max-width: calc(var(--mirai-ui-space-L) * 3);
}

.account .texts > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.description {
  margin-bottom: var(--mirai-ui-space-S);
}

.modal {
  padding-bottom: var(--mirai-ui-space-L);
}

.modal .buttonTransparent {
  margin: 0 var(--mirai-ui-space-L);
  width: auto;
}

.notification {
  margin-top: var(--mirai-ui-space-M);
  width: 100%;
}

.info {
  margin-bottom: var(--mirai-ui-space-L);
}

.offset {
  margin: var(--mirai-ui-space-L) var(--mirai-ui-space-L) var(--mirai-ui-space-M) var(--mirai-ui-space-L);
}

.actions {
  direction: var(--mirai-ui-text-direction);
}

.wide {
  width: 100%;
}

.scrollview,
.children {
  width: 100%;
}

.pressable {
  overflow: visible;
}

/* S */
@media only screen and (max-width: 480px) {
  .modal {
    max-height: 100svh;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .account {
    border-radius: var(--mirai-ui-border-radius);
    gap: var(--mirai-ui-space-S);
    padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-S);
  }

  .account.hover:hover {
    background-color: var(--mirai-ui-accent-background);
  }

  .account.hover:hover > :nth-child(2) > * {
    color: var(--mirai-ui-accent-dark);
  }

  .children {
    align-items: stretch;
    padding: var(--mirai-ui-space-L) 0 0 var(--mirai-ui-space-L);
  }

  .children .form {
    border-left: solid 1px var(--mirai-ui-content-border);
    height: auto;
    margin-left: var(--mirai-ui-space-L);
    width: calc(var(--mirai-ui-breakpoint-S) - calc(var(--mirai-ui-space-L) * 2));
  }
}
