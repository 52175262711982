import { BookingQuery, HotelInfo } from './intents';

const INTENT = {
  BOOKING_CREATE: undefined,
  BOOKING_CANCEL: undefined,
  BOOKING_UPDATE: undefined,
  BOOKING_QUERY: BookingQuery,
  FAREWELLS: undefined,
  GREETINGS: undefined,
  HOTEL_INFO: HotelInfo,
};

export { INTENT };
