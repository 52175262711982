const COUNTRY_ES = 'ES';
const DEFAULT_LANGUAGE = 'es';

const ENDPOINT = {
  CHECKOUT: '/v2/booking/checkout',
  LEGACY: '/accept_and_reserve_V2.htm',
  UPDATE: '/v2/booking/checkout/paylater',
};

const ERROR = {
  FIELD: { code: 0, messages: 'There are some fields required', payload: undefined },
  UNKNOWN: { code: 1, messages: 'Something wrong happened. Try again', payload: undefined },
  GLOBAL: { code: 2, messages: 'There are a problem with the data send', payload: undefined },
  PARAMETER_ERROR: { code: 3, messages: 'Some parameter is incorrect', payload: undefined },
  NOT_AVAILABLE: { code: 4, messages: 'There are not available', payload: undefined },
  NOT_EXISTS: { code: 5, messages: 'There are no exists data for the request', payload: undefined },
  UNATHORIZED: {
    code: 6,
    messages: 'You do not have the necessary permissions to access this resource',
    payload: undefined,
  },
  ANONIMIZED: { code: 7, messages: "it's too late to retrieve your reservation", payload: undefined },
};

const STATE = {
  CONFIRMED: 1,
  CANCELLED: 2,
  POSTPONED: 3,
  COMPLETED: 4,
};

const STATUS = {
  OK: 'OK',
  FAIL: 'FAIL',
  NO_AVAILABLE_ROOMS: 'NO_AVAILABLE_ROOMS',
  PARAMETER_ERROR: 'PARAMETER_ERROR',
};

export { COUNTRY_ES, DEFAULT_LANGUAGE, ENDPOINT, ERROR, STATE, STATUS };
