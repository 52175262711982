import { currencyFormat } from '@mirai/locale';
import { Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './Booking.module.css';
import { getVerboseDate } from '../../../helpers';

const Booking = ({
  dataSource: {
    currency,
    hotel,
    location: { city = '' } = {},
    checkIn,
    checkOut,
    status,
    price: { total = 0 } = {},
  } = {},
  locale,
  ...others
}) => {
  return (
    <View {...others}>
      <View row className={style.title}>
        <Text action bold flex>
          {hotel}
        </Text>

        {status && (
          <Text bold tiny>
            {status}
          </Text>
        )}
      </View>
      {city && <Text small>{city}</Text>}
      {checkIn && checkOut ? (
        <Text light small>
          {getVerboseDate([checkIn, checkOut], locale)}
        </Text>
      ) : (
        <Text light small>
          {currencyFormat({ currency, locale, symbol: true, value: total })}
        </Text>
      )}
    </View>
  );
};

Booking.propTypes = {
  dataSource: PropTypes.shape({
    booking: PropTypes.string,
    currency: PropTypes.string,
    hotel: PropTypes.string,
    location: PropTypes.shape({}),
    checkIn: PropTypes.string,
    checkOut: PropTypes.string,
    status: PropTypes.string,
    price: PropTypes.shape({}),
  }),
  expanded: PropTypes.bool,
  locale: PropTypes.string,
};

export { Booking };
