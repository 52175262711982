import { parseDate, useLocale } from '@mirai/locale';
import { Button, Icon, styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { L10N } from './Chat.l10n';
import * as style from './Chat.module.css';
import { Avatar } from './components';
import { ICON } from '../helpers';

const Header = ({
  form: { dates: [checkin, checkout] = [], occupation: [occupation = []] = [] } = {},
  online,
  onClose,
}) => {
  const { dateFormat, translate } = useLocale();

  const hasDates = !!checkin && !!checkout;
  const hasOccupation = occupation.length > 0;
  const dateCheckin = parseDate(checkin);
  const dateCheckout = parseDate(checkout);

  return (
    <View className={styles(style.header, hasDates || hasOccupation)}>
      <View row className={style.content}>
        <Avatar small />
        <View wide>
          <Text bold>{translate(L10N.LABEL_ASSISTANT)}</Text>
          <View row>
            <View className={styles(style.bullet, online ? style.online : style.offline)} />
            <Text small light>
              {translate(online ? L10N.LABEL_ONLINE : L10N.LABEL_OFFLINE)}
            </Text>
          </View>
        </View>

        <View row>
          <Button small squared tooltip={translate(L10N.ACTION_CLOSE)} transparent onPress={onClose}>
            <Icon value={ICON.CLOSE} />
          </Button>
        </View>
      </View>

      {(hasDates || hasOccupation) && (
        <View row className={style.form}>
          {hasDates &&
            [dateCheckin, dateCheckout].map((date, index) => (
              <View key={`date:${index}`} row className={style.field}>
                <View>
                  <Text bold capitalize headline>
                    {dateFormat(date, { weekday: 'long' })}
                  </Text>
                  <Text small light>
                    {dateFormat(date, { day: 'numeric', month: 'long' })}
                  </Text>
                </View>

                <Text light>{'>'}</Text>
              </View>
            ))}

          {hasOccupation && (
            <View>
              <Text bold headline>
                {occupation.map(({ amount, ages = [] } = {}) => amount || ages.length).join(' + ')}
              </Text>
              <Text small light>
                {translate(L10N.LABEL_WHO)}
              </Text>
            </View>
          )}
        </View>
      )}
    </View>
  );
};

Header.displayName = 'Mirai:Core:Chat.Header';

Header.propTypes = {
  form: PropTypes.shape({
    dates: PropTypes.arrayOf(PropTypes.string),
    occupation: PropTypes.arrayOf(PropTypes.any),
  }),
  online: PropTypes.bool,
  onClose: PropTypes.func,
};

export { Header };
