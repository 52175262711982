.select {
  background-color: var(--mirai-ui-input-background);
  border: none;
  box-sizing: border-box;
  color: var(--mirai-ui-input-color);
  cursor: pointer;
  direction: var(--mirai-ui-text-direction);
  font-family: var(--mirai-ui-input-font);
  font-size: var(--mirai-ui-input-font-size);
  margin: 0;
  outline: none;
  text-align: var(--mirai-ui-text-align);
  user-select: none;
  width: 100%;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select.empty:not(:focus) {
  color: var(--mirai-ui-input-disabled);
}

.select:disabled {
  color: var(--mirai-ui-input-disabled);
  opacity: 1;
}
