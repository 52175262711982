import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../../helpers';
import { Text } from '../../../primitives';
import style from '../InputText.module.css';

const Label = ({ disabled, error, focus, label, required = false, value, ...others }) => (
  <Text
    {...others}
    tag="label"
    className={styles(
      style.text,
      others.className || style.label,
      disabled && style.disabled,
      focus && style.focus,
      error && style.error,
      (focus || error || (value !== undefined && (value?.length > 0 || typeof value === 'number'))) && style.withValue,
    )}
  >
    {label}
    {required && !disabled && <span className={style.required}> *</span>}
  </Text>
);

Label.displayName = 'Component:InputText.Label';

Label.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  focus: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
};

export { Label };
