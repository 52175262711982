import { dateCalc, dateFormat, UTC } from '@mirai/locale';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { styles } from '../../helpers';
import { Pressable, Text, View } from '../../primitives';
import { Tooltip } from '../Tooltip';
import { DAYS } from './Calendar.constants';
import style from './Calendar.module.css';
import { getFirstDateOfWeek, getToday, getFirstDayOfWeek } from './helpers';

export const Week = ({
  captions,
  disabledDatesTS = [],
  disabledPast,
  focus,
  locale,
  format,
  from,
  highlights = [],
  month,
  number,
  range,
  rangeMinDays,
  selected,
  timestamp,
  to,
  tooltips = {},
  year,
  onPress = () => {},
  onFocus = () => {},
  ...others
}) => {
  const firstDate = getFirstDateOfWeek(number, year, getFirstDayOfWeek(locale));
  const todayTS = getToday().getTime();
  const fromTS = from?.getTime();
  const toTS = to?.getTime();
  const focusTS = focus?.getTime();

  let rangeTS = {};
  if (range) {
    const [start, end] = selected;
    rangeTS = {
      start: start ? start.getTime() : undefined,
      end: end ? end.getTime() : undefined,
      min: start && rangeMinDays ? dateCalc(start, rangeMinDays, 'days').getTime() : undefined,
    };

    const [outbound] = disabledDatesTS.filter((ts) => ts > rangeTS.start).sort();
    rangeTS.outbound = outbound ? outbound - 1 : undefined;
  }

  return (
    <View forceRow className={styles(rangeTS.start !== undefined && range && style.range)}>
      {DAYS.map((day) => {
        const date = UTC(new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate() + day));
        const dateTS = date.getTime();
        const dateString = dateFormat(date, { format });

        const is = {
          disabled:
            (disabledPast && dateTS < todayTS) || // past
            !date.getMonth() === month || // days out of month
            dateTS < fromTS || // less than range
            dateTS > toTS || // more than range
            disabledDatesTS.includes(dateTS), // disabled dates
          highlight: highlights.includes(dateString),
          outOfRange: dateTS > rangeTS.outbound,
          minRange: dateTS > rangeTS.start && dateTS < rangeTS.min,
          range: dateTS > rangeTS.start && (dateTS < rangeTS.end || dateTS < rangeTS.min || dateTS < focusTS),
          rangeLimit: dateTS === rangeTS.start || dateTS === rangeTS.end,
          ranging: range && selected?.[0] && !selected[1],
          selected:
            range && selected
              ? selected[0] && !selected[1]
                ? dateTS === selected[0].getTime()
                : selected[0] && selected[1]
                ? dateTS >= selected[0].getTime() && dateTS <= selected[1].getTime()
                : undefined
              : dateTS === selected?.getTime(),
          today: dateTS === todayTS,
          visible: date.getMonth() === month,
        };

        const textStyle =
          is.disabled || (is.ranging && is.outOfRange)
            ? style.textDisabled
            : is.selected || dateTS === rangeTS.end
            ? style.textSelected
            : is.highlight && !is.range
            ? style.textHighlight
            : undefined;

        const tooltip = !is.disabled && tooltips[dateString];

        return (
          <Pressable
            disabled={is.disabled || !is.visible || (is.ranging && (is.minRange || is.outOfRange))}
            key={day}
            tabIndex={is.visible && !is.disabled ? `${date.getMonth()}-${date.getDate()}` : undefined}
            onEnter={is.ranging ? () => onFocus(date) : undefined}
            onLeave={is.ranging ? () => onFocus() : undefined}
            onPress={() => onPress(date)}
            className={style.cell}
            testId={!is.disabled && is.visible ? `${others.testId || 'calendar'}-${year}-${number}-${day}` : undefined}
          >
            {is.visible &&
              React.createElement(
                tooltip ? Tooltip : Fragment,
                tooltip ? { pressable: true, timestamp, top: true, visible: true, ...tooltip } : {},
                <View
                  className={styles(
                    style.day,
                    is.range && !is.disabled && !is.outOfRange && style.dayRange,
                    (is.rangeLimit || (!is.range && is.selected)) && style.daySelected,
                    is.rangeLimit && dateTS === rangeTS.start && style.daySelectedStart,
                    is.rangeLimit && dateTS === rangeTS.end && style.daySelectedEnd,
                    !is.selected && !is.disabled && !is.minRange && !is.outOfRange && style.dayTouchable,
                  )}
                >
                  <Text bold={is.today || is.highlight || (is.selected && !is.range)} className={textStyle}>
                    {date.getDate()}
                  </Text>

                  {captions && (
                    <Text
                      tiny
                      className={styles(
                        textStyle,
                        style.caption,
                        disabledPast && dateTS < todayTS && style.hide,
                        !is.disabled && !is.highlight && !is.selected && !is.rangeLimit && !is.range && style.color,
                      )}
                    >
                      {captions[dateString] || '-'}
                    </Text>
                  )}
                </View>,
              )}
          </Pressable>
        );
      })}
    </View>
  );
};

Week.displayName = 'Component:Calendar:Week';

Week.propTypes = {
  captions: PropTypes.shape({}),
  disabledDatesTS: PropTypes.arrayOf(PropTypes.number),
  disabledPast: PropTypes.bool,
  focus: PropTypes.object,
  highlights: PropTypes.arrayOf(PropTypes.string),
  locale: PropTypes.string,
  format: PropTypes.string,
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  tooltips: PropTypes.shape({}),
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
  range: PropTypes.bool,
  rangeMinDays: PropTypes.number,
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  timestamp: PropTypes.number,
  onFocus: PropTypes.func,
  onPress: PropTypes.func,
};
