import { dataLayerPush } from './helpers';
import { PERFORMANCE } from '../../Core.constants';

const SCRIPTS = {
  [PERFORMANCE.AFFILIRED]: {
    components: ['booking'],
    init: () => ({
      src: 'https://scripts.affilired.com/v2/tracking.php',
    }),
    once: true,
  },

  [PERFORMANCE.ANALYTICS]: {
    init: ({ idAnalytics } = {}) => ({
      src: `https://www.googletagmanager.com/gtag/js?id=${idAnalytics}`,
      onLoad: () => {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
          window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
        window.gtag('config', idAnalytics, { linker: { accept_incoming: true } });
      },
    }),
  },

  [PERFORMANCE.BING]: {
    components: ['booking'],
    init: ({ idBing }) => ({
      src: '//bat.bing.com/bat.js',
      onLoad: () => {
        // eslint-disable-next-line no-undef
        window.uetq = new UET({ ti: idBing, q: window.uetq || [] });
        window.uetq.push('pageLoad');
      },
    }),
  },

  [PERFORMANCE.COMPARE]: {
    components: ['rates', 'checkout', 'booking'],
    init: () => ({
      src: 'https://static.hotelmonitor.info/CmprmInit-min.js',
      onLoad: () => {
        document.addEventListener('CmprmInitLoaded', window.CmprmInit.InitMirai, false);
      },
    }),
    once: true,
  },

  [PERFORMANCE.GOOGLE_ADS]: {
    init: ({ cookiePrefix, idAdwords, loadedComponents = [] } = {}) => ({
      src: `https://www.googletagmanager.com/gtag/js?id=AW-${idAdwords}`,
      onLoad: () => {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
          window.dataLayer.push(arguments);
        };
        window.gtag('config', `AW-${idAdwords}`, {
          conversion_cookie_prefix: cookiePrefix,
          allow_enhanced_conversions: loadedComponents.includes('booking'),
        });
      },
    }),
  },

  [PERFORMANCE.GTM]: {
    components: ['finder', 'rates', 'checkout', 'booking'],
    init: ({ idGTM: id } = {}) => ({
      src: `https://www.googletagmanager.com/gtm.js?id=${id}`,
      onLoad: () => {
        window.dataLayer = window.dataLayer || [];
        dataLayerPush({ event: 'gtm.js', 'gtm.start': new Date().getTime() });
      },
    }),
  },

  [PERFORMANCE.FACEBOOK]: {
    components: ['rates', 'checkout', 'booking'],
  },

  [PERFORMANCE.MIRAI]: {
    components: ['finder', 'rates', 'checkout', 'booking'],
    init: ({ hotel: { id } = {} } = {}) => ({
      src: `https://metas.mirai.com/connectivity/gtag/b2c?hotelId=${id}`,
    }),
    once: true,
  },

  [PERFORMANCE.THE_HOTEL_NETWORK]: {
    components: ['finder', 'rates', 'checkout', 'booking'],
    init: ({ id = '7' }) => ({
      src: `https://www.thehotelsnetwork.com/js/hotel_price_widget.js?partnership_id=${id}`,
    }),
  },
};

export { SCRIPTS };
