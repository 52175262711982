import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Action, Button, Modal, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getDiscount } from './helpers';
import { L10N } from './ModalClubRate.l10n';
import * as style from './ModalClubRate.module.css';
import { EVENT } from '../../../helpers';
import { Modal as ModalLogin } from '../../../Session/Session.Login.Modal';

let memoizedDiscount = undefined;

const ModalClubRate = ({
  cart = [],
  dataSource: { items = [] } = {},
  visible,
  onClose = () => {},
  onSubmit = () => {},
  ...others
}) => {
  const { isMobile } = useDevice();
  const { currencyFormat, translate } = useLocale();
  const { value: { club: { name: club } = {}, currency } = {} } = useStore();
  const [login, setLogin] = useState();

  useEffect(() => {
    if (!cart.length || !cart[0]) memoizedDiscount = undefined;
  }, [cart]);

  useEffect(() => {
    if (visible) {
      Event.publish(EVENT.METRICS, { id: 'RATES:CLUB_RATE' });
    }
  }, [visible]);

  const handleCancel = () => {
    Event.publish(EVENT.RATES_FILTER_CLUB, false);
    Event.publish(EVENT.METRICS, { id: 'RATES:CLUB_RATE:CLUBDISCOUNT', value: false });
  };

  const handleClose = () => {
    setLogin(false);
    onClose();
    Event.publish(EVENT.METRICS, { id: 'RATES:CLUB_RATE:CLOSE' });
  };

  const handleSuccess = () => {
    setLogin(false);
    onSubmit(true);
    Event.publish(EVENT.METRICS, { id: 'RATES:CLUB_RATE:LOGIN_SUCCESS' });
  };
  if (!memoizedDiscount) memoizedDiscount = getDiscount({ cart, items });

  return (
    <>
      <Modal title={translate(L10N.LABEL_FARE_EXCLUSIVE_MEMBERS)} visible={visible && !login} onClose={handleClose}>
        <View {...others} className={style.container}>
          <View className={style.content}>
            <Text bold headline={!isMobile} className={style.title}>
              {translate(memoizedDiscount ? L10N.LABEL_SAVE_JOINING_CLUB : L10N.LABEL_UNLOCK_EXCLUSIVE_MEMBER, {
                club,
                discount: currencyFormat({ currency, value: memoizedDiscount }),
              })}
            </Text>
            <Button onPress={onSubmit} wide>
              {translate(L10N.ACTION_BOOK_AND_JOIN)}
            </Button>
            <Text small>{translate(L10N.LABEL_AUTOMATICALLY_JOIN_CLUB)}</Text>
          </View>
          <Button secondary wide onPress={handleCancel}>
            {translate(L10N.ACTION_SELECT_ANOTHER_FARE)}
          </Button>
          <Action onPress={() => setLogin(true)} className={style.login}>
            {translate(L10N.ACTION_LOGIN)}
          </Action>
        </View>
      </Modal>
      <ModalLogin showSignup={false} visible={visible && login} onClose={handleClose} onSuccess={handleSuccess} />
    </>
  );
};

ModalClubRate.displayName = 'Mirai:Core:Rates:ModalClubRate';

ModalClubRate.propTypes = {
  cart: PropTypes.array,
  dataSource: PropTypes.shape({}),
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export { ModalClubRate };
