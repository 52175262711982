import { dateDiff, dateFormat, parseDate } from '@mirai/locale';

import { CALENDAR_FORMAT as format } from '../../helpers';
import { INVALID_MIN_RANGE_DEALS } from '../Deals.constants';

export const calcMinStay = (from, highlights, minNightsWithDeals, rangeMinDays) => {
  let value;

  if (from && minNightsWithDeals) {
    const dateFrom = parseDate(dateFormat(from, { format }));
    const nextDealIndex = highlights.findIndex((date) => dateDiff(dateFrom, parseDate(date)).days >= 0);
    const dateTo = parseDate(highlights[nextDealIndex + minNightsWithDeals - 1]);

    value = dateTo >= dateFrom ? dateDiff(dateFrom, dateTo).days + 1 : INVALID_MIN_RANGE_DEALS;
  }

  return value || rangeMinDays;
};
