.finder {
  align-self: center;
  flex: 0;
  justify-content: center;
  overflow: hidden;
}

.finder:not(.compact) {
  background-color: var(--mirai-finder-background);
  border: var(--mirai-finder-border);
  gap: var(--mirai-finder-gap);
}

.finder:not(.column) {
  border-radius: var(--mirai-finder-border-radius);
}

.finder:not(.column):not(.compact) {
  padding: var(--mirai-finder-padding);
}

.finder.column {
  border-radius: var(--mirai-finder-column-border-radius);
  padding: var(--mirai-finder-column-padding);
  width: 100%;
}

.field {
  height: var(--mirai-finder-field-height);
}

.separator {
  background-color: var(--mirai-finder-border-color);
}

.finder.column .separator {
  height: var(--mirai-ui-border-width);
  width: 100%;
}

.finder:not(.column) .separator {
  height: var(--mirai-finder-field-height);
  width: var(--mirai-ui-border-width);
}

.button {
  height: var(--mirai-finder-field-height);
}

.button {
  font-family: var(--mirai-finder-button-font-family);
  font-size: var(--mirai-finder-button-font-size);
  font-weight: var(--mirai-finder-button-font-weight);
}

.finder:not(.compact) .button:not(:hover):not(.skeleton) {
  background: var(--mirai-finder-button-background) !important;
}

.finder:not(.compact) .button:hover:not(.skeleton) {
  background: var(--mirai-finder-button-focus-color) !important;
}

.finder.column .button {
  border-radius: var(--mirai-finder-column-border-radius);
  margin-top: var(--mirai-finder-padding);
}

.finder:not(.column):not(.compact) .button {
  border-radius: var(--mirai-finder-border-radius);
  max-height: var(--mirai-finder-field-height);
  min-height: var(--mirai-finder-field-height);
}

.finder:not(.column):not(.compact) .button.withIcon {
  max-width: var(--mirai-finder-field-height);
  min-width: var(--mirai-finder-field-height);
  padding: 0;
}

/* -- compact */
.compact {
  background-color: var(--mirai-ui-base);
  border: var(--mirai-ui-input-text-border);
  transition: border-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing),
    box-shadow var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  max-width: 100svw;
}

.compact:hover {
  border-color: var(--mirai-ui-focus-color);
  box-shadow: var(--mirai-ui-focus-shadow);
}

.compact .icon {
  color: var(--mirai-ui-content);
}

.compact .icon.skeleton {
  color: var(--mirai-ui-content-border);
}

.compact .caption {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.compact .pressable {
  align-items: flex-end;
  display: flex;
  flex-direction: var(--mirai-ui-row-direction);
  gap: var(--mirai-ui-space-XS);
}

.compact.directionLeft > .pressable:not(:last-of-type) {
  box-shadow: 1px 0 var(--mirai-ui-content-border);
}

.compact.directionRight > .pressable:not(:last-of-type) {
  box-shadow: -1px 0 var(--mirai-ui-content-border);
}

/* S & M */
@media only screen and (max-width: 1179px) {
  .finder {
    max-width: calc(100vw - calc(var(--mirai-ui-space-M) * 2));
  }
}

/* S */
@media only screen and (max-width: 480px) {
  .compact {
    flex: 1;
    justify-content: center;
    padding: var(--mirai-ui-space-XS) 0;
    width: 100%;
  }

  .compact > :not(.button) {
    padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-S);
  }

  .compact .calendar {
    justify-content: center;
    flex: 1;
  }

  .compact .promocode {
    max-width: var(--mirai-ui-space-L);
  }

  .compact .button {
    margin-right: var(--mirai-ui-space-XS);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .finder:not(.column):not(.expand) {
    width: fit-content;
  }

  .compact {
    padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-XS) var(--mirai-ui-space-XS) 0;
  }

  .compact:not(.hasPlaces) .caption {
    max-width: 25vw;
  }

  .compact.hasPlaces .caption {
    max-width: 17.5vw;
  }

  [data-mirai-component='finder']:has(.finder.expand),
  .finder.expand {
    width: 100%;
  }

  .finder.expand > ui-layer {
    flex: 1;
  }

  .finder.expand > ui-layer > ui-pressable {
    width: 100%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1179px) {
  .compact > :not(.button) {
    padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-S);
  }
}

/* L */
@media only screen and (min-width: 1180px) {
  .finder {
    max-width: var(--mirai-ui-breakpoint-content);
  }

  .compact > :not(.button) {
    padding: var(--mirai-ui-space-XXS) var(--mirai-ui-space-M);
  }
}
