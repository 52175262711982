.header {
  border-bottom: solid 1px var(--mirai-ui-content-border);
  justify-content: space-between;
  padding: var(--mirai-ui-space-XS);
}

.header .text {
  overflow: hidden;
  padding-left: var(--mirai-ui-space-XS);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.footer {
  flex: 1;
}

.notification {
  color: var(--mirai-ui-content-light);
}

@media only screen and (max-width: 480px) {
  .deals {
    padding-bottom: var(--mirai-ui-space-M);
    width: 100%;
  }

  .header {
    margin-bottom: var(--mirai-ui-space-M);
  }

  .fieldCalendar {
    margin: 0 var(--mirai-ui-space-M) var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  }

  .extras {
    gap: var(--mirai-ui-space-S);
    padding: 0 var(--mirai-ui-space-M);
  }

  .cta {
    margin-top: var(--mirai-ui-space-XS);
  }

  .notification {
    border-top: solid 1px var(--mirai-ui-content-border);
    padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  }

  .footer {
    border-top: solid 1px var(--mirai-ui-content-border);
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: var(--mirai-ui-space-M) var(--mirai-ui-space-L);
  }

  .content.calendarXS {
    max-height: calc(var(--mirai-ui-calendar-cell) * 8);
  }
}

@media only screen and (min-width: 481px) {
  .deals {
    width: fit-content;
  }

  .calendarInfo {
    padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-M) var(--mirai-ui-space-M) var(--mirai-ui-space-M);
  }

  .notification {
    max-width: var(--mirai-deals-notification-max-width);
  }

  .extras {
    border-top: solid 1px var(--mirai-ui-content-border);
    gap: var(--mirai-ui-space-XS);
    padding: var(--mirai-ui-space-M);
  }

  .footer {
    justify-content: flex-end;
  }

  .cta {
    height: var(--mirai-field-height);
  }
}

/* -- reset defaults -------------------------------------------------------- */

h2.title {
  color: var(--mirai-ui-content);
  font-size: var(--mirai-ui-font-size-headline-2);
  font-weight: var(--mirai-ui-font-weight);
  line-height: var(--mirai-ui-line-height-headline-2);
}
