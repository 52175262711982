/* eslint-disable no-console */
import { version } from '../../package.json';
import { COMPONENTS } from '../components';

const COMPONENT_KEY = 'miraiComponent';
const NAMESPACE = '[data-mirai-component]';
const CHAT = 'chat';
const SINGLETON_FINDER = ['rates'];
const UNPROCESSED_KEYS = ['role'];

export const findComponents = (urlParams = {}) => {
  const components = [];

  [...document.querySelectorAll(NAMESPACE)].forEach((el) => {
    const properties = Object.keys(el.dataset)
      .filter((key) => !UNPROCESSED_KEYS.includes(key))
      .reduce((current, name) => {
        const key = name === COMPONENT_KEY ? 'component' : name;
        const value = el.dataset[name];

        return { ...current, [key]: ['true', 'false'].includes(value) ? value === 'true' : value };
      }, {});

    const { component } = properties || {};
    const isComponent = Object.keys(COMPONENTS).includes(component);
    if (isComponent) {
      (component !== CHAT || urlParams?.lisa) && components.push({ ...properties, el });
    } else if (properties?.component)
      // eslint-disable-next-line no-console
      console.error(`Component <${properties?.component}> is not part of @mirai/core v${version}`);
  });

  const singletonFinder = !!components.find(({ component }) => SINGLETON_FINDER.includes(component));

  return singletonFinder ? components.filter(({ component }) => component !== 'finder') : components;
};
