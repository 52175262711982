import { useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { ServiceUser } from '@mirai/services';
import { Menu, Pressable, styles, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { getExternalToken, getProfileUrl } from './helpers';
import { Info } from './Session.Account.Info';
import { ENDPOINT } from './Session.constants';
import { L10N } from './Session.l10n';
import * as style from './Session.module.css';
import { AUTH_TYPE } from '../../Core.constants';
import { ICON, template, toUrlParams } from '../helpers';

const Account = ({ ghost, ...others }) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const ref = useRef(null);
  const {
    set,
    value: {
      components = {},
      club: { authentication: { type = AUTH_TYPE.MIRAI } = {} } = {},
      cookies,
      hotel: { url: hotelUrl } = {},
      id,
      session,
      ...store
    },
  } = useStore();

  const [menu, setMenu] = useState(false);

  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      if (!ref.current?.contains(target)) setMenu(undefined);
    };
    document.addEventListener('click', handleClickOutside, true);

    if (type !== AUTH_TYPE.MIRAI) {
      if (session && !getExternalToken({ cookies, type })) {
        ServiceUser.logout();
        set({ session: undefined });
      }
    }
    return () => document.removeEventListener('click', handleClickOutside, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePress = (value) => {
    if (!value) return setMenu(!menu);

    setMenu(false);
    if (value === 'logout') return handleLogout();

    const url = getProfileUrl({ id, value, ...store });
    if (!url) return;

    document.location.href = url;
  };

  const handleLogout = () => {
    ServiceUser.logout();

    if (type === AUTH_TYPE.MIRAI) {
      set({ session: undefined });

      if (components.profile) window.location.href = hotelUrl;
      else window.location.reload();

      return;
    }

    const url = `${process.env.SERVICE_USER}/${ENDPOINT.EXTERNAL_LOGOUT}${toUrlParams({
      idtokenprovider: id,
      domain: document.location.href,
    })}`;

    document.location.href = url;
  };

  const { email, firstName = '', lastName = '', level } = session || {};

  let options =
    type === AUTH_TYPE.MIRAI
      ? [
          {
            icon: ICON.PERSON,
            label: translate(L10N.ACTION_MANAGE_ACCOUNT),
            value: 'profile',
            testId: 'session-profile',
          },
          { icon: ICON.LIST, label: translate(L10N.ACTION_MY_BOOKINGS), value: 'bookings', testId: 'session-bookings' },
        ]
      : [];
  if (level) options = [{ divider: true, label: translate(L10N.LEVEL, { level }) }, ...options];
  if (!ghost && isMobile)
    options = [
      {
        children: <Info {...{ email, firstName, lastName }} />,
        divider: true,
      },
      ...options,
    ];

  return (
    <View {...others} role="account" ref={ref}>
      <Menu
        visible={menu}
        options={
          session ? [...options, { icon: ICON.LOGOUT, label: translate(L10N.CTA_LOGOUT), value: 'logout' }] : null
        }
        onPress={handlePress}
        testId="profile-menu"
      >
        <Pressable
          onPress={() => setMenu(!menu)}
          dangerouslySetInnerHTML={ghost ? { __html: template(ghost.outerHTML, session, translate) } : undefined}
          className={style.pressable}
          testId="show-profile-menu"
        >
          {!ghost ? (
            <View row className={styles(style.account, components.rates && style.hover)}>
              <View row className={style.avatar}>
                <Text action bold>
                  {`${firstName.substr(0, 1)}`.toUpperCase()}
                </Text>
              </View>
              {!isMobile && <Info {...{ email, firstName, lastName }} />}
            </View>
          ) : null}
        </Pressable>
      </Menu>
    </View>
  );
};

Account.displayName = 'Mirai:Core:Session:Account';

Account.propTypes = {
  ghost: PropTypes.any,
};

export { Account };
