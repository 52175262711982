.container {
  gap: var(--mirai-ui-space-L);
  padding: var(--mirai-ui-space-L);
}

.content {
  background-color: var(--mirai-ui-accent-background);
  border: solid 1px var(--mirai-ui-accent-border);
  padding: var(--mirai-ui-space-L);
  width: 100%;
}

.content .title {
  color: var(--mirai-ui-accent-dark);
}

.container .login {
  align-self: center;
}

/* S */
@media only screen and (max-width: 480px) {
  .content {
    gap: var(--mirai-ui-space-S);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .content {
    border: solid 1px var(--mirai-ui-accent-border);
    border-radius: var(--mirai-ui-border-radius);
    gap: var(--mirai-ui-space-M);
    max-width: calc(var(--mirai-ui-breakpoint-S) - calc(var(--mirai-ui-space-L) * 2));
  }
}
