.columns {
  align-items: flex-start;
  gap: var(--mirai-ui-space-XXS);
}

.columns > * {
  gap: var(--mirai-ui-space-XS);
}

.content {
  gap: var(--mirai-ui-space-XXS);
}

.text {
  display: -webkit-box;
  overflow: hidden;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.textDoubleLine {
  -webkit-line-clamp: 2;
}

/* $ & M */
@media only screen and (min-width: 481px) {
  .columns {
    flex-direction: var(--mirai-ui-row-direction);
    flex-wrap: wrap;
    gap: 5%;
  }

  .columns > * {
    flex-basis: 47.5%;
  }

  .oversized {
    align-items: flex-start;
    gap: var(--mirai-ui-space-S) 1%;
    flex-wrap: wrap;
  }

  .oversized > * {
    align-items: center;
    flex-basis: 24%;
    margin-bottom: var(--mirai-ui-space-XXS);
  }

  .oversized .text {
    text-align: center;
  }

  .list {
    gap: var(--mirai-ui-space-XS);
  }
}
