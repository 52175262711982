[data-mirai-modal] {
  overflow: hidden;
}

.container {
  display: flex;
  height: 100svh;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  width: 100svw;
  z-index: var(--mirai-ui-modal-layer);
}

.container.mobileBehavior {
  align-items: flex-end;
  align-content: flex-end;
  bottom: 0;
}

.container.visible {
  pointer-events: all;
}

.header {
  align-items: center;
  border-bottom: solid var(--mirai-ui-border-width) var(--mirai-ui-content-border);
  gap: var(--mirai-ui-space-XS);
  padding: var(--mirai-ui-modal-header-padding) calc(var(--mirai-ui-modal-header-padding) / 1.5);
  width: 100%;
}

.header:not(.reverse).hideBack,
.header.reverse.hideClose {
  padding-left: calc(var(--mirai-ui-modal-icon) + var(--mirai-ui-space-M));
}

.header:not(.reverse).hideClose,
.header.reverse.hideBack {
  padding-right: calc(var(--mirai-ui-modal-icon) + var(--mirai-ui-space-M));
}

.header .title {
  flex: 1;
  padding: 0 var(--mirai-ui-modal-header-padding);
  text-align: center;
}

.header .icon {
  font-size: var(--mirai-ui-modal-icon);
  height: var(--mirai-ui-modal-icon);
  line-height: var(--mirai-ui-modal-icon);
  width: var(--mirai-ui-modal-icon);
}

.modal {
  background-color: var(--mirai-ui-modal-background);
  cursor: default;
  pointer-events: none;
  transition: opacity var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing),
    transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing),
    box-shadow var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
}

.container.mobileBehavior .modal {
  transform: translateY(100%);
}

.modal.visible {
  box-shadow: var(--mirai-ui-modal-shadow);
  pointer-events: all;
}

.container.mobileBehavior .modal.visible {
  transform: translateY(0%);
}

.overflow {
  background-color: var(--mirai-ui-modal-overflow);
  opacity: 0;
  transition: backdrop-filter var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing),
    opacity var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  cursor: default;
}

.overflow.visible {
  opacity: 1;
}

.overflow.blur.visible {
  backdrop-filter: blur(4px);
}

.visible.onOverflow {
  cursor: pointer;
}

/* S */
@media only screen and (max-width: 480px) {
  .container {
    align-items: flex-end;
    align-content: flex-end;
    bottom: 0;
    height: 100svh;
  }

  .header {
    justify-content: flex-end;
  }

  .modal {
    border-top-left-radius: var(--mirai-ui-modal-border-radius);
    border-top-right-radius: var(--mirai-ui-modal-border-radius);
    transform: translateY(100%);
    width: 100%;
  }

  .modal.visible {
    transform: translateY(0%);
  }

  .overflow {
    transition-delay: calc(var(--mirai-ui-motion-expand) / 2);
  }

  .overflow.visible {
    transition-delay: 0s;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .container {
    align-items: center;
    top: 0;
    bottom: 0;
  }

  .header {
    justify-content: flex-end;
  }

  .modal:not(.mobileMode) {
    border-radius: var(--mirai-ui-modal-border-radius);
    opacity: 0;
    transform: scale(1.1);
  }

  .modal.calcWidth {
    width: calc(var(--mirai-ui-breakpoint-content) / 3);
  }

  .modal.visible:not(.mobileMode) {
    opacity: 1;
    transform: scale(1);
  }

  .pressable {
    align-self: flex-end;
  }
}
