import { parseCustomData, parseUserData, sendData } from './helpers';
import { formatPrice } from '../helpers/legacy';

export const bookingResponse = async ({ instances = [], response: { tracking = {} } = {} } = {}) => {
  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const {
    bookingValues: { nights = 0 } = {},
    guestData = {},
    hotelInfo: { currency: hotelCurrency = {}, propertyName } = {},
    idReservation,
    pendingPayment,
    reservedRooms = [],
    totalPriceWithTaxes,
  } = tracking;

  let contents = [];
  let numItems = 0;
  reservedRooms.forEach(({ averagePriceWithoutTaxes = 0, numRoomsToReserve = 1, rateId } = {}) => {
    const quantity = nights * numRoomsToReserve;

    contents.push({ id: rateId, itemPrice: formatPrice(averagePriceWithoutTaxes, hotelCurrency), quantity });
    numItems += quantity;
  });

  const commonData = {
    eventName: pendingPayment ? 'BTP' : 'Purchase',
    userData: parseUserData(guestData),
    customData: parseCustomData({
      contents,
      hotelCurrency,
      numItems,
      orderId: idReservation,
      propertyName,
      value: formatPrice(totalPriceWithTaxes, hotelCurrency),
    }),
  };

  const responses = await Promise.all(
    instances.map(({ idTracking, pixelId }) => sendData({ ...commonData, idTracking, pixelId })),
  ).catch(() => {});

  return responses;
};
