import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Pressable, styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { L10N } from './Chat.l10n';
import * as style from './Chat.module.css';
import { Avatar } from './components';
import { audioNotification } from './helpers';
import { EVENT } from '../helpers';

const DELAY_WELCOME = 10000;
let focused = false;

const ButtonAvatar = ({ ready = false, visible, onPress }) => {
  const [focus, setFocus] = useState(false);
  const [hide, setHide] = useState(false);
  const { translate } = useLocale();
  const {
    value: { session: { firstName } = {} },
  } = useStore();

  useEffect(() => {
    const callback = ({ visible: modalVisible }) => setHide(modalVisible);

    Event.subscribe(EVENT.MODAL_VISIBLE, callback);
    return () => Event.unsubscribe(EVENT.MODAL_VISIBLE, callback);
  }, []);

  useEffect(() => {
    if (!ready || focused) return;
    let inactivityTimer;

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      if (focused) return;
      inactivityTimer = setTimeout(() => {
        setFocus(true);
        audioNotification();
        clearTimeout(inactivityTimer);
      }, DELAY_WELCOME);
    };

    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('scroll', resetTimer);

    resetTimer();

    return () => {
      clearTimeout(inactivityTimer.current);
      document.removeEventListener('mousemove', resetTimer);
      document.removeEventListener('scroll', resetTimer);
    };
  }, [ready]);

  useEffect(() => {
    if (focus) focused = true;
  }, [focus]);

  return (
    <View className={styles(style.buttonAvatar, ready && !visible && !hide && style.visible)}>
      <View className={styles(style.welcome, focus && !visible && style.visible)}>
        <Text action markdown={false} medium>
          {translate(L10N.LABEL_GREETINGS, { name: firstName || translate(L10N.LABEL_GUEST) })}
        </Text>
      </View>

      <Pressable onEnter={() => setFocus(true)} onLeave={() => setFocus(false)} onPress={onPress}>
        <Avatar disabled={visible} className={style.avatar} />
      </Pressable>
    </View>
  );
};

ButtonAvatar.displayName = 'Mirai:Core:Chat.ButtonAvatar';

ButtonAvatar.propTypes = {
  ready: PropTypes.bool,
  visible: PropTypes.bool,
  onPress: PropTypes.func,
};

export { ButtonAvatar };
