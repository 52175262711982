.inputNumber {
  margin-bottom: var(--mirai-ui-input-text-gap);
}

.texts {
  flex: 1;
}

.value {
  color: var(--mirai-ui-input-color);
  display: block;
  text-align: center;
  width: var(--mirai-ui-input-number-value-width);
}

.value.disabled {
  color: var(--mirai-ui-input-text-disabled);
}

.label {
  color: var(--mirai-ui-input-text-focus);
}

.hint {
  margin: 0;
}
