.button {
  border-radius: var(--mirai-finder-border-radius);
  font-family: var(--mirai-finder-button-font-family);
  font-size: var(--mirai-finder-button-font-size);
  font-weight: var(--mirai-finder-button-font-weight);
  height: var(--mirai-finder-field-height);
  max-height: var(--mirai-finder-field-height);
  min-height: var(--mirai-finder-field-height);
}

.button:not(:hover) {
  background: var(--mirai-finder-button-background) !important;
}

.button:hover {
  background: var(--mirai-finder-button-focus-color) !important;
}
