.message {
  align-items: flex-end;
  animation: mirai-message-visible 200ms cubic-bezier(0.1, 0.1, 0.25, 0.9); /* ! TODO: Why variables dont' work? */
  padding: 0 var(--mirai-chat-space);
}

@keyframes mirai-message-visible {
  from {
    opacity: 0;
    transform: translateY(var(--mirai-chat-space));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.message.auto {
  justify-content: left;
}

.message:not(.auto) {
  justify-content: right;
}

.timestamp {
  align-self: center;
  margin-bottom: calc(var(--mirai-chat-space) / 4);
}

.actions {
  padding: calc(var(--mirai-chat-space) / 2);
  gap: var(--mirai-ui-space-XXS);
}

.content {
  border-radius: var(--mirai-chat-border-radius);
  max-width: 85%;
  overflow: hidden;
}

.content.auto:not(.busy) {
  background-color: var(--mirai-chat-message-background);
  border: solid var(--mirai-chat-border-size) var(--mirai-chat-border-color);
  border-bottom-left-radius: 0;
}

.content.busy {
  background-color: transparent;
  overflow: visible;
  padding: var(--mirai-ui-space-XS) 0;
  position: relative;
}

.content:not(.auto):not(.busy) {
  background-color: var(--mirai-chat-accent);
  border-bottom-right-radius: 0;
}

.content .text {
  font-family: var(--mirai-chat-text-font);
  font-size: var(--mirai-chat-text-font-size);
  font-weight: var(--mirai-chat-text-font-weight);
  line-height: var(--mirai-chat-text-line-height);
  padding: var(--mirai-chat-message-offset);
  width: fit-content;
}

.content.auto .text {
  color: var(--mirai-chat-text-color);
}

.content:not(.auto) .text {
  color: var(--mirai-chat-text-accent-color);
}

.content .thumbnail {
  align-self: flex-start;
  background-size: contain;
  background-color: var(--mirai-chat-border-color);
  border-radius: var(--mirai-chat-border-radius);
  margin-right: var(--mirai-chat-space);
  min-height: var(--mirai-ui-space-XL);
  min-width: var(--mirai-ui-space-XL);
}

.content .map {
  background-color: var(--mirai-chat-input-background);
  border: none;
  height: 180px;
  margin: 0;
  width: 100%;
}

.content .address {
  padding: var(--mirai-chat-message-offset);
}

.spinner {
  animation: mirai-message-spinner 0.8s infinite linear alternate;
  animation-delay: 0.4s;
  color: var(--mirai-chat-text-color);
  margin-left: calc(var(--mirai-chat-space) * 0.75);
  position: absolute;
}

.spinner,
.spinner::before,
.spinner::after {
  background-color: var(--mirai-chat-text-color);
  border-radius: 50%;
  color: var(--mirai-chat-text-color);
  height: calc(var(--mirai-chat-space) / 2.5);
  width: calc(var(--mirai-chat-space) / 2.5);
}

.spinner::before,
.spinner::after {
  animation: mirai-message-spinner 0.8s infinite alternate;
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.spinner::before {
  animation-delay: 0s;
  left: calc(var(--mirai-chat-space) * -0.65);
}
.spinner::after {
  animation-delay: 0.8s;
  left: calc(var(--mirai-chat-space) * 0.65);
}

@keyframes mirai-message-spinner {
  0% {
    background-color: var(--mirai-chat-text-color);
  }
  50%,
  100% {
    background-color: var(--mirai-chat-text-color-light);
  }
}

@media only screen and (min-width: 481px) {
  .message.auto .actions {
    opacity: 0.33;
    transition: opacity var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  }

  .message.auto:hover .actions {
    opacity: 1;
  }
}
