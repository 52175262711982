.notification {
  align-items: flex-start;
  width: fit-content;
}

.notification:not(.large):not(.small) {
  gap: var(--mirai-ui-space-XS);
}

.notification.small {
  gap: var(--mirai-ui-space-XXS);
}

.notification.large {
  gap: var(--mirai-ui-notification-padding);
}

.notification.large {
  flex-direction: column;
  align-items: center;
}

.notification.wide {
  width: 100%;
}

.notification .info {
  color: var(--mirai-ui-inysfo);
}

/* -- outlined ------------------------------------------------------------------------------------------------------ */
.notification.outlined {
  background-color: var(--mirai-ui-content-background);
  border-radius: var(--mirai-ui-notification-border-radius);
  padding: var(--mirai-ui-notification-padding);
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-content-border);
}

.notification.outlined.large {
  padding: var(--mirai-ui-notification-padding-large);
}

.notification.outlined.small {
  padding: var(--mirai-ui-notification-padding-small);
}

.notification.outlined.contrast {
  background-color: var(--mirai-ui-content-dark);
  border-color: var(--mirai-ui-content-dark);
}

.notification.outlined.error {
  background-color: var(--mirai-ui-error-background);
  border-color: var(--mirai-ui-error-border);
}

.notification.outlined.warning {
  background-color: var(--mirai-ui-warning-background);
  border-color: var(--mirai-ui-warning-border);
}

.notification.outlined.success {
  background-color: var(--mirai-ui-success-background);
  border-color: var(--mirai-ui-success-border);
}

.notification.outlined.info {
  background-color: var(--mirai-ui-info-background);
  border-color: var(--mirai-ui-info-border);
}

/* -- <Text>s ------------------------------------------------------------------------------------------------------- */
.notification .texts {
  flex: 1;
}

.notification.large .texts {
  align-items: center;
  gap: var(--mirai-ui-notification-padding-small);
}

.notification.large .texts * {
  text-align: center;
}

.notification.outlined.contrast * {
  color: var(--mirai-ui-base);
}

.notification.error * {
  color: var(--mirai-ui-error);
}

.notification.warning * {
  color: var(--mirai-ui-warning);
}

.notification.success * {
  color: var(--mirai-ui-success);
}

.notification.info * {
  color: var(--mirai-ui-info);
}
