.header {
  width: 100vw;
}

.header:not(.extra) {
  background-color: var(--mirai-header-content-background);
  border-bottom: solid 1px var(--mirai-header-content-border);
}

.content {
  gap: var(--mirai-ui-space-S);
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  max-width: var(--mirai-ui-breakpoint-content);
}

.header .main {
  gap: var(--mirai-ui-space-XL);
}

.header .main .textName {
  color: var(--mirai-header-content);
}

.header .login {
  border-radius: var(--mirai-ui-border-radius);
}

.header .login:hover,
.header .login:active {
  background-color: var(--mirai-header-content-background-hover);
}

.header .login > * {
  gap: var(--mirai-ui-space-XXS);
  height: var(--mirai-ui-space-XL);
  line-height: var(--mirai-ui-space-XL);
  padding: 0 calc(var(--mirai-ui-button-padding-x) / 2);
}

.header .login * {
  color: var(--mirai-header-content);
}

.header .login:hover *,
.header .login:active * {
  color: var(--mirai-header-content-hover);
}

.header.extra {
  background-color: var(--mirai-ui-base);
  border-bottom: solid 1px var(--mirai-ui-content-border);
}

.header.extra .content {
  min-height: calc(var(--mirai-ui-space-L) + var(--mirai-ui-space-S));
}

/* S */
@media only screen and (max-width: 480px) {
  .header {
    padding: var(--mirai-ui-space-XS) var(--mirai-ui-space-XS) var(--mirai-ui-space-XS) var(--mirai-ui-space-S);
  }

  .header .main {
    gap: var(--mirai-ui-space-L);
  }

  .main .image {
    max-height: var(--mirai-ui-space-XL);
    max-width: 40vw;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .header {
    padding: var(--mirai-ui-space-S) var(--mirai-ui-space-M);
  }

  .header .name {
    max-width: calc(var(--mirai-ui-space-XXL) * 5);
  }

  .header .options {
    gap: var(--mirai-ui-space-XXS);
  }

  .main .image {
    max-height: var(--mirai-ui-space-XL);
    max-width: calc(var(--mirai-ui-breakpoint-content) / 8);
  }
}

@media print {
  .noPrint {
    display: none;
  }

  .header:not(.extra) {
    border-bottom: none;
  }

  .main .image {
    max-height: var(--mirai-ui-space-L);
  }
}
