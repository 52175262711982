.button {
  align-items: center;
  background-color: var(--mirai-ui-button-background);
  border-radius: var(--mirai-ui-button-radius);
  color: var(--mirai-ui-button-color);
  display: flex;
  font-family: var(--mirai-ui-font);
  font-size: var(--mirai-ui-font-size-action);
  font-weight: var(--mirai-ui-button-font-weight);
  justify-content: center;
  max-height: var(--mirai-ui-button-height);
  min-height: var(--mirai-ui-button-height);
  overflow: hidden;
  padding: 0 var(--mirai-ui-button-padding-x);
  position: relative;
  text-align: center;
  transition: background-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing),
    box-shadow var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing),
    color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  width: fit-content;
}

.button,
.button .children {
  gap: var(--mirai-ui-space-XXS);
}

/* -- variants */
.button.disabled:not(.busy) {
  background-color: var(--mirai-ui-button-disabled-background);
  color: var(--mirai-ui-button-disabled-color);
}

.secondary {
  background-color: var(--mirai-ui-button-secondary-background);
  box-shadow: inset 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-button-secondary-color);
  color: var(--mirai-ui-button-secondary-color);
}

.button:not(.disabled).secondary:active,
.button:not(.disabled).secondary:hover {
  background-color: var(--mirai-ui-button-secondary-background-active);
  box-shadow: inset 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-button-secondary-focus-color);
  color: var(--mirai-ui-button-secondary-focus-color);
}

.transparent {
  background-color: transparent;
  color: var(--mirai-ui-button-transparent-color);
}

.transparent:not(.disabled):active,
.transparent:not(.disabled):hover {
  background-color: var(--mirai-ui-button-transparent-background-active);
  color: var(--mirai-ui-button-focus-color);
}

.button:not(.disabled):not(.secondary):not(.transparent):active,
.button:not(.disabled):not(.secondary):not(.transparent):hover {
  background-color: var(--mirai-ui-button-focus-color);
}

/* -- sizing */
.large {
  font-size: var(--mirai-ui-font-size-paragraph);
  max-height: var(--mirai-ui-button-large-height);
  min-height: var(--mirai-ui-button-large-height);
}

.rounded {
  border-radius: var(--mirai-ui-space-XXL);
}

.small {
  font-size: var(--mirai-ui-font-size-small);
  max-height: var(--mirai-ui-button-small-height);
  min-height: var(--mirai-ui-button-small-height);
  padding: 0 calc(var(--mirai-ui-button-padding-x) / 2);
}

.squared {
  padding: 0;
  min-width: var(--mirai-ui-button-height);
  max-width: var(--mirai-ui-button-height);
}

.large.squared {
  min-width: var(--mirai-ui-button-large-height);
  max-width: var(--mirai-ui-button-large-height);
}

.small.squared {
  min-width: var(--mirai-ui-button-small-height);
  max-width: var(--mirai-ui-button-small-height);
}

.wide {
  width: auto;
}

/* -- children */
.button .children,
.button .spinner {
  transition: opacity var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing),
    transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
}

.button.busy .children {
  opacity: 0;
  transform: translateY(var(--mirai-ui-space-XL));
}

.button .spinner {
  opacity: 0;
  transform: translateY(calc(var(--mirai-ui-space-XL) * -1));
}

.button.busy .spinner {
  opacity: 1;
  transform: translateY(0%);
}

.busyContainer {
  align-items: center;
  justify-content: center;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.busyContainer:before {
  background-color: var(--mirai-ui-base);
  content: '';
  display: inline-block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  transition: opacity var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
}

.secondary:not(.disabled) .busyContainer:before {
  background-color: var(--mirai-ui-button-background);
}

.busyContainer.active:before {
  opacity: 0.2;
}

.spinner,
.spinner::before,
.spinner::after {
  animation: mirai-button-busy calc(var(--mirai-ui-motion-expand) * 3) infinite var(--mirai-ui-motion-easing) alternate;
  background-color: var(--mirai-ui-button-color-active);
  border-radius: var(--mirai-ui-space-XXS);
  height: var(--mirai-ui-space-XS);
  width: var(--mirai-ui-space-XS);
}

.squared .spinner,
.squared .spinner::before,
.squared .spinner::after {
  height: calc(var(--mirai-ui-space-XS) / 1.5);
  width: calc(var(--mirai-ui-space-XS) / 1.5);
}

.secondary .spinner,
.secondary .spinner::before,
.secondary .spinner::after {
  animation-name: mirai-button-secondary-busy;
  background-color: var(--mirai-ui-button-secondary-color);
}

.transparent .spinner,
.transparent .spinner::before,
.transparent .spinner::after {
  animation-name: mirai-button-transparent-busy;
  background-color: var(--mirai-ui-content-border);
}

.spinner::before,
.spinner::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.spinner::before {
  animation-delay: 0s;
  left: calc(var(--mirai-ui-space-S) * -1);
}

.squared .spinner::before {
  left: calc(var(--mirai-ui-space-S) * -0.75);
}

.spinner {
  animation-delay: calc(var(--mirai-ui-motion-expand) * 2);
}

.spinner::after {
  animation-delay: calc(var(--mirai-ui-motion-expand) * 3);
  left: var(--mirai-ui-space-S);
}

.squared .spinner::after {
  left: calc(var(--mirai-ui-space-S) * 0.75);
}
