import { useLocale } from '@mirai/locale';
import { Button, Icon, Input, styles, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { ROLE } from './FieldPromocode.constants';
import { L10N } from './FieldPromocode.l10n';
import * as style from './FieldPromocode.module.css';
import { ICON } from '../../helpers';
import { Field } from '../Field';

const FieldPromocode = ({ compacted, hasPlaces, inline, visible, onPress, onSubmit, ...others }) => {
  const { translate } = useLocale();

  const handlePress = () => {
    // ! TODO: Probably will be better if we use a ref
    document.querySelector(`[data-role="${ROLE}"]`)?.focus();
    onPress && onPress();
  };

  return (
    <Field
      {...{ compacted, hasPlaces, inline, visible }}
      icon={ICON.DISCOUNT}
      inputMode={!compacted}
      label={translate(L10N.LABEL_PROMOTION)}
      onPress={handlePress}
      className={(style.container, others.className)}
      style={others.style}
      testId={others.testId ? others.testId : undefined}
    >
      <View row className={styles(compacted && style.content)}>
        {compacted && <Icon value={ICON.DISCOUNT} />}
        <Input
          name="mirai:finder:promocode"
          {...others}
          role={ROLE}
          placeholder={translate(L10N.LABEL_CODE)}
          className={styles(style.input, compacted && style.compacted)}
          testId={others.testId ? `${others.testId}-input` : undefined}
        />
        {onSubmit && <Button onPress={onSubmit}>{translate(L10N.ACTION_SEARCH)}</Button>}
      </View>
    </Field>
  );
};

FieldPromocode.displayName = 'Mirai:Core:FieldPromocode';

FieldPromocode.propTypes = {
  compacted: PropTypes.bool,
  hasPlaces: PropTypes.bool,
  inline: PropTypes.bool,
  visible: PropTypes.bool,
  onPress: PropTypes.func,
  onSubmit: PropTypes.func,
};

export { FieldPromocode };
