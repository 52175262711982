const FIELDS_COUNT = 4;

const FRAME = {
  localization: {
    cardNumberPlaceholder: '0000 0000 0000 0000',
    expiryMonthPlaceholder: 'MM',
    expiryYearPlaceholder: 'YY',
    cvvPlaceholder: '000',
  },
  style: {
    base: {
      color: '#484848', //  --mirai-ui-content
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'", // --mirai-ui-input-font
      fontSize: '16px', // --mirai-ui-input-font-size
      fontWeight: 400, // --mirai-ui-font-
      letterSpacing: 0,
    },
    placeholder: {
      base: {
        color: 'transparent',
      },
      focus: {
        color: '#999999', // --mirai-ui-input-disabled
      },
    },
  },
};

export { FIELDS_COUNT, FRAME };
