import { useLocale } from '@mirai/locale';
import { Button, Icon, Input as InputBase, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { L10N } from './Input.l10n';
import * as style from './Input.module.css';
import { ICON } from '../../../helpers';

const Input = ({ disabled = false, onValue = () => {}, ...others }) => {
  const { translate } = useLocale();

  const [value, setValue] = useState();

  const handleChange = (value) => {
    setValue(value);
  };

  const handleKeyPress = ({ key } = {}) => {
    if (key === 'Enter') handlePress();
  };

  const handlePress = () => {
    if (isEmpty) return;

    onValue(value);
    setValue();
  };

  const isEmpty = !value || value.trim().length === 0;

  return (
    <View {...others} row className={style.container}>
      <InputBase
        autoResize
        multiLine
        name="input"
        placeholder={translate(L10N.LABEL_SEND_MESSAGE)}
        value={value}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        className={style.input}
      />

      <Button disabled={disabled} small squared tooltip={translate(L10N.ACTION_SEND)} transparent onPress={handlePress}>
        <Icon light={isEmpty} value={ICON.SEND} />
      </Button>
    </View>
  );
};

Input.propTypes = {
  disabled: PropTypes.bool,
  onValue: PropTypes.func,
};

export { Input };
