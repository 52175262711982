import { Event, useStore } from '@mirai/data-sources';
import { dateDiff, useLocale } from '@mirai/locale';
import { ServiceFeatures, ServiceRates } from '@mirai/services';
import { Notification, Progress, styles, Theme, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import {
  CardHotel,
  Filters,
  Item,
  ModalCancellationInfo,
  ModalClubRate,
  ModalExtras,
  ModalItemInfo,
  ModalItemUrl,
  ModalMapView,
  ModalOfferInfo,
} from './components';
import {
  filterItem,
  getBoard,
  getCheckoutParams,
  getFeatures,
  getFinderParams,
  getForwarderUrl,
  select,
  showClubRate,
  signout,
} from './helpers';
import { FEATURES, NOTIFICATION, UTM_SOURCE_NAME } from './Rates.constants';
import { Content } from './Rates.Content';
import { Footer as RatesFooter } from './Rates.Footer';
import { Header as RatesHeader } from './Rates.Header';
import { L10N } from './Rates.l10n';
import * as style from './Rates.module.css';
import { RoomSelector } from './Rates.RoomSelector';
import Skeleton from './Rates.Skeleton';
import { Unavailability } from './Rates.Unavailability';
import { ERROR } from '../../Core.constants';
import { debounce, fetchUrlParams } from '../../helpers';
import { Cards, Footer } from '../__shared__';
import { Finder } from '../Finder';
import { Header } from '../Header';
import { EVENT, getOccupationTotals, opacity, parseToParties, replaceUrlParams, SUBMIT_DELAY } from '../helpers';

const Rates = ({ testId, ...others }) => {
  const { isDesktop, isMobile, mobile, width } = useDevice();
  const { translate } = useLocale();
  const refRoomSelector = useRef();
  const refItems = useRef({});
  const {
    set,
    value: {
      club = {},
      currency,
      finder = {},
      forwarder,
      hotel = {},
      id,
      language,
      locale,
      occupation: { maxRooms } = {},
      rates: { cart: storeCart = [], meta } = {},
      session,
      skeleton = false,
      urlParams,
      variant,
    },
  } = useStore();

  const [busy, setBusy] = useState(false);
  const [cancellationInfo, setCancellationInfo] = useState();
  const [cart, setCart] = useState([]);
  const [clubDiscount, setClubDiscount] = useState(club.discount);
  const [clubRate, setClubRate] = useState(false);
  const [dataSource, setDataSource] = useState();
  const [extras, setExtras] = useState();
  const [fetching, setFetching] = useState(undefined);
  const [filters, setFilters] = useState({});
  const [metaBoard, setMetaBoard] = useState();
  const [itemInfo, setItemInfo] = useState();
  const [itemUrl, setItemUrl] = useState();
  const [mapView, setMapView] = useState();
  const [offerInfo, setOfferInfo] = useState();
  const [responseError, setResponseError] = useState();
  const [room, setRoom] = useState();
  const [strictSearch, setStrictSearch] = useState();

  const { calendar = [], occupation = [], place } = finder;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () =>
      Event.publish(EVENT.METRICS, {
        id: 'RATES:RENDER',
        ...getFeatures(hotel.features, urlParams, { isDesktop }, multiRoom),
        multiRoom,
        variant,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const callback = async (next) => {
      setClubDiscount(typeof next !== 'object' ? next : undefined);
      clubRate && setClubRate(false);
    };

    Event.subscribe(EVENT.RATES_FILTER_CLUB, callback);

    return () => Event.unsubscribe(EVENT.RATES_FILTER_CLUB, callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubRate]);

  useEffect(() => skeleton && setFetching(true), [skeleton]);

  useEffect(() => {
    if (!dataSource || !storeCart.length) return;

    getBoard({ cart, dataSource, storeCart })
      .then((board) => {
        if (!board) return Event.publish(EVENT.METRICS, { id: 'RATES:META:ERROR' });

        if (meta) {
          Event.publish(EVENT.NOTIFICATION, {
            contrast: true,
            defaultMessage: translate(L10N.NOTIFICATION_META_RATE_FOUND, {
              utm_source: UTM_SOURCE_NAME[urlParams.utm_source] || urlParams.utm_source,
            }),
          });
          Event.publish(EVENT.METRICS, { id: 'RATES:META:SUCCESS' });
        }

        setMetaBoard(board);
        set({ rates: undefined });
      })
      .catch(() => set({ rates: undefined }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, storeCart]);

  useEffect(() => {
    if (!clubDiscount && !currency && !session && strictSearch === undefined) return;

    !clubRate && setCart([]);
    setRoom(undefined);
    debounceFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubDiscount, currency, session, strictSearch]);

  useEffect(() => {
    if (!locale) return;
    debounceFetch(room);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    if (multiRoom) {
      if (room > 0) {
        const { current: { offsetHeight = 0, offsetTop = 0 } = {} } = refRoomSelector || {};
        scrollTo(offsetTop + (isMobile ? offsetHeight : 0));
      }
      setDataSource();
    }

    room !== undefined && debounceFetch(room);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  useEffect(() => {
    if ((!dataSource && (!responseError || !responseError.tracking)) || room) return;

    Event.publish(EVENT.RATES_RESPONSE, {
      error: !!responseError,
      event: EVENT.RATES_RESPONSE,
      response: dataSource || responseError,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, responseError]);

  // -- methods --------------------------------------------------------------------------------------------------------
  const fetch = async (room, nextOccupation = occupation, nextPromocode = finder.promocode) => {
    if (skeleton) return;

    Event.publish(EVENT.FINDER, { calendar: undefined });
    setFetching(true);
    setResponseError();

    // ! TODO: [MF-2005] Use helper fetchRates() within <Rates>
    let urlParams = fetchUrlParams(location.search);
    if (!urlParams.checkin || !urlParams.nights || !urlParams.parties) {
      urlParams = { ...urlParams, ...getFinderParams({ ...finder, hotel }) };
      replaceUrlParams(urlParams);
    }

    const params = {
      ...urlParams,
      ...(club.active ? { club, clubDiscount } : undefined),
      id,
      locale,
      maxRooms,
      device,
      occupation: nextOccupation,
      place,
      selectedIds: cart.map(({ roomId } = {}) => roomId).join(','),
      session,
      strictSearch,
    };
    if (room !== undefined) params.parties = parseToParties([nextOccupation[room || 0]]);

    const nextDataSource = await ServiceRates.get(params).catch(setResponseError);
    if (nextDataSource?.externalSignout) return signout({ club, language });

    if (nextDataSource) {
      checkNotifications(nextDataSource, nextPromocode);

      if (nextOccupation?.length > 1 && room === undefined) setRoom(0);
    }

    setDataSource(nextDataSource);
    setFetching(false);

    if (nextDataSource?.hotels) Event.publish(EVENT.METRICS, { id: 'HOTELS:RENDER' });
  };

  const debounceFetch = debounce(fetch, 60);

  // -- handlers -------------------------------------------------------------------------------------------------------
  const handleFilters = (next = {}) => {
    const { strictSearch: nextStrictSearch } = next;

    setCart([]);
    setRoom(undefined);
    setFilters(next);

    if (strictSearch !== nextStrictSearch) setStrictSearch(nextStrictSearch);
  };

  const handleFinder = (values = {}) => {
    setCart([]);
    setDataSource(undefined);
    setRoom();
    setMetaBoard();
    scrollTo(0);

    replaceUrlParams(getFinderParams({ ...values, hotel }));
    debounceFetch(undefined, values.occupation, values.promocode);
  };

  const handleSelect = (board, others) => {
    const nextCart = [...cart];
    nextCart[room || 0] = board ? { ...board, ...others } : undefined;

    if (board) {
      scrollToRoom(board.roomId);

      Event.publish(EVENT.RATES_ADD_TO_CART, {
        event: EVENT.RATES_ADD_TO_CART,
        response: { board, cart: nextCart, ...dataSource },
      });
    }

    setCart(nextCart);
    setExtras();
    setMetaBoard(undefined);
    clubRate && setClubRate(false);

    if (!multiRoom && !!board) handleSubmit(nextCart);

    if (multiRoom) {
      Event.publish(EVENT.METRICS, { id: 'RATES:SELECT_ROOM' });
      if (board && room < occupation.length - 1) return setRoom(room + 1);
      if (!board) set({ rates: undefined });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = async (cart, sessionProp = session) => {
    const isPestana = ServiceFeatures.get(FEATURES.PESTANA, id) || false;
    setClubRate(false);

    if (showClubRate({ cart, clubDiscount, dataSource, isPestana, session: sessionProp })) return setClubRate(true);

    const { extras = [] } =
      (await ServiceRates.extras({
        ...getCheckoutParams({
          cart,
          clubDiscount,
          currency,
          dataSource,
          forwarder,
          urlParams: fetchUrlParams(location.search),
        }),
        ...getOccupationTotals({ infantAge: dataSource.infantAge, occupation }),
        clubDiscount: !!session || clubDiscount,
        device,
        hotelCurrency: dataSource.currency,
        locale,
      }).catch(() => {})) || {};

    if (variant === 'B')
      return handleCheckout(
        cart,
        extras
          .filter((item) => item.included)
          .map(({ amount, baseValue, nights, id, included, valueAmount, valueNights }) => ({
            id,
            included,
            amount: parseInt(valueAmount || amount),
            nights: parseInt(valueNights || nights),
            value: baseValue[valueAmount || amount][valueNights || nights]?.price,
          })),
        variant,
      );

    const selectableExtras = extras.some(({ included } = {}) => !included);

    if (selectableExtras) setExtras(extras);
    else handleCheckout(cart, extras);
  };

  const handleCheckout = (cart = [], extras = [], variant = 'A') => {
    setBusy(true);
    Event.publish(EVENT.METRICS, { id: 'RATES:SUBMIT' });

    setTimeout(() => {
      setBusy(false);
      document.location.href = getForwarderUrl({
        cart,
        clubDiscount,
        currency,
        dataSource,
        extras,
        forwarder,
        session,
        urlParams: { ...fetchUrlParams(location.search), device },
        variant,
        checkoutParams: JSON.stringify(
          getCheckoutParams({
            cart,
            clubDiscount,
            currency,
            dataSource,
            forwarder,
            urlParams: fetchUrlParams(location.search),
          }),
        ),
        occupationTotals: JSON.stringify(getOccupationTotals({ infantAge: dataSource.infantAge, occupation })),
      });
    }, SUBMIT_DELAY);
  };

  const handleCloseLogin = () => {
    multiRoom ? setClubRate(false) : handleSelect();
  };

  const checkNotifications = (nextDataSource, nextPromocode) => {
    const { notifications = [] } = nextDataSource;

    if (notifications.includes(NOTIFICATION.PROMOCODE_NOT_FOUND) && nextPromocode) {
      Event.publish(EVENT.NOTIFICATION, {
        contrast: true,
        defaultMessage: translate(L10N.NOTIFICATION_PROMOCODE_NOT_FOUND, { promocode: nextPromocode }),
      });
      set({ finder: { calendar, occupation, place, promocode: '' } });
    }
    if (notifications.includes(NOTIFICATION.ROOM_NOT_FOUNT) && urlParams.roomTypeId) {
      Event.publish(EVENT.NOTIFICATION, {
        contrast: true,
        defaultMessage: translate(L10N.NOTIFICATION_ROOM_NOT_FOUND),
      });
    }
    if (notifications.includes(NOTIFICATION.OFFER_NOT_FOUND) && urlParams.idoffers) {
      Event.publish(EVENT.NOTIFICATION, {
        contrast: true,
        defaultMessage: translate(L10N.NOTIFICATION_OFFER_NOT_FOUND),
      });
    }
  };

  const scrollTo = (value = 0) => window.scrollTo({ top: value, behavior: 'smooth' });

  const scrollToRoom = (roomId) => {
    const { top } = refItems?.current[roomId]?.getBoundingClientRect() || {};

    if (top) scrollTo(top + window.scrollY - (isMobile ? 16 : 16 * 5));
  };

  const { contentBackground } = Theme.get();
  const multiRoom = occupation?.length > 1;
  const { days: nights } = dateDiff(calendar[0], calendar[1]);
  const response = dataSource !== undefined;
  const roomSelectorProps = { cart, occupation, room, onPress: setRoom };
  const features = getFeatures(hotel.features, urlParams, { isDesktop });
  const device = mobile ? 'MOBILE' : 'DESKTOP_TABLET';
  const priceFactor = features.showPricePerNight && nights > 1 ? nights : 1;

  return (
    <>
      <Header currency login={club.active} name={features.showNameInHeader} skeleton={skeleton} />

      <View
        ref={refRoomSelector}
        className={styles(style.finder, style.blur, (!multiRoom || responseError) && style.sticky)}
        style={!isMobile ? { backgroundColor: opacity(contentBackground, 0.66) } : undefined}
      >
        <View row={isMobile} className={style.wrapper}>
          <Finder
            compacted={!isDesktop}
            icon
            metrics={`${dataSource?.hotels ? 'HOTELS' : responseError ? 'UNAVAILAVILITY' : 'RATES'}:FINDER`}
            onSubmit={handleFinder}
            testId={testId ? `${testId}-finder` : undefined}
          />
          {!responseError && <Filters {...dataSource} {...{ priceFactor }} onSubmit={handleFilters} />}
        </View>
      </View>

      <Progress indeterminate visible={fetching && !responseError} className={style.progress} />

      {isMobile && !responseError && (
        <RoomSelector {...roomSelectorProps} testId={testId ? `${testId}-multiroom` : undefined} />
      )}

      <View role="rates" testId={testId} className={styles(style.container, others.className)}>
        <View className={[style.wrapper, style.content]}>
          {!responseError && <Content {...dataSource} {...others} skeleton={skeleton} />}

          {(response || (multiRoom && !responseError)) && (
            <RatesHeader {...{ dataSource, multiRoom, onMapView: setMapView, onSelect: handleSelect }}>
              {!isMobile ? (
                <RoomSelector {...roomSelectorProps} testId={testId ? `${testId}-multiroom` : undefined} />
              ) : undefined}
            </RatesHeader>
          )}

          {response ? (
            <Cards>
              {select(dataSource.items, filters, { priceFactor })?.map(({ id, ...item }, index) => (
                <Item
                  {...{
                    ...filterItem(item, filters, priceFactor),
                    cart,
                    features,
                    filters,
                    metaBoard,
                    id,
                    multiRoom,
                    nights,
                    priceFactor,
                    room,
                    taxesType: dataSource.taxesType,
                  }}
                  key={`${id || index}:${fetching}:${width}`}
                  ref={(el) => (refItems.current[id] = el)}
                  selected={cart[room || 0]}
                  onCancellationInfo={(rate) => setCancellationInfo({ ...rate, name: item.name })}
                  onMoreInfo={() => setItemInfo(item)}
                  onOfferInfo={(rate) => setOfferInfo(rate)}
                  onSelect={handleSelect}
                  onViewUrl={(info) => setItemUrl(info)}
                  testId={testId ? `${testId}-item-${index}` : undefined}
                />
              ))}

              {dataSource.hotels?.map((item = {}) => (
                <CardHotel key={item.id} {...{ ...item, nights, priceFactor }} />
              ))}
            </Cards>
          ) : responseError ? (
            responseError.hotel || responseError.tracking?.hotelInfoBAR?.length === 0 ? (
              <Unavailability {...{ ...responseError, nights, priceFactor }} onMapView={setMapView} />
            ) : (
              <Notification error large className={style.error}>
                {translate(L10N.NOTIFICATION_ERROR[responseError.code || ERROR.UNKNOWN])}
              </Notification>
            )
          ) : (
            <Skeleton multiRoom={multiRoom} />
          )}
        </View>

        <RatesFooter
          {...{ cart: multiRoom ? cart : [], clubRate, dataSource, extras, multiRoom, occupation }}
          onSubmit={() => (multiRoom ? handleSubmit(cart) : undefined)}
          testId={testId ? `${testId}-footer` : undefined}
        />
      </View>

      {response && (
        <>
          <ModalItemInfo
            {...{ ...itemInfo, features, room }}
            visible={!!itemInfo}
            onClose={() => setItemInfo()}
            onViewUrl={(info) => setItemUrl(info)}
          />
          <ModalCancellationInfo
            {...cancellationInfo}
            visible={!!cancellationInfo}
            onClose={() => setCancellationInfo()}
          />
          <ModalItemUrl {...itemUrl} visible={!!itemUrl} onClose={() => setItemUrl()} />
          <ModalOfferInfo {...{ ...offerInfo }} visible={!!offerInfo} onClose={() => setOfferInfo()} />
          <ModalExtras
            {...{ ...dataSource, busy, cart, extras }}
            visible={!!extras}
            onCancel={() => (multiRoom ? setExtras() : handleSelect())}
            onSubmit={(extras) => handleCheckout(cart, extras)}
            testId={testId ? `${testId}-modalExtras` : undefined}
          />
          {club.active && dataSource.items?.length > 0 && (
            <ModalClubRate
              dataSource={dataSource}
              cart={cart}
              visible={clubRate}
              onClose={handleCloseLogin}
              onSubmit={(session) => handleSubmit(cart, session)}
            />
          )}
        </>
      )}

      <ModalMapView items={mapView} nights={nights} visible={!!mapView} onClose={() => setMapView()} />

      <Footer />
    </>
  );
};

Rates.displayName = 'Mirai:Core:Rates';

Rates.propTypes = {
  testId: PropTypes.string,
};

export { Rates };
