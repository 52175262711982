import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { styles } from '../../helpers';
import { useDevice } from '../../hooks';
import { Icon, ICON, ScrollView, Pressable, Text, View } from '../../primitives';
import { DIRECTION_TYPE, Theme } from '../../theme';
import { Progress } from '../Progress';
import { PRELOAD_IMAGES, SCROLL_DELAY } from './Slider.constants';
import { Item } from './Slider.Item';
import style from './Slider.module.css';

let interval;
let timeout;

const Slider = ({
  auto = false,
  behavior = 'smooth',
  captions = [],
  children,
  counter = false,
  height: propHeight = 240,
  images = [],
  index: propIndex = 0,
  indicator = false,
  preloadImages = PRELOAD_IMAGES,
  visibleItems = 1,
  width: propWidth = 320,
  onChange = () => {},
  onCounter,
  ...others
}) => {
  const { isDesktop } = useDevice();

  const [disabledScroll, setDisabledScroll] = useState(true);
  const [focus, setFocus] = useState(false);
  const [index, setIndex] = useState(propIndex);
  const [replay, setReplay] = useState(false);

  useEffect(() => {
    if (auto) interval = setInterval(() => setIndex(index < items - 1 ? index + 1 : 0), 2000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auto, index]);

  useEffect(() => {
    timeout = setTimeout(() => setDisabledScroll(false), SCROLL_DELAY);

    if (!replay && index + 1 === items) setReplay(true);

    onChange(index);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const defaultDirection = Theme.getDirection() === DIRECTION_TYPE.LEFT;
  const height = propHeight;
  const width = propWidth;
  const items = images.length || React.Children.count(children);
  const hasChildren = !!children;
  const hasItems = items > 1;
  const isEnd = Math.abs(index) >= items - 1;

  const handleIndex = (nextIndex) => {
    setDisabledScroll(true);
    setIndex(Math.abs(nextIndex) === items ? 0 : Math.abs(nextIndex) * (defaultDirection ? 1 : -1));
  };

  const handleScroll = ({ x = 0 } = {}) => {
    const nextIndex = x / width;
    if (Number.isInteger(nextIndex) && nextIndex !== index) setIndex(nextIndex);
  };

  return (
    <View
      {...others}
      tag="slider"
      onMouseEnter={isDesktop ? () => setFocus(true) : undefined}
      onMouseLeave={isDesktop ? () => setFocus(false) : undefined}
      className={styles(style.slider, others.className)}
      style={{ ...others.style, width: width * visibleItems }}
    >
      {hasItems && (
        <Pressable
          onPress={() => handleIndex(index - 1)}
          className={styles(
            style.button,
            ((defaultDirection ? index <= 0 : replay ? false : isEnd) || (isDesktop && !focus)) && style.hide,
            style.first,
          )}
        >
          <Icon value={replay && !defaultDirection && isEnd ? ICON.REPLAY : ICON.LEFT} className={style.icon} />
        </Pressable>
      )}

      <ScrollView
        behavior={behavior}
        horizontal
        scrollEventThrottle={SCROLL_DELAY}
        scrollTo={index * Math.floor(width)}
        snap
        width={width * visibleItems}
        onScroll={disabledScroll ? undefined : handleScroll}
        className={style.scrollView}
      >
        {hasChildren
          ? children
          : images.map((image, imageIndex) => (
              <View key={imageIndex} tag="images" className={style.item}>
                <Item
                  {...{ height, width }}
                  image={
                    replay ||
                    (imageIndex >= Math.abs(index) - preloadImages && imageIndex <= Math.abs(index) + preloadImages)
                      ? image
                      : undefined
                  }
                />
                {captions[imageIndex] && (
                  <View tag="image-caption" className={style.caption}>
                    <Text bold small className={[style.overlay, style.text]}>
                      {captions[imageIndex]}
                    </Text>
                  </View>
                )}
              </View>
            ))}
      </ScrollView>

      {hasItems && (
        <Pressable
          onPress={() => handleIndex(index + 1)}
          className={styles(
            style.button,
            ((defaultDirection ? (replay ? false : isEnd) : index === 0) || (isDesktop && !focus)) && style.hide,
            style.second,
          )}
        >
          <Icon
            value={replay && defaultDirection && index === items - 1 ? ICON.REPLAY : ICON.RIGHT}
            className={style.icon}
          />
        </Pressable>
      )}

      {hasItems && (onCounter || counter) && (
        <>
          {React.createElement(
            onCounter ? Pressable : View,
            {
              ...(onCounter ? { onPress: onCounter } : undefined),
              className: styles(style.overlay, style.counter, !defaultDirection && style.reverse),
            },
            <>
              <Icon action value={ICON.PHOTO_LIBRARY} />
              <Text bold small>
                {items}
              </Text>
            </>,
          )}
        </>
      )}

      {indicator && (
        <Progress value={(index * 100) / (items - 1)} className={styles(style.progress, index > 0 && style.visible)} />
      )}
    </View>
  );
};

Slider.displayName = 'Component:Slider';

Slider.propTypes = {
  auto: PropTypes.bool,
  behavior: PropTypes.string,
  captions: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  counter: PropTypes.bool,
  height: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(PropTypes.string),
  index: PropTypes.number,
  indicator: PropTypes.bool,
  preloadImages: PropTypes.number,
  replay: PropTypes.bool,
  visibleItems: PropTypes.number,
  width: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onCounter: PropTypes.func,
};

export { Slider };
