import { Icon, Text, Pressable, styles, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import * as style from './Card.module.css';

const Card = ({
  caption,
  children,
  disabled = false,
  href,
  icon,
  image,
  offset = true,
  option = true,
  text,
  // type,
  onPress,
  ...others
} = {}) => {
  const [checked, setChecked] = useState(false);

  const handlePress = () => {
    if (href) {
      const page = window.open(href, '_blank');
      page.document.title = 'Loading...';
    } else {
      if (option) setChecked(true);
      onPress();
    }
  };

  const pressable = !!onPress || !!href;

  return (
    <Pressable
      {...others}
      disabled={disabled || !pressable}
      onPress={pressable ? handlePress : undefined}
      className={styles(
        style.card,
        checked && style.checked,
        disabled && !checked && style.disabled,
        offset && style.offset,
        pressable && !disabled && style.pressable,
        others.className,
      )}
    >
      {onPress && option && <Text action>{checked ? '●' : '○'}</Text>}
      {image && <View className={style.image} style={{ backgroundImage: `url(${image})` }} />}
      {icon && <Icon value={icon} />}
      {(text || caption) && (
        <View>
          {text && (
            <Text action bold wide>
              {text}
            </Text>
          )}
          {caption && <Text small>{caption}</Text>}
        </View>
      )}

      {children && <View wide>{children}</View>}
    </Pressable>
  );
};

Card.propTypes = {
  caption: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.string,
  image: PropTypes.string,
  offset: PropTypes.bool,
  option: PropTypes.bool,
  selected: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.oneOf(['calendar', 'occupation', 'offer']),
  onPress: PropTypes.func,
};

export { Card };
