import { Event, LocalAdapter, Storage, useStore } from '@mirai/data-sources';
import { parseDate, useLocale } from '@mirai/locale';
import { ServiceCountry } from '@mirai/services';
import { InputText, Form as FormBase, Modal, ScrollView, styles, Text, useDevice } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import { BookingTerms, Payment, TextRequiredFields } from '../../__shared__';
import { EVENT, generateId, getCountryCode } from '../../helpers';
import { FIELD, FORM_STORAGE_CACHE, FORM_STORAGE_KEY, INPUT, METRIC } from '../Checkout.constants';
import { L10N } from '../Checkout.l10n';
import * as style from '../Checkout.module.css';
import { getFieldProps, getSchemaProps } from '../helpers';

const storage = new Storage({ adapter: LocalAdapter, cache: FORM_STORAGE_CACHE });

const key = (dataSource, schema) =>
  `${FORM_STORAGE_KEY}:${generateId(
    JSON.stringify({
      ...dataSource,
      schema: Object.entries(schema)
        .filter(([, value]) => value.input !== INPUT.HTML)
        .map(([key]) => key),
    }),
  )}`;

const Form = ({
  children,
  dataSource: { currency, repositories: { countries } = {}, form: schema = {}, tracking = {}, ...dataSource } = {},
  showErrors = false,
  onError = () => {},
  ...others
}) => {
  const { isDesktop } = useDevice();
  const { translate } = useLocale();
  const {
    set,
    value: { club, payment: { method } = {}, session },
  } = useStore();

  const [errorForm, setErrorForm] = useState({});
  const [errorPayment, setErrorPayment] = useState({});
  const [form, setForm] = useState(
    storage.get(key(dataSource, schema)) ||
      Object.fromEntries(Object.entries(schema).map(([key, { value }]) => [key, value])),
  );
  const [formTracked, setFormTracked] = useState();
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setForm({ ...form, ...getSchemaProps({ schema, value: session }) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  useLayoutEffect(() => {
    if (Object.keys(form).length) storage.set(key(dataSource, schema), form);
    set({ checkout: form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    onError({ ...errorForm, ...errorPayment });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorForm, errorPayment]);

  const handleChange = async (next) => {
    if (!!schema.country && next.country && !next.phone) {
      const { code } = await ServiceCountry.info(getCountryCode(next.country, countries));
      if (code) next.phone = code;
    }

    const nextFields = getFields(next);
    const nextFiltered =
      nextFields.length < getFields(form).length
        ? Object.fromEntries(Object.entries(next).filter(([key]) => Object.fromEntries(nextFields)[key] !== undefined))
        : undefined;

    setForm(nextFiltered || next);
  };

  const handleLeave = () => {
    const { email, firstName, lastName, phone } = errorForm;

    if (!formTracked && !email && !firstName && !lastName && !phone) {
      Event.publish(EVENT.CHECKOUT_FORM, { event: EVENT.CHECKOUT_FORM, response: { tracking } });
      setFormTracked(true);
    }
  };

  const handleFormError = (errors) => {
    setErrorForm(errors);

    const totalErrors = Object.keys(errors).length;
    if (totalErrors === 0) Event.publish(EVENT.METRICS, { id: `${METRIC}:FORM:SUCCESS` });
    else if (totalErrors === 1) Event.publish(EVENT.METRICS, { id: `${METRIC}:FORM:ERROR` });
  };

  const getFields = (value) =>
    Object.entries(schema)
      .filter(([key]) => !!schema[key])
      .filter(([, { visible }]) => !visible || visible(value));

  const { payment } = dataSource || {};

  return (
    <>
      <FormBase
        {...others}
        autoComplete="off"
        showErrors={showErrors}
        validateOnMount
        onChange={handleChange}
        onError={handleFormError}
        onLeave={handleLeave}
        className={styles(style.form, style.box)}
      >
        <Text bold headline={isDesktop} wide>
          {translate(L10N.LABEL_PERSONAL_DETAILS)}
        </Text>

        <TextRequiredFields wide />

        {getFields(form).map(([key, field = {}]) =>
          React.createElement(
            FIELD[field.input] || InputText,
            getFieldProps(
              key,
              field,
              { value: form[key], error: errorForm[key], session, translate, ...dataSource },
              club,
              () => setModal(true),
            ),
          ),
        )}
      </FormBase>

      {children}

      {payment && (
        <Payment
          {...{
            ...payment,
            config: { ...payment.config, publicKey: payment.config?.publicKey?.[method] },
            info: { ...payment.info, date: payment.info?.date ? parseDate(payment.info?.date) : undefined },
            showErrors,
          }}
          currency={currency}
          onError={setErrorPayment}
        />
      )}

      <Modal
        fit
        title={translate(L10N.LABEL_CONDITIONS)}
        visible={modal}
        onClose={() => setModal(false)}
        className={style.modal}
      >
        <ScrollView snap={false} vertical className={style.scrollview}>
          <BookingTerms dataSource={dataSource} small className={style.others} />
        </ScrollView>
      </Modal>
    </>
  );
};

Form.displayName = 'Mirai:Core:Checkout:Form';

Form.propTypes = {
  children: PropTypes.node,
  dataSource: PropTypes.shape({
    form: PropTypes.shape({}),
  }),
  showErrors: PropTypes.bool,
  variant: PropTypes.string,
  onError: PropTypes.func,
};

export { Form };
