import { Event, useMetrics, useStore } from '@mirai/data-sources';
import { ServiceFeatures } from '@mirai/services';
import { useDevice } from '@mirai/ui';
import { useEffect, useState } from 'react';

import { getConsentMode, getPerformanceLabel } from './helpers';
import {
  CONSENT_MODE_ANSWERED,
  CONSENT_MODE_REQUIRED,
  DEFAULT_PERFORMANCE,
  KPI_EVENTS,
} from './PerformanceManager.constants';
import { EVENTS } from './PerformanceManager.events';
import { SCRIPTS } from './PerformanceManager.scripts';
import { EVENT as COMPONENTS_EVENT } from '../../components/helpers';
import { PERFORMANCE } from '../../Core.constants';
import { fetchUrlParams, getPerformance, loadScript } from '../../helpers';

const MAX_COUNTER = 5;
const { COMPARE, GTM } = PERFORMANCE || {};

let counter = 0;
let memoizedEvents = [];
let memoizedPendingEvents = [];

const PerformanceManager = () => {
  const device = useDevice();
  const { track, trackRender } = useMetrics();
  const {
    value: { components = {}, performance = DEFAULT_PERFORMANCE || [], ...store },
  } = useStore();

  const [consentMode, setConsentMode] = useState(getConsentMode());
  const [ready, setReady] = useState(false);
  const [types, setTypes] = useState([]);

  const { hotel = {}, finder: { place } = {}, id: instanceId, locale, session, type } = store;

  useEffect(() => {
    //! TODO: temp fix for 123 compare for first phase
    window.Mirai.urlParameters = fetchUrlParams(location.search);

    const metrics = ServiceFeatures.get('metrics', hotel.id) || {};
    const bannedMetrics = Object.entries(metrics)
      .filter(([, value]) => value === false)
      .map(([key]) => key);

    const handleMetrics = ({ id, ...props }) => {
      if (!id || window.IS_PLAYWRIGHT || window.location.host === 'monalisa.mirai.com') return;
      const event = id.toUpperCase().trim();
      const [metric] = event.split(':');

      if (bannedMetrics.includes(metric) && !KPI_EVENTS.includes(event)) return;
      const method = event.includes('RENDER') ? trackRender : track;

      method(event, {
        ...props,
        hotelId: hotel.id,
        instanceId,
        locale,
        mobile: device.mobile,
        session,
        type,
      });
    };

    Event.subscribe(COMPONENTS_EVENT.METRICS, handleMetrics);

    return () => Event.unsubscribe(COMPONENTS_EVENT.METRICS, handleMetrics);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (consentMode) return;

    const interval = setInterval(async () => {
      counter !== undefined && counter++;
      const nextConsentMode = getConsentMode(counter >= MAX_COUNTER);
      const isGtm = (provider) => [COMPARE, GTM].includes(provider);

      if (
        (nextConsentMode === undefined &&
          memoizedPendingEvents.filter(({ provider } = {}) => isGtm(provider)).length === 0) ||
        (!nextConsentMode && counter < MAX_COUNTER)
      )
        return;

      await loadScripts(
        performance.filter(({ type } = {}) => type !== COMPARE),
        nextConsentMode,
      );

      const nextMemoizedPendingEvents = [];
      memoizedPendingEvents.forEach((event = {}) => {
        const { provider } = event;
        const execute = nextConsentMode || (counter >= MAX_COUNTER && CONSENT_MODE_ANSWERED.includes(provider));
        const requeue = nextConsentMode === undefined && !isGtm(provider);

        if (!execute || requeue) {
          nextMemoizedPendingEvents.push(event);
        } else {
          executeEvent(event);

          if (!nextConsentMode && !isGtm(provider)) nextMemoizedPendingEvents.push(event);
        }
      });
      memoizedPendingEvents = nextMemoizedPendingEvents;
      if (nextConsentMode !== undefined) {
        counter = undefined;
        setConsentMode(nextConsentMode);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentMode]);

  useEffect(() => {
    const { config, finder: { place: { id: [hotelId] = [], isHotel } = {} } = {} } = store;

    if (place && ready)
      loadScripts(getPerformance(config, isHotel ? hotelId : undefined)?.filter(({ type } = {}) => type !== COMPARE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place]);

  useEffect(() => {
    if (!ready) return;

    memoizedEvents.forEach((event) => executeEvent(event));
    memoizedEvents = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready]);

  useEffect(() => {
    const handleEvent = ({ event, ...value }) => {
      const currentConsentMode = getConsentMode();

      const availableProviders = performance
        .map(({ type } = {}) => type)
        .filter((type) => !(!currentConsentMode && CONSENT_MODE_ANSWERED.includes(type)))
        .filter((type) => currentConsentMode || !CONSENT_MODE_REQUIRED.includes(type));

      Object.entries(EVENTS[event]).forEach(([propProvider, callback = () => {}]) => {
        const provider = parseInt(propProvider);
        const props = { callback, event, provider, value };

        if (
          !currentConsentMode &&
          (!availableProviders.includes(provider) || (types.length > 0 && !types.includes(provider)))
        ) {
          if (counter === undefined && ready && CONSENT_MODE_ANSWERED.includes(provider)) executeEvent(props);
          else if (!memoizedPendingEvents.some((item) => item.event === event && item.provider === provider)) {
            memoizedPendingEvents.push(props);
          }
        } else if (ready) executeEvent(props);
        else memoizedEvents.push(props);
      });
    };

    Object.keys(EVENTS).forEach((event) => Event.subscribe(event, handleEvent));

    return () => Object.keys(EVENTS).forEach((event) => Event.unsubscribe(event, handleEvent));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device, ready, store]);

  const executeEvent = ({ provider, callback, value, event } = {}) => {
    const config = performance.find(({ type }) => type === provider);
    const response = callback({ ...value, ...config, device, store });
    if (!response) return;

    const label = `⚡️ ${getPerformanceLabel(provider)} ${event}`;
    // eslint-disable-next-line no-console
    response.then ? response.then((value) => console.log(label, value)) : console.log(label, response);

    if (provider === GTM) {
      loadScripts(
        performance.filter(({ type } = {}) => type === COMPARE),
        consentMode,
      );
    }
  };

  const loadScripts = async (performance = [], propConsentMode = consentMode) => {
    const loadedComponents = Object.keys(components);

    const scripts = [];
    performance
      .filter(({ type } = {}) => propConsentMode === true || !CONSENT_MODE_REQUIRED.includes(type))
      .forEach(({ type, instances: propInstances = [] } = {}) => {
        const { components = [], init = () => {}, once = false } = SCRIPTS[type] || {};

        if (components.length === 0 || components.some((item) => loadedComponents.includes(item))) {
          const instances = once ? [propInstances[0]] : propInstances;
          scripts.push(
            ...instances.map((config = {}, index) => ({
              domain: `performance-${getPerformanceLabel(type)}`,
              id: index + 1,
              ...init({ ...config, hotel, loadedComponents }),
              type,
            })),
          );
        }
      });

    setReady(false);
    await Promise.all(scripts.map((config) => loadScript(config).catch(() => {})));
    setTypes(scripts.map(({ type } = {}) => type));
    setReady(true);
  };

  return null;
};

export { PerformanceManager };
