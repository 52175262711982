import { Pressable, ScrollView, styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './InputRich.module.css';

const List = ({ options = [], value, onChange = () => {}, onValid = () => {}, ...others }) => {
  const handlePress = (id) => {
    onChange(id);
    onValid(true);
  };

  return (
    <ScrollView {...others} height={128} scrollIndicator className={styles(style.list, others.className)}>
      {options.map(({ caption, id, isHotel, text } = {}, index) => {
        const selected = isHotel && value === id;

        return (
          <Pressable
            key={index}
            onPress={isHotel ? () => handlePress(id) : undefined}
            className={styles(style.item, isHotel ? style.option : style.category, selected && style.selected)}
          >
            {isHotel && <Text action>{selected ? '●' : '○'}</Text>}
            <View>
              <Text action bold={!isHotel || !!caption}>
                {text}
              </Text>
              {caption && <Text small>{caption}</Text>}
            </View>
          </Pressable>
        );
      })}
    </ScrollView>
  );
};

List.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.label,
    }),
  ),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onValid: PropTypes.func,
};

export { List };
