import { Icon, ICON, styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './InputField.module.css';

const InputField = ({ children, error, focus, hint, label, showRequired = false, success, value, ...others }) => (
  <View {...others} className={styles(style.container, others.className)}>
    <View className={styles(style.border, error && style.error, focus && style.focus)}>
      <View wide className={style.content}>
        {label && (
          <Text
            small
            className={styles(
              style.label,
              (focus || error || (value !== undefined && (value?.length > 0 || typeof value === 'number'))) &&
                style.withValue,
            )}
          >
            {label}
            {showRequired && <span className={style.required}> *</span>}
          </Text>
        )}
        {children}
        {(error || success) && (
          <Icon
            value={error ? ICON.ERROR : ICON.SUCCESS}
            className={styles(style.icon, error && style.error, success && style.success)}
          />
        )}
      </View>
    </View>
    {hint && (
      <Text small className={style.hint}>
        {hint}
      </Text>
    )}
  </View>
);

InputField.displayName = 'Mirai:Payments:InputField';

InputField.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.bool,
  focus: PropTypes.bool,
  hint: PropTypes.string,
  label: PropTypes.string.isRequired,
  showRequired: PropTypes.bool,
  success: PropTypes.bool,
  value: PropTypes.string,
};

export { InputField };
