import { LocalAdapter, Storage } from '@mirai/data-sources';
import { COUNTRIES, dateCalc, dateDiff, dateFormat, DEFAULT_CURRENCY, parseDate, UTC } from '@mirai/locale';

import { fetchCurrency } from './fetchCurrency';
import { getAccommodationType } from './getAccommodationType';
import { getLocale } from './getLocale';
import { getMetaRates } from './getMetaRates';
import { getPerformance } from './getPerformance';
import { getTags } from './getTags';
import { prepareRoom } from './prepareRoom';
import { toOccupation } from './toOccupation';
import {
  CALENDAR_FORMAT,
  FINDER_STORAGE_KEY,
  HOTELS_TEST_AB,
  TAX_DEFAULT,
  TAXES,
  TYPE,
  TYPE_COMMISSION,
} from '../Core.constants';

const IS_JEST = !!process?.env?.JEST_WORKER_ID;

/* eslint-disable no-unused-vars */
export const consolidateStore = async ({
  components = [],
  config = {},
  cookies = {},
  instance: { el, hotel: hotelId, id, type, ...instance } = {},
  session,
  urlParams = {},
} = {}) => {
  const { miraiId } = urlParams;
  const isRates = components.some(({ component } = {}) => component === 'rates');

  // -- calendar -------------------------------------------------------------------------------------------------------
  const {
    maxDays = 365,
    maxNights: rangeMaxDays = 31,
    nightsForDeal: rangeMinDays,
    prices: showPrices = false,
    pricesWithTaxes: pricesLegend,
  } = config.accomodation?.calendar || {};
  const calendar = {
    rangeMaxDays,
    rangeMinDays,
    pricesLegend: TAXES.includes(pricesLegend) ? pricesLegend : showPrices ? TAX_DEFAULT : undefined,
    showPrices,
    to: !IS_JEST ? dateFormat(dateCalc(new Date(), maxDays, 'days'), { format: CALENDAR_FORMAT }) : undefined,
  };

  // -- language, country & locale -------------------------------------------------------------------------------------
  const { language, country, locale } = getLocale(instance, urlParams);

  // -- currency -------------------------------------------------------------------------------------------------------
  const currency =
    urlParams.currency ||
    instance.currency ||
    (type === TYPE.HOTEL && !IS_JEST ? await fetchCurrency(id) : DEFAULT_CURRENCY);

  // -- club -----------------------------------------------------------------------------------------------------------
  let club;
  const { club: { active } = {} } = config?.club || {};
  if (active) {
    let {
      accomodation: { club: { banner, discount: discountEnabled = false, rates = false } = {}, variants = [] },
      club: {
        club: { authentication, idClub: id, name },
        conditionsUrl: conditions,
        privacyPolicyUrl: privacy,
      },
    } = config;

    let { applyClubDiscount, idtokenprovider: idHotel = '' } = urlParams;
    let discount = applyClubDiscount ? applyClubDiscount === 'true' : undefined;

    if (discount === undefined) {
      discount = rates;
      if (type === TYPE.CHAIN) {
        let { discount: variantDiscount, rates: variantRates } =
          variants.find(({ id = '' } = {}) => id.toString() === idHotel.toString()) || {};
        discountEnabled = variantDiscount || discountEnabled;
        discount = variantRates || rates;
      }
    }

    club = {
      active,
      authentication,
      banner,
      discount: active && discountEnabled ? discount : undefined,
      id,
      name,
      url: { conditions, privacy },
      variants: variants
        .filter(({ club } = {}) => club)
        .map(({ club: { discount: enabled, rates: active } = {}, id } = {}) => ({ active, enabled, id })),
    };
  }

  // -- hotel ----------------------------------------------------------------------------------------------------------
  const hotel = {
    accommodationType: getAccommodationType(config.lodgingDenomination, hotelId),
    features: config.accomodation?.features || {},
    id: type === TYPE.CHAIN ? urlParams.idtokenprovider || urlParams.idHotel || hotelId : id,
    url: urlParams.home || window.location.origin,
    warningAvailability: config.minRoomsEngineNotification,
  };

  if (type === TYPE.CHAIN) {
    const { hotels = {} } = config;
    hotel.variants = {};

    Object.entries(hotels).map(([key, { lodgingDenomination: accommodationType }]) => {
      hotel.variants[key] = { accommodationType };
    });
  }

  // -- occupation -----------------------------------------------------------------------------------------------------
  const occupation = {
    dataSource: config.accomodation?.occupation || [
      { type: TYPE.ADULT, min: 1, age: [18] },
      { type: TYPE.CHILD, age: [0, 17], fulfill: true },
    ],
    maxRooms: config.accomodation?.rooms?.maxOccupation,
    variants: config.accomodation?.variants || [],
  };

  // -- origin ---------------------------------------------------------------------------------------------------------
  const origin = {
    commissionCode: urlParams.hsri || TYPE_COMMISSION[type],
  };

  // -- tags -----------------------------------------------------------------------------------------------------------
  const { chainSelector } = config;
  let tags;
  if (type === TYPE.CHAIN && chainSelector) {
    const { placeHolder = {}, trees = [] } = chainSelector;
    tags = { options: getTags(trees, language), placeholder: placeHolder[language] };
  }

  // -- finder ---------------------------------------------------------------------------------------------------------
  const storageFinder = !isRates ? new Storage({ adapter: LocalAdapter }).get(`${FINDER_STORAGE_KEY}:${id}`) || {} : {};

  let { chainSelectorValue, checkin, clientCode, nights = isRates ? 1 : 0, parties, promo: promoCode } = urlParams;
  checkin = checkin ? parseDate(checkin) : isRates ? UTC(new Date()) : undefined;
  let checkout;

  if (checkin) checkout = dateCalc(checkin, parseInt(nights), 'days');

  let promo;
  if (config.showPromoNav) {
    const { accomodation: { promo: configPromo = {} } = {}, promoText: placeholder = {} } = config;
    promo = { ...configPromo, placeholder };
  }

  let place;
  if (tags) {
    place = tags.options.find(
      ({ id: [idHotel] = [], isHotel, value } = {}) =>
        (chainSelectorValue && value === Number(chainSelectorValue)) || (isHotel && hotel.id === idHotel),
    );

    const { id: [id] = [] } = place || {};
    const { accommodationType } = hotel.variants[id] || {};
    if (accommodationType) hotel.accommodationType = accommodationType;
  }

  let finder = {
    place: place || storageFinder.place,
    calendar: checkin
      ? [checkin, checkout]
      : storageFinder.calendar && dateDiff(new Date(), new Date(storageFinder.calendar[0])).days > -1
      ? [new Date(storageFinder.calendar[0]), new Date(storageFinder.calendar[1])]
      : [],
    occupation: parties
      ? toOccupation({ chainSelectorValue, occupation, parties, places: tags?.options })
      : isRates
      ? [prepareRoom(occupation.dataSource)]
      : storageFinder.occupation || undefined,
    promocode: clientCode || promoCode || promo?.code || storageFinder.promocode,
  };

  // -- forwarder ------------------------------------------------------------------------------------------------------
  const host = `${document.location.protocol}//${document.location.host}`;
  const isDevelopment = process.env.NODE_ENV === 'development' || window.IS_PLAYWRIGHT;
  const forwarder = {
    rates: miraiId ? `${host}/step1-rates` : `${process.env.SERVICE_BOOKING}/miraiFrontDispatcher.htm`,
    checkout: miraiId ? `${host}/step2-checkout` : `${process.env.SERVICE_BOOKING}/forwarder_client_form.htm`,
    booking: miraiId ? `${host}/step3-booking` : `${process.env.SERVICE_BOOKING}/forwarder_manage_reservation.htm`,
    signup:
      miraiId || isDevelopment
        ? `${host}/signup`
        : `${process.env.SERVICE_USER}/club/v1/forwarder/registration_process`,
    profile:
      miraiId || isDevelopment ? `${host}/profile` : `${process.env.SERVICE_USER}/club/v1/forwarder/members_area`,
  };

  return {
    config,
    cookies,
    urlParams,
    ...instance,
    id,
    type,

    // region
    currency,
    language,
    country,
    locale,
    locales: config.languages
      ?.map((language) => (language === 'no' ? 'nb' : language))
      .map((language) => `${language}-${COUNTRIES[language]}`),

    // entities,
    calendar,
    club,
    components: components.reduce((value, { component, ...props }) => {
      value[component] = props;
      return value;
    }, {}),
    forwarder,
    hotel,
    occupation,
    origin,
    performance: getPerformance(config, hotel.id),
    promo,
    session,
    tags,
    variant: IS_JEST
      ? undefined
      : urlParams.variant
      ? urlParams.variant
      : !HOTELS_TEST_AB.includes(hotel.id) || Math.random() < 0.5
      ? 'A'
      : 'B',
    // -- components
    checkout: !IS_JEST && urlParams.checkoutForm ? JSON.parse(urlParams.checkoutForm) : undefined,
    deals: undefined,
    finder,
    lisa: undefined, // ! TODO: Rename to chat
    rates: {
      cart: urlParams.cart ? urlParams.cart.split(',') : urlParams.rates ? getMetaRates(urlParams.rates) : undefined,
      meta: !!urlParams.rates,
    },
    signup: undefined,
  };
};
