import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { Primitive } from '../../primitives';
import {
  DEFAULT_TIMEOUT_ONCHANGE,
  DEFAULT_TIMEOUT_ONERROR,
  FIELDS_BOOLEAN,
  FIELDS_INPUT,
  FIELDS_WITHOUT_ERROR,
} from './Form.constants';
import { getChildrenErrors, getChildrenValues, getField, groupState } from './helpers';

let timeoutError;

const Form = ({
  children,
  debounce = DEFAULT_TIMEOUT_ONCHANGE,
  schema = {},
  showErrors = false,
  validateOnMount = false,
  onChange,
  onEnter,
  onError,
  onLeave,
  onSubmit,
  ...others
}) => {
  const [error, setError] = useState({});
  const [initialValue, setInitialValue] = useState({});
  const [touched, setTouched] = useState({});
  const [values, setValues] = useState({});

  useEffect(() => {
    const nextValues = getChildrenValues(children);
    const nextChildrenKeys = Object.keys(nextValues).sort();

    if (!Object.keys(nextValues).length) return;

    if (JSON.stringify(nextChildrenKeys) !== JSON.stringify(Object.keys(initialValue).sort())) {
      setInitialValue(nextValues);
      setValues(nextValues);
      clearTimeout(timeoutError);

      if (validateOnMount) handleError(nextValues);
      else setError({});

      setTouched({});
    } else {
      const collision = nextChildrenKeys.some((key) => JSON.stringify(values[key]) !== JSON.stringify(nextValues[key]));
      if (collision) {
        setValues(nextValues);
        handleError(nextValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  useEffect(() => {
    if (!onChange || values === initialValue || !Object.keys(values).length) return;

    if (!debounce) return onChange(values, groupState({ initialValue, value: values, touched }));

    const timer = setTimeout(() => onChange(values, groupState({ initialValue, value: values, touched })), debounce);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleChange = (field, fieldValue) => {
    const nextValues = { ...values, [field]: fieldValue };

    setValues(nextValues);
    timeoutError = setTimeout(() => handleError(nextValues), DEFAULT_TIMEOUT_ONERROR);
  };

  const handleError = (values) => {
    const next = getChildrenErrors({ children, schema, values });

    setError(next);
    if (JSON.stringify(error) !== JSON.stringify(next)) onError && onError(next);

    return next;
  };

  const handleLeave = (field, event) => {
    setTouched({ ...touched, [field]: true });
    if (onEnter) onEnter(field, event);
  };

  const handleKeyUp = (event) => {
    if (event.key !== 'Enter' || Object.keys(error).length) return;
    handleSubmit(event);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = handleError(values);

    if (Object.keys(errors).length) onError(errors);
    else if (onSubmit) onSubmit(values, groupState({ initialValue, value: values, touched }), event);
  };

  return useMemo(
    () =>
      React.createElement(
        Primitive,
        {
          ...others,
          tag: 'form',
          onSubmit: handleSubmit,
        },
        React.Children.map(children, (child, index) => {
          if (!child || child === null) return;

          const { props = {}, type: { displayName } = {} } = child || {};
          const { type } = props;
          const field = getField(props);

          return React.cloneElement(child, {
            key: index,
            ...(field
              ? {
                  ...props,
                  ...schema[field],
                  error: !FIELDS_WITHOUT_ERROR.includes(displayName) && (props.error || (showErrors && !!error[field])),
                  value: !FIELDS_BOOLEAN.includes(displayName) ? values[field] : props.value,
                  onChange: (value) => handleChange(field, value),
                  onEnter: (event) => handleLeave(field, event),
                  onKeyUp: FIELDS_INPUT.includes(displayName) ? handleKeyUp : undefined,
                  onLeave: onLeave ? (event) => onLeave(field, event) : undefined,
                }
              : type === 'submit'
              ? { ...props, onPress: handleSubmit }
              : undefined),
          });
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children, error, others, schema],
  );
};

Form.displayName = 'Component:Form';

Form.propTypes = {
  children: PropTypes.node,
  debounce: PropTypes.number,
  schema: PropTypes.shape({}),
  showErrors: PropTypes.bool,
  validateOnMount: PropTypes.bool,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onError: PropTypes.func,
  onLeave: PropTypes.func,
  onSubmit: PropTypes.func,
};

export { Form };
