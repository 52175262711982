export const getBoard = ({ cart = [], dataSource, storeCart = [] } = {}) =>
  new Promise((resolve, reject) => {
    const counts = storeCart.reduce((total, item) => ({ ...total, [item]: (total[item] || 0) + 1 }), {});

    const getBoard = (id) =>
      dataSource.items
        .map(({ boards = [], name: roomName }) => boards.map((board) => ({ ...board, roomName })))
        .flat()
        .find((board) => board.id === id);

    const validStoreCart = storeCart.filter(
      (id) => counts[id] >= getBoard(id)?.minSelected && counts[id] <= getBoard(id)?.maxSelected,
    );

    if (storeCart.length === cart.length || validStoreCart.length != storeCart.length) return reject();
    else return resolve(storeCart.map((id) => getBoard(id)).find(({ id } = {}) => !!id));
  });
