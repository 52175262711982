import {
  PAYMENT_CARD as CARD,
  PAYMENT_CARD_VALIDATION_PROVIDER as CARD_VALIDATION_PROVIDER,
  PAYMENT_METHOD as METHOD,
  PAYMENT_PROVIDER as PROVIDER,
} from '../../helpers';

const DATE_FORMAT = { year: 'numeric', month: 'long', day: 'numeric' };

const URL_IMAGES = 'payments/images';

export { CARD, CARD_VALIDATION_PROVIDER, DATE_FORMAT, METHOD, PROVIDER, URL_IMAGES };
