import { Icon, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './Countdown.module.css';
import { Countdown as CountdownBase } from '../../../../../__shared__';
import { ICON } from '../../../../../helpers';
import { L10N } from '../../Item.l10n';

const Countdown = ({ offer, timestamp }) => {
  const { isMobile } = useDevice();

  return timestamp ? (
    <View row className={[style.row, style.centered]}>
      <Icon action={isMobile} error value={ICON.ALARM} />

      <CountdownBase
        error
        l10n={!offer ? L10N.LABEL_COUNTDOWN : L10N.LABEL_COUNTDOWN_OFFER}
        small={!isMobile}
        tiny={isMobile}
        timestamp={timestamp}
      />
    </View>
  ) : null;
};

Countdown.displayName = 'Mirai:Core:Rates:Item.RateDetails:Countdown';

Countdown.propTypes = {
  offer: PropTypes.bool,
  timestamp: PropTypes.number,
};

export { Countdown };
