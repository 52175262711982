import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { styles } from '../../helpers';
import { getInputPhoneErrors } from '../../helpers';
import { Icon, Input, Select, Text, View } from '../../primitives';
import { DIRECTION_TYPE, Theme } from '../../theme';
import { Expand } from '../InputSelect/partials';
import style from '../InputText/InputText.module.css';
import { Hint, IconState, Label } from '../InputText/partials';
import { parseValue, sanitizePrefixes } from './helpers';
import stylePhone from './InputPhone.module.css';

const InputPhone = ({
  disabled,
  emptyOption,
  error,
  hint,
  icon,
  label,
  labelPrefix,
  name,
  prefixes = [],
  showRequired = false,
  showState = true,
  success,
  value: propValue,
  warning,
  onChange = () => {},
  onEnter = () => {},
  onError = () => {},
  onLeave = () => {},
  ...others
}) => {
  const defaultDirection = Theme.getDirection() === DIRECTION_TYPE.LEFT;

  const [focus, setFocus] = useState(false);
  const [prefixFocus, setPrefixFocus] = useState(false);
  const [value, setValue] = useState(parseValue(propValue, sanitizePrefixes(prefixes)));

  useEffect(() => {
    setValue(parseValue(propValue, sanitizePrefixes(prefixes)));
  }, [prefixes, propValue]);

  useEffect(() => {
    onError(getInputPhoneErrors({ prefixes: sanitizePrefixes(prefixes), value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefixes, value]);

  const handleChange = (next, event, { isPhone = true } = {}) => {
    const [prefix = '', phone = ''] = hasPrefixes ? value?.split(' ') || [] : [undefined, value];
    let nextValue = (isPhone ? [prefix, next?.replace(/[^\d]/g, '')] : [next, phone]).join(' ');
    if (!hasPrefixes) nextValue = nextValue.trim();

    setValue(nextValue);
    onChange(nextValue, event);
  };

  const handleEnter = (event, { isPhone = true } = {}) => {
    isPhone ? setFocus(true) : setPrefixFocus(true);
    onEnter(event);
  };

  const handleLeave = (event, { isPhone = true } = {}) => {
    isPhone ? setFocus(false) : setPrefixFocus(false);
    onLeave(event);
  };

  const hasPrefixes = !!prefixes?.length;
  const [prefix, phone] = hasPrefixes ? value?.split(' ') || [] : [undefined, value];
  const has = {
    icon: !!icon,
    label: !!label,
    labelPrefix: !!labelPrefix,
    prefix: prefix?.length > 0,
    phone: phone?.length > 0,
    stateIcon: showState && (error || success || warning),
  };
  const { testId } = others;
  const prefixDirection = !defaultDirection ? '‎' : '';
  const idPrefix = hasPrefixes && has.labelPrefix ? `${name}-prefix` : undefined;
  const id = label ? others.id || name : others.id;

  return (
    <View
      role={others.role}
      tag="input-phone"
      className={styles(stylePhone.inputPhone, others.className)}
      style={others.style}
    >
      <View
        row
        className={styles(
          style.inputBorder,
          disabled && style.disabled,
          error && style.error,
          (focus || prefixFocus) && style.focus,
        )}
      >
        {hasPrefixes && (
          <>
            <View row>
              <View className={style.content}>
                {labelPrefix && (
                  <Label
                    {...{ disabled, error, focus: prefixFocus, for: idPrefix, label: labelPrefix, value: prefix }}
                  />
                )}
                {prefix && (
                  <Text className={stylePhone.prefixValue}>
                    {prefixDirection}
                    {prefix}
                  </Text>
                )}
                <Select
                  {...others}
                  disabled={disabled}
                  emptyOption={emptyOption}
                  id={idPrefix}
                  name={`${name}-prefix`}
                  options={prefixes.map((prefix) => `${prefixDirection}${prefix}`)}
                  values={sanitizePrefixes(prefixes)}
                  style={undefined}
                  type={undefined}
                  value={prefix || ''}
                  onChange={(value, event) => handleChange(value, event, { isPhone: false })}
                  onEnter={(event) => handleEnter(event, { isPhone: false })}
                  onLeave={(event) => handleLeave(event, { isPhone: false })}
                  className={styles(
                    style.input,
                    stylePhone.prefix,
                    has.labelPrefix && style.withLabel,
                    has.labelPrefix && !(prefixFocus || error || has.prefix) && style.empty,
                  )}
                  testId={testId ? `${testId}-select` : undefined}
                  aria-label={labelPrefix}
                />
              </View>

              {!disabled && <Expand />}
            </View>
            <View forceRow className={stylePhone.separator}></View>
          </>
        )}

        <View row wide>
          {has.icon && (
            <Icon
              value={icon}
              className={styles(style.icon, style.left, disabled && style.disabled, error && style.error)}
            />
          )}
          <View wide className={style.content}>
            {label && (
              <Label
                {...{ disabled, error, focus, for: id, label, required: showRequired && others.required, value: phone }}
              />
            )}
            <Input
              {...others}
              disabled={disabled}
              id={id}
              maxLength={12}
              name={`${name}-phone`}
              type="tel"
              value={phone || ''}
              onChange={(value, event) => handleChange(value, event)}
              onEnter={(event) => handleEnter(event)}
              onLeave={(event) => handleLeave(event)}
              className={styles(style.input, has.label && style.withLabel)}
              testId={testId ? `${testId}-input` : undefined}
              aria-label={label}
            />
          </View>
        </View>
        {has.stateIcon && <IconState {...{ error, success, warning }} />}
      </View>
      {hint && <Hint {...{ disabled, error, hint }} />}
    </View>
  );
};

InputPhone.displayName = 'Component:InputPhone';

InputPhone.propTypes = {
  disabled: PropTypes.bool,
  emptyOption: PropTypes.string,
  error: PropTypes.bool,
  hint: PropTypes.string,
  icon: PropTypes.func,
  label: PropTypes.string,
  labelPrefix: PropTypes.string,
  name: PropTypes.string.isRequired,
  prefixes: PropTypes.arrayOf(PropTypes.string),
  showRequired: PropTypes.bool,
  showState: PropTypes.bool,
  success: PropTypes.bool,
  value: PropTypes.string,
  warning: PropTypes.bool,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onError: PropTypes.func,
  onLeave: PropTypes.func,
};

export { InputPhone };
