import { toUrlParams } from './toUrlParams';
import { ENDPOINT } from '../ButtonPayment.constants';

export const getUrlTpv = ({
  environment,
  id,
  locale,
  tpvNumOrder,
  tracking,
  urlParams: { variant } = {},
  ...others
}) => {
  if (!tpvNumOrder || !id || !locale) return;

  const { redsysTokenization, sipayRequestId, checkoutToken, paycometToken, alternativePaymentMethodSelected } = others;

  return `${process.env.SERVICE_BOOKING}/${ENDPOINT.TPV}${toUrlParams({
    alternativePaymentMethodSelected,
    checkoutToken,
    env: environment,
    idTokenProvider: id,
    lang: locale.split('-')[0],
    numOrder: tpvNumOrder,
    paycometToken,
    redsysTokenization,
    sipayRequestId,
    tracking,
    variant,
    version: 2,
  })}`;
};
