import { useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Action, Modal, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { L10N } from './Hotelverse.l10n';
import * as style from './Hotelverse.module.css';
import { addScript, getAccommodationType } from '../../../helpers';
import { STATE } from '../../Booking.constants';

const Hotelverse = ({ hotelverse: { hash, token } = {}, state, ...others }) => {
  const { translate } = useLocale();
  const { isMobile } = useDevice();

  const {
    value: {
      config: { hotels, lodgingDenomination: individual } = {},
      language,
      urlParams: { idHotel, reservationCode: id } = {},
    },
  } = useStore();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const cdn = process.env.HOTELVERSE_CDN;
    const scriptEmbed = open && addScript(`${cdn}/scripts/embed.js`);
    const scriptListener = open && addScript(`${cdn}/scripts/interfaceListener.js`);

    if (open) document.body.classList.add('hotelverse-open');

    return () => {
      document.body.classList.remove('hotelverse-open');
      if (scriptEmbed) document.head.removeChild(scriptEmbed);
      if (scriptListener) document.head.removeChild(scriptListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const { btoa } = window;
  const commandInterface = btoa(
    JSON.stringify({ commandsInterface: { disabledBackButton: true, metrics: true }, alg: 'HS256' }),
  );

  const handlePress = () => setOpen(true);

  const accommodationType = getAccommodationType({
    dataSource: hotels,
    hotels: [idHotel],
    individual: individual && typeof individual === 'object' ? individual[idHotel] : individual,
  });

  return state !== STATE.CANCELLED && token && hash ? (
    <>
      {` ${translate(L10N.LABEL_CHOOSE_ROOM, { type: accommodationType })} `}
      <Action {...others} small={isMobile} underline onPress={handlePress} className={style.action}>
        {translate(L10N.ACTION_CHOOSE_ROOM, { type: accommodationType })}
      </Action>
      <Modal blur fit portal visible={open} onClose={() => setOpen(false)} className={style.modal}>
        {open && (
          <View>
            <View
              id="hv"
              data-hvhash={hash}
              data-hvaccesstokenhv={token}
              data-hvclientid="6"
              data-hvcommandsinterface={commandInterface}
              data-hvexthotelid={idHotel}
              data-hvextlocator={id}
              data-hvlanguage={language.toUpperCase()}
              data-hvproduct="ChooseYourRoom"
            ></View>
          </View>
        )}
      </Modal>
    </>
  ) : null;
};

Hotelverse.propTypes = {
  hotelverse: PropTypes.shape({
    hash: PropTypes.string,
    token: PropTypes.string,
  }),
  state: PropTypes.number,
};

Hotelverse.displayName = 'Mirai:User:Booking:Hotelverse';

export { Hotelverse };
