.button {
  margin-top: var(--mirai-ui-space-M);
}

.description {
  margin-bottom: var(--mirai-ui-space-M);
}

.frame,
.name {
  font-family: var(--mirai-ui-input-font) !important;
  font-size: var(--mirai-ui-input-font-size) !important;
}
.frame {
  color: var(--mirai-ui-input-color);
  direction: var(--mirai-ui-text-direction);
  padding-bottom: 0 !important;
  padding-top: var(--mirai-ui-space-M) !important;
  outline: 0;
  height: var(--mirai-ui-space-L);
}

.frame::placeholder {
  color: var(--mirai-ui-input-disabled);
}

.inputCVV {
  margin-left: var(--mirai-ui-space-M);
}
