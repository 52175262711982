export const getParamsFromExtras = ({ selectedExtras = [], urlParams = {}, variant }) => {
  let { extras, totalPrice } = urlParams;

  if (variant === 'B' && selectedExtras.length > 0) {
    extras = JSON.stringify(
      selectedExtras.map(({ amount = 0, id: idExtra, included = false, nights = 0, valueAmount, valueNights }) => ({
        idExtra,
        included,
        nightsToApply: valueNights || nights,
        numSelected: valueAmount || amount,
      })),
    );

    const totalExtras = selectedExtras.reduce(
      (total, { amount = 0, baseValue = {}, nights = 0, valueAmount, valueNights } = {}) =>
        total + (baseValue[valueAmount || amount][valueNights || nights]?.price || 0),
      0,
    );

    totalPrice = parseInt(totalPrice) + +(totalExtras * 100).toFixed();
  }

  return { extras, totalPrice };
};
