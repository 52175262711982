import { ERROR } from '../../Core.constants';

export const L10N = {
  NOTIFICATION_ERROR: {
    [ERROR.NOT_AVAILABLE]: { id: 'common.notification.error_not_available' },
    [ERROR.PARAMETER_ERROR]: { id: 'common.notification.error_parameter' },
    [ERROR.UNKNOWN]: { id: 'common.notification.error_unknown' },
  },
  // -- <Details> ------------------------------------------------------------- */
  LABEL_BOOKING_DETAILS: { id: 'user.label.booking_details' },
  LABEL_BOOKING_EXTRAS: { id: 'user.label.booking_extras' },
  LABEL_OBSERVATIONS: { id: 'common.label.observations' },

  /* -- <Form> -------------------------------------------------------------- */
  ACTION_LOGIN: { id: 'common.action.login' },
  ACTION_MORE_INFO: { id: 'common.action.more_info' },
  ACTION_SIGNUP: { id: 'common.action.signup' },
  COMMENTS_HINT: { id: 'user.label.comments_hint' },
  LABEL_ADDRESS: { id: 'common.label.address' },
  LABEL_CITY: { id: 'common.label.city' },
  LABEL_COMMENTS: { id: 'common.label.comments' },
  LABEL_COMPANY: { id: 'common.label.company' },
  LABEL_CONDITIONS: { id: 'user.label.conditions' },
  LABEL_COUNTRY: { id: 'common.label.country' },
  LABEL_DNI: { id: 'common.label.id_card' },
  LABEL_EMAIL: { id: 'common.label.email' },
  LABEL_FIRSTNAME: { id: 'common.label.firstname' },
  LABEL_IDPASSPORT: { id: 'common.label.id_passport' },
  LABEL_JOINCLUB: { id: 'user.label.join_club' },
  LABEL_LASTNAME: { id: 'common.label.lastname' },
  LABEL_PERSONAL_DETAILS: { id: 'user.label.personal_details' },
  LABEL_PHONE_PREFIX: { id: 'common.label.phone_prefix' },
  LABEL_PHONE: { id: 'common.label.phone' },
  LABEL_PRIVACYPOLICY: { id: 'user.label.checkout_privacy_policy' },
  LABEL_ROOM_NUMBER: { id: 'common.label.room_number' },
  LABEL_SECONDLASTNAME: { id: 'common.label.second_lastname' },
  LABEL_STATEREGION: { id: 'common.label.state_region' },
  LABEL_SUBJECT_TO_AVAILABILITY: { id: 'user.label.subject_to_availability' },
  LABEL_TERMS_AND_CONDITIONS: { id: 'common.label.terms_and_conditions' },
  LABEL_ZIPCODE: { id: 'common.label.zip_code' },
  NOTIFICATION_AGENCY: { id: 'user.notification.agency' },
  NOTIFICATION_NO_CARD: { id: 'user.notification.no_card' },
  NOTIFICATION_SESSION_BOOKING: { id: 'user.notification.session_booking' },

  /* -- <Extras> ------------------------------------------------------------ */
  ACTION_FINAL_DETAILS: { id: 'payments.action.final_details' },
  ACTION_VIEW_EXTRAS: { id: 'common.action.view_extras' },
  LABEL_DISCOVER_EXTRAS: { id: 'common.label.discover_extras' },
  LABEL_ENHANCED_YOUR_STAY: { id: 'common.label.enhance_your_stay' },
  LABEL_TOTAL: { id: 'common.label.total' },

  /* -- <Confirmation> ------------------------------------------------------ */
  ACTION_BOOK_NOW: { id: 'user.action.book_now' },
  NOTIFICATION_SSL_ENCRYPTION: { id: 'common.notification.ssl_encryption' },
  NOTIFICATION_UNKNOWN_POS_ERROR: { id: 'user.notification.unknown_pos_error' },
};
