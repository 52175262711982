import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Icon, Pressable, Text, useDevice, styles, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { BookingProcess } from './components';
import { L10N } from './Header.l10n';
import * as style from './Header.module.css';
import { AUTH_TYPE } from '../../Core.constants';
import { Skeleton, SelectEnvironment } from '../__shared__';
import { EVENT, ICON, SUBMIT_DELAY, toUrlParams } from '../helpers';
import { Account } from '../Session/Session.Account';
import { ENDPOINT } from '../Session/Session.constants';
import { Modal as ModalLogin } from '../Session/Session.Login.Modal';

const Header = ({
  currency: showCurrency = false,
  locale: showLocale = true,
  login: showLogin = false,
  ghost,
  name: showName,
  ...others
}) => {
  const { isDesktop, isMobile } = useDevice();
  const { translate } = useLocale();
  const {
    value: {
      club = {},
      finder: { place: { id: [placeId] = [], isHotel, title: placeName } = {} } = {},
      hotel: { features = {}, id, url } = {},
      id: instanceId,
      language,
      session,
      skeleton = false,
    } = {},
  } = useStore();

  const [modal, setModal] = useState(false);

  useEffect(() => {
    const callback = ({ visible = true } = {}) => setModal(visible);

    Event.subscribe(EVENT.LOGIN, callback);
    return () => Event.unsubscribe(EVENT.LOGIN, callback);
  }, []);

  const handleClose = () => setModal(false);

  const handleLogin = () => {
    const { authentication: { type = AUTH_TYPE.MIRAI } = {} } = club;
    Event.publish(EVENT.METRICS, { id: 'HEADER:LOGIN' });

    if (type === AUTH_TYPE.MIRAI) return setModal(true);

    const url = `${process.env.SERVICE_USER}/${ENDPOINT.EXTERNAL_LOGIN}${toUrlParams({
      idtokenprovider: id,
      domain: document.location.href,
      lang: language,
    })}`;
    document.location.href = url;
  };

  const handleUrl = () => {
    Event.publish(EVENT.METRICS, { id: 'HEADER:GO_TO_HOME' });
    setTimeout(() => (window.location = url), SUBMIT_DELAY / 2);
  };

  const { logo, name, type } =
    (placeId ? (isHotel ? features[placeId] : { name: placeName, ...features[instanceId] }) : features[id]) || {};
  const largeName = name?.length > 32;
  const nameProps = { action: (largeName && !isMobile) || (isMobile && !largeName), small: largeName && isMobile };
  const fullName = `${type ? translate(L10N.LABEL_PROPERTY_TYPE, { type }) : ''} ${name}`?.trim();

  return (
    <>
      <View tag="header" wide testId={others.testId} className={styles(style.header, others.className)}>
        <View row wide className={style.content}>
          <View row className={style.main}>
            {skeleton ? (
              <Skeleton bold brand words={3} />
            ) : (
              (logo || ghost || (!logo && name)) && (
                <Pressable onPress={url ? handleUrl : undefined}>
                  {ghost ? (
                    <View dangerouslySetInnerHTML={{ __html: ghost.outerHTML }} />
                  ) : logo ? (
                    <View alt={fullName} tag="img" src={logo} className={style.image} />
                  ) : (
                    <Text {...nameProps} bold brand className={style.textName}>
                      {fullName}
                    </Text>
                  )}
                </Pressable>
              )
            )}

            {isDesktop && <BookingProcess className={style.noPrint} />}
          </View>

          <View row className={[style.options, style.noPrint]}>
            {showLocale && <SelectEnvironment compacted={isMobile} headerMode metrics="HEADER" name="locale" />}
            {showCurrency && <SelectEnvironment compacted={isMobile} headerMode metrics="HEADER" name="currency" />}
            {showLogin && (
              <>
                {session ? (
                  <Account compacted />
                ) : (
                  <>
                    <Pressable onPress={handleLogin} className={style.login}>
                      <View row>
                        <Icon headline level={2} value={ICON.ACCOUNT} />

                        {!isMobile && (
                          <Text action medium>
                            {translate(L10N.ACTION_LOGIN)}
                          </Text>
                        )}
                      </View>
                    </Pressable>
                    <ModalLogin visible={modal} onClose={handleClose} onSuccess={handleClose} />
                  </>
                )}
              </>
            )}
          </View>
        </View>
      </View>

      {showName && logo && name && (
        <View className={styles(style.header, style.extra, others.className)}>
          <View row wide className={style.content}>
            <Text {...nameProps}>{fullName}</Text>
          </View>
        </View>
      )}

      {!isDesktop && <BookingProcess />}
    </>
  );
};

Header.displayName = 'Mirai:Core:Header';

Header.propTypes = {
  currency: PropTypes.bool,
  ghost: PropTypes.any,
  locale: PropTypes.bool,
  login: PropTypes.bool,
  name: PropTypes.bool,
};

export { Header };
