.switch {
  align-self: flex-start;
  box-sizing: border-box;
}

.switch input {
  height: 0;
  outline: none;
  pointer-events: none;
  position: absolute;
  user-select: none;
  visibility: hidden;
  width: 0;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.switch .area {
  align-items: center;
  justify-content: center;
  background-color: var(--mirai-ui-switch-border-color);
  border-radius: var(--mirai-ui-switch-border-radius);
  cursor: pointer;
  display: flex;
  position: relative;
  transition: background-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing),
    box-shadow var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
}

.switch:focus .area,
.switch:hover .area {
  box-shadow: var(--mirai-ui-focus-shadow);
}

.switch .area.error {
  background-color: var(--mirai-ui-error);
}

.switch .toggle {
  background-color: var(--mirai-ui-switch-base);
  border-radius: calc(var(--mirai-ui-switch-border-radius) - var(--mirai-ui-switch-border-size));
  left: var(--mirai-ui-switch-border-size);
  position: absolute;
  top: var(--mirai-ui-switch-border-size);
  transition: var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
}

.switch input:checked + .area {
  background-color: var(--mirai-ui-switch-checked);
}

.switch input:disabled + .area {
  background-color: var(--mirai-ui-switch-disabled);
  cursor: default;
}

.switch input:checked + .area .toggle {
  left: calc(100% - var(--mirai-ui-switch-border-size));
  transform: translateX(-100%);
}

@media only screen and (max-width: 480px) {
  .switch .area {
    height: calc(var(--mirai-ui-switch-size-mobile) + calc(var(--mirai-ui-switch-border-size) * 2));
    width: calc(var(--mirai-ui-switch-size-mobile) * 2.4);
  }

  .switch .toggle {
    height: var(--mirai-ui-switch-size-mobile);
    width: var(--mirai-ui-switch-size-mobile);
  }
}

@media only screen and (min-width: 481px) {
  .switch .area {
    height: calc(var(--mirai-ui-switch-size) + calc(var(--mirai-ui-switch-border-size) * 2));
    width: calc(var(--mirai-ui-switch-size) * 2.4);
  }

  .switch .toggle {
    height: var(--mirai-ui-switch-size);
    width: var(--mirai-ui-switch-size);
  }

  .switch:hover input:not(:disabled) + .area {
    background-color: var(--mirai-ui-focus-color);
  }
}
