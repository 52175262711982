export const L10N = {
  LABEL_ADDRESS: { id: 'common.label.address' },
  LABEL_CARD: { id: 'payments.label.card_number' },
  LABEL_CITY: { id: 'common.label.city' },
  LABEL_COUNTRY: { id: 'common.label.country' },
  LABEL_CVV: { id: 'payments.label.cvv' },
  LABEL_DATEOFBIRTH: { id: 'user.label.date_of_birth', defaultMessage: '　' },
  LABEL_DNI: { id: 'common.label.id_card' },
  LABEL_EMAIL: { id: 'common.label.email' },
  LABEL_EXPIRE: { id: 'payments.label.expiration' },
  LABEL_FIRSTNAME: { id: 'common.label.firstname' },
  LABEL_IDPASSPORT: { id: 'common.label.id_passport' },
  LABEL_JOINCLUB: { id: 'common.label.member_conditions' },
  LABEL_LASTNAME: { id: 'common.label.lastname' },
  LABEL_NAME: { id: 'payments.label.card_name' },
  LABEL_PASSWORD: { id: 'common.label.password', defaultMessage: '　' },
  LABEL_PHONE: { id: 'common.label.phone' },
  LABEL_PRIVACY: { id: 'common.label.privacy_policy' },
  LABEL_PRIVACYPOLICY: { id: 'common.label.privacy_policy' },
  LABEL_SECONDLASTNAME: { id: 'common.label.second_lastname' },
  LABEL_SPANISHIDCARD: { id: 'payments.label.spanish_resident' },
  LABEL_STATEREGION: { id: 'common.label.state_region' },
  LABEL_ZIPCODE: { id: 'common.label.zip_code' },

  NOTIFICATION_REQUIRED_FIELDS: { id: 'user.notification.required_fields' },
};
