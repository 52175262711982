export const L10N = {
  ACTION_ADD: { id: 'common.action.add' },
  ACTION_NEXT: { id: 'finder.action.next' },
  ACTION_REMOVE: { id: 'common.action.remove' },
  ACTION_RESERVE: { id: 'common.action.reserve' },

  LABEL_APPLIES_SELECTED_ROOMS: { id: 'common.label.applies_selected_rooms' },
  LABEL_APPLIES_INDIVIDUALS_RESERVATION: { id: 'common.label.applies_individuals_reservation' },
  LABEL_ENHANCED_YOUR_STAY: { id: 'common.label.enhance_your_stay' },
  LABEL_FOR_TYPE_EXTRA: { id: 'finder.label.for_type_extra' },
  LABEL_FREE: { id: 'common.label.free' },
  LABEL_INCLUDED: { id: 'common.label.included' },
  LABEL_NIGHT_COUNT: { id: 'common.label.night_count' },
  LABEL_NIGHTS: { id: 'common.label.nights' },
};
