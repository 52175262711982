.environment {
  background-color: var(--mirai-chat-input-background);
  flex-wrap: wrap;
  gap: var(--mirai-ui-space-XS);
  padding: var(--mirai-ui-space-XS);
}

.environment .intent {
  flex: 1;
}

.environment .variable {
  color: var(--mirai-ui-base);
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  display: block;
}

.environment.error {
  background-color: var(--mirai-ui-error-background);
}

.environment.error * {
  color: var(--mirai-ui-error);
}
