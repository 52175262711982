import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Text, Tooltip, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { getPrices } from './helpers';
import * as style from './PriceDetails.module.css';
import { TAXES_TYPE } from '../../../../../../Core.constants';
import { EVENT } from '../../../../../helpers';
import { DISCOUNT_TYPE } from '../../../../Rates.constants';
import { L10N } from '../../Item.l10n';

const { ALL_EXCLUDED, FEES_EXCLUDED, FEES_INCLUDED_TAXES_EXCLUDED, TAXES_EXCLUDED, TAXES_INCLUDED_FEES_EXCLUDED } =
  TAXES_TYPE;

const PriceDetails = ({ board = {}, cart = [], extras = [], metrics = 'RATES:ITEM', taxesType, ...others }) => {
  const { isMobile } = useDevice();
  const { value: { currency, finder: { promocode } = {} } = {} } = useStore();
  const { currencyFormat, translate } = useLocale();

  const handlePress = () => Event.publish(EVENT.METRICS, { id: `${metrics}:PRICE_DETAILS` });

  const {
    basePrice = 0,
    discount: { breakdown = [] } = {},
    fees: boardFees = 0,
    taxes: boardTaxes = 0,
    totalBaseExtras = 0,
  } = cart.length === 0 ? board : getPrices({ cart, extras, ...others });

  const totalBreakdown = breakdown.reduce((total, { value }) => total + value, 0);
  const total = basePrice + boardFees + boardTaxes + totalBaseExtras - totalBreakdown;
  const { confidential } =
    cart.length === 0
      ? board
      : cart.reduce((total, current) => ({ confidential: current?.confidential && total?.confidential }), {
          confidential: true,
        });

  const renderSubtotal = () =>
    (totalBreakdown > 0 || totalBaseExtras > 0) && (
      <>
        <View className={style.anchor} />
        <View row className={style.item}>
          <Text action bold>
            {translate(L10N.LABEL_SUBTOTAL)}
          </Text>
          <Text action bold>
            {currencyFormat({
              currency,
              value:
                basePrice +
                totalBaseExtras -
                totalBreakdown +
                (taxesType === TAXES_INCLUDED_FEES_EXCLUDED ? boardTaxes : 0) +
                (taxesType === FEES_INCLUDED_TAXES_EXCLUDED ? boardFees : 0),
            })}
          </Text>
        </View>
      </>
    );

  const renderTaxes = () =>
    boardTaxes > 0 && (
      <View row className={style.item}>
        <Text action>{translate(L10N.LABEL_TAXES)}</Text>
        <Text action>{currencyFormat({ currency, value: boardTaxes })}</Text>
      </View>
    );

  const renderFees = () =>
    boardFees > 0 && (
      <View row className={style.item}>
        <Text action>{translate(L10N.LABEL_FEES)}</Text>
        <Text action>{currencyFormat({ currency, value: boardFees })}</Text>
      </View>
    );

  const taxesTypes = {
    [ALL_EXCLUDED]: [renderSubtotal, renderTaxes, renderFees],
    [TAXES_EXCLUDED]: [renderSubtotal, renderTaxes, renderFees],
    [FEES_EXCLUDED]: [renderTaxes, renderSubtotal, renderFees],
    [TAXES_INCLUDED_FEES_EXCLUDED]: [renderTaxes, renderSubtotal, renderFees],
    [FEES_INCLUDED_TAXES_EXCLUDED]: [renderFees, renderSubtotal, renderTaxes],
  };

  return (
    <View row>
      <Tooltip
        pressable
        Template={() => (
          <View className={style.breakdown}>
            {basePrice > 0 && (
              <View row className={style.item}>
                <Text action>{translate(L10N.LABEL_BASE_PRICE)}</Text>
                <Text action>{currencyFormat({ currency, value: basePrice })}</Text>
              </View>
            )}

            {totalBaseExtras > 0 && (
              <View row className={style.item}>
                <Text action>{translate(L10N.LABEL_EXTRAS)}</Text>
                <Text action>{currencyFormat({ currency, value: totalBaseExtras })}</Text>
              </View>
            )}

            {breakdown?.map(({ type, value }) => (
              <View row key={type} className={style.item}>
                <Text action>
                  {translate(L10N.LABEL_DISCOUNT_TYPE[type])}
                  {promocode &&
                    (type === DISCOUNT_TYPE.PROMOTION || (type === DISCOUNT_TYPE.DEAL && confidential)) &&
                    ` ${promocode.toUpperCase()}`}
                </Text>
                <Text action>{currencyFormat({ currency, value: -value })}</Text>
              </View>
            ))}

            {(taxesTypes[taxesType] || [renderTaxes, renderFees]).map((render, index) => (
              <React.Fragment key={index}>{render()}</React.Fragment>
            ))}

            <View className={style.anchor} />

            <View row className={style.item}>
              <Text bold>{translate(L10N.LABEL_TOTAL)}</Text>
              <Text bold>{currencyFormat({ currency, value: total })}</Text>
            </View>
          </View>
        )}
        onPress={handlePress}
        className={style.tooltip}
      >
        <Text accent action={!isMobile} medium small={isMobile} className={others.className}>
          {translate(L10N.LABEL_PRICE_DETAILS)}
        </Text>
      </Tooltip>
    </View>
  );
};

PriceDetails.displayName = 'Mirai:Core:Rates:Item:PriceDetails';

PriceDetails.propTypes = {
  board: PropTypes.shape({
    discount: PropTypes.shape({
      breakdown: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.number,
          value: PropTypes.number,
        }),
      ),
      percentage: PropTypes.number,
    }),
    originalPrice: PropTypes.number,
  }),
  cart: PropTypes.arrayOf(PropTypes.shape({})),
  extras: PropTypes.arrayOf(PropTypes.shape({})),
  metrics: PropTypes.string,
  taxesType: PropTypes.number,
};

export { PriceDetails };
