.cvv,
.expire {
  margin-right: calc(var(--mirai-ui-space-XS) * 2);
  max-width: calc(calc(var(--mirai-ui-input-font-size) * 6.25) + calc(var(--mirai-ui-input-text-padding-x) * 2));
}

.input {
  box-sizing: border-box;
  height: var(--mirai-ui-space-XL);
}
