import { formatPrice } from '../helpers/legacy';

export const bookingResponse = ({ response: { tracking = {} } = {} } = {}) => {
  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const { hotelInfo: { currency: hotelCurrency = {} } = {}, totalPriceWithoutTaxes } = tracking;

  const value = { gv: formatPrice(totalPriceWithoutTaxes, hotelCurrency), gc: hotelCurrency.code };
  window.uetq?.push(value);

  return value;
};
