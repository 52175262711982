import { Event, useStore } from '@mirai/data-sources';
import { parseDate, useLocale } from '@mirai/locale';
import PropTypes from 'prop-types';
import React from 'react';

import { Card } from './components';
import { getActionIcon } from './helpers';
import { getAccommodationLabel } from '../../../helpers';
import { EVENT } from '../../Chat.constants';

const DATE_FORMAT = { weekday: 'short', month: 'short', day: 'numeric' };

// ! TODO: Refacto use of intent/response vs lisa.intent/response
const Action = ({ disabled, intent, image, requirement, type, url, value, onPress, onResponse, ...props }) => {
  const { dateFormat, translate } = useLocale();
  const {
    value: { hotel: { accommodationType } = {}, lisa },
  } = useStore();

  const handlePress = () => {
    if (intent) return onPress({ intent, context: value });
    else if (requirement) {
      const { intent, response: { form = {}, value: context = {} } = {} } = lisa;
      if (requirement.value) {
        onResponse({ text, value: requirement.value });
        onPress({
          context,
          intent,
          form: { ...form, [requirement.name]: requirement.value },
        });
      } else Event.publish(EVENT.INPUT_COMPONENT, { requirement, onSubmit: onResponse });
    } else if (type && value) {
      const { intent, response: { form = {}, value: context = {} } = {} } = lisa;

      const nextForm = { ...form };
      if (type === 'calendar') nextForm.dates = [value.from, value.to];
      else if (type === 'occupation') nextForm.occupation = value.rooms.map(({ occupation }) => occupation);
      else if (type === 'offer') nextForm.offerId = value;

      onResponse({ image, caption, text });
      onPress({ context, intent, form: nextForm });
    }
  };

  const caption = type === 'occupation' ? value.rooms.map(({ name }) => name).join(' + ') : props.caption;
  const text =
    type === 'calendar'
      ? `${dateFormat(parseDate(value.from), DATE_FORMAT)} ─ ${dateFormat(parseDate(value.to), DATE_FORMAT)}`
      : type === 'occupation'
      ? `${value.rooms.length} ${getAccommodationLabel(
          { plural: value.rooms.length > 1, type: accommodationType },
          translate,
        )}`
      : props.text;

  return (
    <Card
      {...{ caption, disabled, image, text }}
      disabled={intent && disabled}
      href={url}
      icon={getActionIcon(url)}
      option={!image && !disabled && !url}
      onPress={handlePress}
    />
  );
};

Action.propTypes = {
  caption: PropTypes.string,
  disabled: PropTypes.bool,
  image: PropTypes.string,
  intent: PropTypes.string,
  requirement: PropTypes.shape({
    name: PropTypes.string,
    required: PropTypes.bool,
    text: PropTypes.string,
    type: PropTypes.oneOf(['calendar', 'list', 'occupation', 'offer', 'text']),
    value: PropTypes.any,
  }),
  text: PropTypes.string,
  type: PropTypes.oneOf(['calendar', 'occupation', 'offer']),
  url: PropTypes.string,
  value: PropTypes.any,
  onPress: PropTypes.func,
  onResponse: PropTypes.func,
};

export { Action };
