import { Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './Session.module.css';

const Info = ({ email, firstName, lastName }) => (
  <View wide className={style.texts}>
    <Text action bold role="content">
      {`${firstName} ${lastName}`.trim()}
    </Text>
    <Text tiny role="content">
      {email}
    </Text>
  </View>
);

Info.displayName = 'Mirai:Core:Session:Account:Info';

Info.propTypes = {
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export { Info };
