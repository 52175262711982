import { useLocale } from '@mirai/locale';
import { Input, InputText, styles, useDevice, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getHistogram } from './helpers';
import { L10N } from './InputRange.l10n';
import * as style from './InputRange.module.css';

const InputRange = ({
  caption,
  dataSource,
  resolution,
  title,
  unit,
  value: form = {},
  onChange = () => {},
  ...others
}) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();

  const [histogram, setHistogram] = useState({});

  useEffect(() => {
    setHistogram(getHistogram(dataSource, resolution));
  }, [dataSource, resolution]);

  const handleInput = (...params) => {
    let [field, value, forceField = false] = params;

    value = parseInt(value);
    if (!forceField) {
      if (field === 'min' && value >= form.max) field = 'max';
      else if (field === 'max' && value <= form.min) field = 'min';
    }

    onChange({ ...form, [field]: value });
  };

  const { max, min, bins = [] } = histogram;
  const rangeProps = { max, min, step: 1, type: 'range', className: style.input };
  const numberProps = { caption: unit, type: 'number', className: style.input };

  return bins.length > 0 ? (
    <View {...others}>
      <View>
        <Text bold headline wide>
          {title}
        </Text>
        {caption && (
          <Text action={!isMobile} small={isMobile} light>
            {caption}
          </Text>
        )}
      </View>

      <View>
        <View row className={style.chart}>
          {bins.map(({ range: [min, max] = [], count = 0 }, index) => (
            <View
              key={`col:${index}`}
              className={styles(style.value, (min < form.min - 1 || max > form.max + 1) && style.disabled)}
              style={{ height: `${(count / histogram.maxBinCount) * 100}%` }}
            />
          ))}
        </View>

        <View className={style.range}>
          <Input
            {...rangeProps}
            name="range:min"
            value={form.min || histogram.min}
            onChange={(value) => handleInput('min', value)}
          />
          <Input
            {...rangeProps}
            name="range:max"
            value={form.max || histogram.max}
            onChange={(value) => handleInput('max', value)}
          />
        </View>
      </View>

      <View row className={style.inputNumbers}>
        <InputText
          {...numberProps}
          label={translate(L10N.LABEL_MIN)}
          name="input:min"
          value={form.min || histogram.min}
          onChange={(value) => handleInput('min', value, true)}
        />
        <Text>-</Text>
        <InputText
          {...numberProps}
          label={translate(L10N.LABEL_MAX)}
          name="input:max"
          value={form.max || histogram.max}
          onChange={(value) => handleInput('max', value, true)}
        />
      </View>
    </View>
  ) : null;
};

InputRange.displayName = 'Mirai:Core:Rates:Filters:InputRange';

InputRange.propTypes = {
  caption: PropTypes.string,
  dataSource: PropTypes.arrayOf(PropTypes.number),
  resolution: PropTypes.number,
  title: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.shape({
    max: PropTypes.number,
    min: PropTypes.number,
  }),
  onChange: PropTypes.func,
};

export { InputRange };
