import { currencyFormat, useLocale } from '@mirai/locale';
import { styles, Text, Tooltip, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { FORMAT_CURRENCY } from './BookingTable.constants';
import { L10N } from './BookingTable.l10n';
import * as style from './BookingTable.module.css';

const Row = ({ bold, children, currency, title, description, price, subtitle, summary, ...others }) => {
  const { isMobile } = useDevice();
  const { locale, translate } = useLocale();

  const priceProps = { ...FORMAT_CURRENCY, currency, locale };

  const renderPrice = (bold) => (
    <Text action bold={bold}>
      {price ? currencyFormat({ ...priceProps, value: price }) : translate(L10N.LABEL_FREE)}
    </Text>
  );

  return (
    <View {...others} row={!isMobile} className={styles(style.row, others.className)}>
      <View className={style.title}>
        <View row>
          <Text action bold={bold} className={style.wide}>
            {title}
          </Text>
          {isMobile && renderPrice(bold)}
        </View>
        <View row className={style.subtitle}>
          <View row wide>
            {subtitle && <Text small>{subtitle}</Text>}
            {subtitle && description && (
              <Text small className={styles(style.caption, style.separator)}>
                -
              </Text>
            )}
            {description && (
              <Tooltip pressable right={!isMobile} text={description}>
                <Text small underline className={styles(style.action, style.noPrint)}>
                  {translate(L10N.ACTION_MORE_INFO)}
                </Text>
              </Tooltip>
            )}
          </View>
        </View>
      </View>

      {(!isMobile || summary) && (
        <View className={style.summary}>
          <Text action={!isMobile} light={isMobile} small={isMobile}>
            {summary}
          </Text>
        </View>
      )}

      {!isMobile && <View className={style.price}>{renderPrice(bold)}</View>}

      {children}
    </View>
  );
};

Row.displayName = 'Mirai:User:components:BookingTable.Row';

Row.propTypes = {
  bold: PropTypes.bool,
  children: PropTypes.node,
  currency: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  summary: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export { Row };
