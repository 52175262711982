import { LocalAdapter, Storage } from '@mirai/data-sources';

import { AUTH_TYPE } from '../../../Core.constants';

export const getExternalToken = ({ cookies, type } = {}) => {
  let value;

  if (type === AUTH_TYPE.PESTANA) value = cookies?.pestana;
  else if (type === AUTH_TYPE.SENATOR) {
    const tokenData = new Storage({ adapter: LocalAdapter }).get('apiData');
    if (tokenData) value = window.btoa(JSON.stringify(tokenData));
  }

  return value;
};
