import { Event, useStore } from '@mirai/data-sources';
import { dateDiff, parseDate, useLocale } from '@mirai/locale';
import { Action, Icon, styles, Text, Tooltip, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { FORMAT_DATE, REDIRECT_PATH } from './BookingSummary.constants';
import { L10N } from './BookingSummary.l10n';
import * as style from './BookingSummary.module.css';
import { EVENT, getRoomNumber, ICON, SUBMIT_DELAY, toUrlParams } from '../../helpers';
import { ButtonMore } from '../ButtonMore';
import { PriceBreakdown } from '../PriceBreakdown';

const BookingSummary = ({ box = false, children, dataSource = {}, ...others }) => {
  const { isDesktop, isMobile } = useDevice();
  const { dateFormat, translate } = useLocale();
  const {
    value: {
      components = {},
      finder: { promocode = '' } = {},
      hotel: { features = {}, id } = {},
      language,
      urlParams: {
        applyClubDiscount,
        chainSelectorValue,
        checkin,
        clientCodeStrictSearch,
        contactCenter,
        device: deviceType,
        env,
        home,
        hsri,
        idchain,
        idtokenprovider,
        parties,
        rooms,
        roomNumberBySelectedRoom,
      } = {},
      variant,
    },
  } = useStore();

  const [expanded, setExpanded] = useState(isDesktop);

  const {
    checkIn,
    checkInTime,
    checkOut,
    checkOutTime,
    currency,
    details = [],
    hotel: { address, city, country, email, location = [], name: hotelName = '', phone, site } = {},
    nights,
  } = dataSource;

  const handleBack = () => {
    if (metrics) Event.publish(EVENT.METRICS, { id: `${metrics}:CHANGE_SELECTION` });

    setTimeout(() => {
      document.location.href = `${process.env.SERVICE_BOOKING}/${REDIRECT_PATH}${toUrlParams({
        applyClubDiscount,
        chainSelectorValue,
        checkin,
        clientCode: promocode,
        clientCodeStrictSearch,
        currency,
        contactCenter,
        deviceType,
        env,
        home,
        hsri,
        idchain,
        idtokenprovider,
        lang: language,
        cart: rooms
          .split(',')
          .map((room) => room.replace(/\|/g, '--').split(':')[0])
          .join(','),
        nights,
        parties,
        variant,
      })}`;
    }, [SUBMIT_DELAY]);
  };

  const handlePress = () => {
    setExpanded(true);
    if (metrics) Event.publish(EVENT.METRICS, { id: `${metrics}:DETAILS:VIEW_MORE` });
  };

  const metrics = components.checkout ? 'CHECKOUT' : components.booking ? 'BOOKING' : undefined;
  const { days } = dateDiff(parseDate(checkIn), parseDate(checkOut));
  const isCheckout = !!components.checkout;
  const items = details.filter(({ type } = {}) => !!type) || [];
  const roomNumber = getRoomNumber(roomNumberBySelectedRoom);
  const urlLocation = location.length ? `https://maps.google.com?q=${location.join(',')}` : undefined;
  const { name = hotelName, type } = features[id] || {};

  return (
    <View {...others} wide className={styles(style.bookingSummary, box && style.box, others.className)}>
      <View className={style.section}>
        <Text bold headline={isDesktop}>
          {translate(L10N.LABEL_BOOKING_SUMMARY)}
        </Text>

        <View row wide className={style.dates}>
          <View>
            <Text action={isMobile} bold>
              {translate(L10N.LABEL_CHECK_IN)}
            </Text>
            <View>
              <Text action>{dateFormat(parseDate(checkIn), FORMAT_DATE)}</Text>
              {checkInTime && (
                <Text light small>
                  {translate(L10N.LABEL_CHECKIN_TIME, { value: checkInTime })}
                </Text>
              )}
            </View>
          </View>

          <View>
            <Text action={isMobile} bold>
              {translate(L10N.LABEL_CHECK_OUT)}
            </Text>
            <View>
              <Text action>{dateFormat(parseDate(checkOut), FORMAT_DATE)}</Text>
              {checkOutTime && (
                <Text light small>
                  {translate(L10N.LABEL_CHECKOUT_TIME, { value: checkOutTime })}
                </Text>
              )}
            </View>
          </View>
        </View>

        <View>
          <Text action={isMobile} bold>
            {translate(L10N.LABEL_YOUR_RESERVATION)}
          </Text>
          <Text action>
            {`${translate(L10N.LABEL_NIGHT_COUNT, { value: nights || days || 0 })}, ${translate(L10N.LABEL_ROOM_COUNT, {
              value: items.reduce((total, { amount = 1 } = {}) => total + amount, 0),
            })}`}
          </Text>

          {items.map(({ amount, type } = {}, index) => (
            <View key={index} row className={style.info}>
              <Text light small>
                {`${type}${roomNumber ? ` (${translate(L10N.LABEL_ROOM_NUMBER, { roomNumber })})` : ''} ${
                  amount > 1 ? ` (x${amount})` : ''
                }`}
              </Text>
              {roomNumber && (
                <Tooltip pressable text={translate(L10N.LABEL_SUBJECT_TO_AVAILABILITY)}>
                  <Icon value={ICON.INFO} />
                </Tooltip>
              )}
            </View>
          ))}
        </View>

        <View>
          <Text action={isMobile} bold>
            {translate(L10N.LABEL_ACCOMMODATION)}
          </Text>
          <Text action markdown={false}>
            {`${type && name ? translate(L10N.LABEL_PROPERTY_TYPE, { type }) : ''} ${name}`?.trim()}
          </Text>
          <Text light small>
            {`${address}, ${city}, ${country}`}
          </Text>
          {!isCheckout && (
            <Text light small>
              {phone}
            </Text>
          )}
        </View>

        {!isCheckout && (site || email || urlLocation) && (
          <View row wide className={styles(style.links, style.noPrint)}>
            {site && (
              <Action href={site} underline small target="_blank">
                <Icon value={ICON.SITE} />
                {translate(L10N.ACTION_VIEW_HOTEL)}
              </Action>
            )}
            {email && (
              <Action href={`mailto:${email}`} underline small target="_blank">
                <Icon value={ICON.MAIL} />
                {translate(L10N.ACTION_SEND_EMAIL)}
              </Action>
            )}
            {urlLocation && (
              <Action href={urlLocation} underline small target="_blank">
                <Icon value={ICON.LOCATION} />
                {translate(L10N.ACTION_VIEW_MAP)}
              </Action>
            )}
          </View>
        )}

        {isCheckout && (
          <Action underline onPress={handleBack} testId="action-change-selection">
            {translate(L10N.ACTION_CHANGE_SELECTION)}
          </Action>
        )}
      </View>

      <PriceBreakdown dataSource={dataSource} className={style.section} />

      {children ? (
        expanded ? (
          <View className={style.section}>{children}</View>
        ) : (
          <ButtonMore onPress={handlePress} />
        )
      ) : undefined}
    </View>
  );
};

BookingSummary.displayName = 'Mirai:Core:BookingSummary';

BookingSummary.propTypes = {
  box: PropTypes.bool,
  children: PropTypes.any,
  dataSource: PropTypes.shape({}),
};

export { BookingSummary };
