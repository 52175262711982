import { Event, LocalAdapter, Storage, useStore } from '@mirai/data-sources';
import { ServiceLisa } from '@mirai/services';
import { Modal, ScrollView, styles, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ButtonAvatar } from './Chat.ButtonAvatar';
import { CHAT_STORAGE_CACHE, DELAY_RESPONSE } from './Chat.constants';
import { Header } from './Chat.Header';
import * as style from './Chat.module.css';
import { Input, InputRich, Message } from './components';
import { audioNotification, getStorageDomain } from './helpers';
import { EVENT } from '../helpers';

const storage = new Storage({ adapter: LocalAdapter, cache: CHAT_STORAGE_CACHE });
let connectionInterval;

const Chat = ({ ...others }) => {
  const { isMobile } = useDevice();
  const {
    set,
    value: { calendar, components, currency, hotel, locale, occupation, origin, session, skeleton = false, tags, type },
  } = useStore();

  const [busy, setBusy] = useState(false);
  const [messages, setMessages] = useState(storage.get(getStorageDomain(components)) || []);
  const [online, setOnline] = useState(false);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (skeleton) return;

    (async () => {
      if (!(await handleOnline())) return;
      const response = await ServiceLisa.welcome({ context: { hotel }, locale, session }).catch(handleError);
      if (response) {
        if (!messages.length) return addMessage(response, messages);
        set({ lisa: messages[messages.length - 1] });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skeleton]);

  useEffect(() => {
    storage.set(getStorageDomain(components), messages?.slice(-32) || []);
  }, [components, messages]);

  useEffect(() => {
    if (!visible) return clearInterval(connectionInterval);

    connectionInterval = setInterval(handleOnline, online ? 60000 : 10000);
    return () => clearInterval(connectionInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, visible]);

  let storeContext = {
    calendar,
    currency,
    hotel,
    messages: messages
      .filter(({ intent, text }) => !!intent || !!text)
      .map(({ intent, text, response: { form } = {} }) => ({ form, intent, text })),
    occupation,
    origin,
    tags,
    type,
  };

  const handleOnline = async () => {
    if (!(components.finder || components.rates)) return;

    let next = false;
    const response = await ServiceLisa.status().catch(() => setOnline(next));
    if (response && !online) {
      next = true;
      setOnline(next);
    }

    return next;
  };

  const handleInput = (input) => {
    const nextMessages = [...messages, { text: input, timestamp: new Date().getTime() }];
    setMessages(nextMessages);

    setTimeout(async () => {
      setBusy(true);
      storeContext = { ...storeContext, form: messages[messages.length - 1]?.response?.form };
      const response = await ServiceLisa.message({ context: storeContext, input, locale, session }).catch(handleError);
      if (response) addMessage(response, nextMessages, true);
      setBusy(false);
    }, DELAY_RESPONSE);
  };

  const handleAction = ({ context, form, intent } = {}) => {
    if (intent === 'ACTION_LOGIN') return Event.publish(EVENT.LOGIN, { visible: true });
    setBusy(true);

    setTimeout(async () => {
      const nextContext = { ...storeContext, ...context, form };
      const response = await ServiceLisa.action({ context: nextContext, intent, locale, session }).catch(handleError);
      if (response) addMessage(response, messages, true);
      setBusy(false);
    }, DELAY_RESPONSE);
  };

  const handleError = (error) => {
    // eslint-disable-next-line no-console
    console.error('::handleError::', error);
    setOnline(false);
  };

  const handleRetry = async () => {
    setBusy(true);

    const nextMessages = [...messages];
    nextMessages.pop();

    setMessages(nextMessages);
    const { text: input, response: { form } = {} } = nextMessages[nextMessages.length - 1] || {};
    storeContext = { ...storeContext, form };
    const response = await ServiceLisa.message({ context: storeContext, input, locale, session }).catch(handleError);
    if (response) addMessage(response, nextMessages, true);
    setBusy(false);
  };

  const addMessage = (response = {}, messages = [], notify = false) => {
    if (!response) return;

    set({ lisa: response });
    setMessages([...messages, { auto: true, ...response }]);

    if (response.locale !== locale) Event.publish(EVENT.INTENT_LOCALE, { locale: response.locale });
    if (notify) audioNotification();
  };

  const { response: { form } = {} } = messages.filter((message) => message.auto).pop() || [];

  return !skeleton ? (
    <>
      {React.createElement(
        isMobile ? Modal : View,
        isMobile
          ? { ...others, visible, className: styles(style.container, others.className) }
          : {
              ...others,
              className: styles(style.container, visible && style.visible, others.className),
              'aria-hidden': visible ? 'false' : 'true',
            },
        <>
          <Header {...{ form, online }} onClose={() => setVisible(false)} />

          <ScrollView scrollTo={new Date().getTime()} snap={false} className={style.messages}>
            {messages.map((message, index) => (
              <Message
                {...message}
                key={`message:${index}`}
                disabled={busy || index < messages.length - 1}
                onAction={handleAction}
                onRetry={index === messages.length - 1 && messages[index - 1]?.text ? handleRetry : undefined}
              />
            ))}
            {busy && <Message auto busy />}
          </ScrollView>

          {online && (
            <>
              <Input disabled={busy} onValue={handleInput} />
              <InputRich onValue={handleAction} />
            </>
          )}
        </>,
      )}

      <ButtonAvatar ready={!!messages.length} visible={visible} onPress={() => setVisible(true)} />
    </>
  ) : null;
};

Chat.displayName = 'Mirai:Core:Chat';

Chat.propTypes = {
  name: PropTypes.string,
};

export { Chat };
