import { parseCommon, parseRate } from './helpers';
import { getRoomsPriceRange } from '../helpers';
import { formatPrice } from '../helpers/legacy';

export const ratesResponse = ({
  device: { mobile } = {},
  instances = [],
  response: { calendar, hotels = [], tracking = {} } = {},
  store: { finder: { place: { id: [hotelId] = [], isHotel } = {}, ...finder } = {}, id, type } = {},
} = {}) => {
  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const { hotelInfo = {}, hotelInfoBAR = [], rates } = tracking;
  const { occupation = [], promocode = '' } = finder || {};
  const hasHotels = hotels?.length > 0 && !calendar;
  const items = [];

  if (hasHotels) {
    // ! TODO
    hotelInfoBAR.forEach(
      ({
        currency,
        hotelName,
        idTokenProvider,
        bestAvailableRate: { averagePrice: { internalWithoutTaxes } } = {},
      }) => {
        items.push({
          item_id: `${idTokenProvider}`,
          item_name: hotelName,
          coupon: promocode,
          currency: currency.currencyCode,
          item_brand: `${id}`,
          price: formatPrice(internalWithoutTaxes, currency),
        });
      },
    );
  } else if (rates) {
    Object.entries(getRoomsPriceRange(rates, hotelInfo.currency)).forEach(
      ([key, { minRate = 0, maxRate = 0 } = {}]) => {
        const { id } = rates[key];

        items.push(parseRate({ hotelInfo, id, promocode, rate: minRate }));
        if (minRate.id !== maxRate.id) items.push(parseRate({ hotelInfo, id, promocode, rate: maxRate }));
      },
    );
  }

  const step = hasHotels ? 'CHAINRESULT' : 'BOOKINGSTEP1';
  const value = {
    ...(rates || hasHotels
      ? {
          coupon: finder?.promocode || '',
          item_list_name: hasHotels ? 'CHAIN_RESULT' : 'Search Results',
          items,
        }
      : {
          ...parseCommon({ ...finder, id, type }),
          device: mobile ? 'MOBILE' : 'DESKTOP_TABLET',
          hotelId: isHotel ? hotelId : undefined,
          multiroom: occupation.length > 1,
          step,
        }),
    send_to: instances.map(({ idAnalytics } = {}) => idAnalytics),
  };

  window.gtag('event', rates || hasHotels ? (hasHotels ? 'view_item_list' : 'view_item') : 'no_view_item_list', value);
  window.gtag('event', 'data_search_info', {
    ...parseCommon({ ...finder, id, type }),
    non_interaction: true,
    send_to: instances.map(({ idAnalytics } = {}) => idAnalytics),
    step,
  });

  return value;
};
