import { AmazonPay, Aplazame, Card, PCI, Tpv } from '../components';
import { CARD_VALIDATION_PROVIDER, METHOD, PROVIDER } from '../Payment.constants';

const { AMAZON_PAY, APLAZAME, CARD, CARD_PREPAY, PCIPROXY, TPV } = METHOD;
const { HIPAY, MULTIPAGOS, PAYBOX, REDSYS, REDUNIQ } = PROVIDER;

export const getComponent = ({ cardValidation, method, provider } = {}) => {
  let component;

  if (
    [CARD, CARD_PREPAY, PCIPROXY].includes(method) &&
    (!cardValidation || !CARD_VALIDATION_PROVIDER.includes(provider))
  )
    component = method === PCIPROXY ? PCI : Card;
  else if (method === TPV && [HIPAY, MULTIPAGOS, PAYBOX, REDSYS, REDUNIQ].includes(provider)) component = Tpv;
  else if (method === AMAZON_PAY) component = AmazonPay;
  else if (method === APLAZAME) component = Aplazame;

  return component;
};
