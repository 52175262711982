.checkbox {
  position: relative;
}

.checkbox input {
  background-color: var(--mirai-ui-checkbox-base);
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-checkbox-border-color);
  border-radius: var(--mirai-ui-checkbox-border-radius);
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  outline: none;
  transition: background-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing),
    border-color var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing),
    box-shadow var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  user-select: none;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.checkbox input:disabled {
  border-color: var(--mirai-ui-checkbox-disabled);
}

.checkbox input:disabled.checked {
  background-color: var(--mirai-ui-checkbox-disabled);
}

.checkbox input:not(:disabled).checked {
  background-color: var(--mirai-ui-checkbox-checked);
  border-color: var(--mirai-ui-checkbox-checked);
}

.checkbox input:not(:disabled).error {
  border-color: var(--mirai-ui-error);
  border-width: calc(var(--mirai-ui-border-width) * 2);
}

.checkbox input:not(:disabled):focus,
.checkbox input:not(:disabled):hover {
  border-color: var(--mirai-ui-focus-color);
  box-shadow: var(--mirai-ui-focus-shadow);
}

.checkbox input:not(:disabled):checked:focus,
.checkbox input:not(:disabled):checked:hover {
  background-color: var(--mirai-ui-focus-color);
}

/* -- reset defaults -------------------------------------------------------- */
.checkbox input.input {
  margin-right: 0;
  top: 0;
}

/* S */
@media only screen and (max-width: 480px) {
  .checkbox {
    height: var(--mirai-ui-checkbox-size-mobile);
    width: var(--mirai-ui-checkbox-size-mobile);
    font-size: var(--mirai-ui-checkbox-size-mobile);
    line-height: var(--mirai-ui-checkbox-size-mobile);
  }

  .checkbox input {
    height: var(--mirai-ui-checkbox-size-mobile);
    width: var(--mirai-ui-checkbox-size-mobile);
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .checkbox {
    height: var(--mirai-ui-checkbox-size);
    width: var(--mirai-ui-checkbox-size);
    font-size: var(--mirai-ui-checkbox-size);
    line-height: var(--mirai-ui-checkbox-size);
  }

  .checkbox input {
    height: var(--mirai-ui-checkbox-size);
    width: var(--mirai-ui-checkbox-size);
  }
}
