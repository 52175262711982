import { Event } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Modal, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import * as style from './ModalCancellationInfo.module.css';
import { EVENT } from '../../../../../helpers';
import { L10N } from '../../Item.l10n';

const ModalCancellationInfo = ({ cancellation: { paymentConditions, title, text } = {}, name, visible, onClose }) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();

  useEffect(() => {
    if (visible) Event.publish(EVENT.METRICS, { id: 'RATES:ITEM:CANCELLATION_INFO' });
  }, [visible]);

  const textProps = { action: !isMobile, small: isMobile };

  return (
    <Modal fit title={name} visible={visible} onClose={onClose} onOverflow={onClose} className={style.modal}>
      <View className={style.content}>
        {paymentConditions && (
          <View className={style.section}>
            <Text bold upperCase>
              {translate(L10N.LABEL_PAYMENT_TERMS)}
            </Text>
            <Text {...textProps}>{paymentConditions}</Text>
          </View>
        )}

        <View className={style.section}>
          <Text bold upperCase>
            {translate(L10N.LABEL_CANCELLATION_POLICY)}
          </Text>
          <Text bold {...textProps}>
            {title}
          </Text>
          <Text {...textProps}>{text}</Text>
        </View>
      </View>
    </Modal>
  );
};

ModalCancellationInfo.propTypes = {
  cancellation: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
  }),
  name: PropTypes.string,
  paymentConditions: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

ModalCancellationInfo.displayName = 'Mirai:Core:Rates:Item:ModalCancellationInfo';

export { ModalCancellationInfo };
