import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../../helpers';
import { useDevice } from '../../hooks';
import { Checkbox, Icon, ICON, Pressable, Radio, Switch } from '../../primitives';
import { Label } from '../InputText/partials';
import { CHECKBOX, RADIO, SWITCH } from './InputOption.constants';
import style from './InputOption.module.css';

const InputOption = ({
  checked,
  disabled,
  error,
  indeterminate,
  label,
  name,
  required,
  showRequired = false,
  small,
  type = CHECKBOX,
  value = '',
  onChange = () => {},
  ...others
}) => {
  const { isMobile } = useDevice();

  const Primitive = type === CHECKBOX ? Checkbox : type === RADIO ? Radio : Switch;

  const handleChange = (event) => {
    onChange([CHECKBOX, SWITCH].includes(type) ? !checked : value, event);
  };

  const id = label ? others.id || name : others.id;

  return (
    <Pressable
      {...others}
      tag="input-option"
      preventDefault={false}
      onPress={handleChange}
      className={styles(style.inputOption, others.className)}
    >
      <Primitive
        {...{ disabled, error, id, name, type, value }}
        checked={checked || (type === CHECKBOX && indeterminate)}
        aria-label={label}
      >
        {type === CHECKBOX && (checked || indeterminate) ? (
          <Icon value={checked ? ICON.CHECK : ICON.REMOVE} paragraph={!isMobile} className={style.icon} />
        ) : undefined}
      </Primitive>
      {label && (
        <Label
          action={!small}
          small={small}
          {...{ disabled, for: id, label, required: showRequired && required }}
          className={style.label}
        />
      )}
    </Pressable>
  );
};

InputOption.displayName = 'Component:InputOption';

InputOption.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  indeterminate: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  showRequired: PropTypes.bool,
  small: PropTypes.bool,
  type: PropTypes.oneOf([CHECKBOX, RADIO, SWITCH]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
};

export { InputOption };
