html {
  scroll-behavior: smooth;
}

.settings {
  align-items: flex-start;
  gap: var(--mirai-ui-space-L);
}

.settings .menu {
  background-color: var(--mirai-ui-menu-base);
  border-radius: var(--mirai-ui-menu-border-radius);
  box-shadow: inset 0 0 0 var(--mirai-ui-border-width) var(--mirai-ui-menu-option-disabled);
  /* min-width: var(--mirai-ui-menu-min-width); */
  overflow: hidden;
  width: var(--mirai-ui-breakpoint-S);
}

.menu .option {
  display: flex;
  flex-direction: var(--mirai-ui-row-direction);
  gap: var(--mirai-ui-menu-option-padding);
  padding: var(--mirai-ui-menu-option-padding);
  width: 100%;
}

.menu .option:hover *,
.menu .option.active * {
  color: var(--mirai-ui-accent);
}

.menu .option:not(:last-child) {
  box-shadow: inset 0 calc(var(--mirai-ui-border-width) * -1) var(--mirai-ui-menu-option-disabled);
}

.settings .form {
  gap: var(--mirai-ui-space-M);
}

.form .box {
  background-color: var(--mirai-ui-content-background);
  border-radius: var(--mirai-ui-border-radius);
  gap: var(--mirai-ui-space-M);
  margin-top: var(--mirai-ui-space-L);
  padding: var(--mirai-ui-space-M);
}

.form .notification {
  margin-bottom: var(--mirai-ui-space-M);
}

.modal {
  padding: var(--mirai-ui-space-M);
  gap: var(--mirai-ui-space-M);
}

.modal .description {
  text-align: center;
}

.modal .buttons {
  gap: var(--mirai-ui-space-M);
}

/* S  */
@media only screen and (max-width: 480px) {
  .settings .menu {
    width: 100%;
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .settings .menu {
    width: var(--mirai-ui-breakpoint-S);
  }
}
