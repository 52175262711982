import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Pressable, Radio, styles, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getCaption, getComponent, getImage } from './helpers';
import { DATE_FORMAT, METHOD, PROVIDER, URL_IMAGES } from './Payment.constants';
import { L10N } from './Payment.l10n';
import * as style from './Payment.module.css';
import { EVENT, SUBMIT_DELAY } from '../../helpers';

let timeoutChange;

const Payment = ({
  children,
  config = {},
  currency,
  error = {},
  info = {},
  methods = [],
  showErrors = false,
  onChange = () => {},
  onError = () => {},
  ...others
}) => {
  const { isDesktop, isMobile } = useDevice();
  const { currencyFormat, dateFormat, translate } = useLocale();
  const {
    set,
    value: { components = {} },
  } = useStore();

  const [cardType, setCardType] = useState();
  const [method, setMethod] = useState(methods.length > 0 ? methods[0] : undefined);

  useEffect(() => {
    onChange({ method });
    set({ payment: { method } });
    onError({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method]);

  const handleChange = (values = {}) => {
    const { type } = values;

    type !== cardType && setCardType(type);
    const next = { method, ...values };
    onChange(next);
    set({ payment: { ...next } });

    if (metrics && Object.keys(values).length > 0) {
      clearTimeout(timeoutChange);
      timeoutChange = setTimeout(
        () => Event.publish(EVENT.METRICS, { id: `${metrics}:PAYMENT:FORM`, method }),
        SUBMIT_DELAY,
      );
    }
  };

  const handleChangeMethod = (nextMethod) => {
    setMethod(nextMethod);
    if (metrics) Event.publish(EVENT.METRICS, { id: `${metrics}:PAYMENT:CHANGE_METHOD`, method: nextMethod });
  };

  const { testId = 'payments' } = others;
  const standalone = methods.length === 1;
  const availableMethods = methods.filter((item) => !!METHOD[item]);
  const metrics = components.checkout ? 'CHECKOUT' : components.booking ? 'BOOKING' : undefined;

  return (
    <View {...others} role="payments" tag="payment" row={false} className={styles(style.container, others.className)}>
      {availableMethods.length > 1 && (
        <View className={style.item}>
          <Text bold headline={!isMobile} className={style.header}>
            {translate(L10N.LABEL_PAYMENT_METHODS)}
          </Text>
        </View>
      )}
      {availableMethods.map((item) => {
        const selected = method === item;
        const caption = selected ? getCaption({ method, ...info, ...config }) : undefined;
        const component = selected ? getComponent({ method: item, ...info, ...config }) : undefined;
        const image = getImage({ method: item });
        const { time = {} } = info || {};

        return (
          <View key={item} className={style.item}>
            <Pressable
              disabled={selected || standalone}
              onPress={() => handleChangeMethod(item)}
              className={styles(style.header, selected && style.selected)}
              testId={testId ? `${testId}-item-${item}` : undefined}
            >
              {!standalone && <Radio checked={selected} name="method" value={item} className={style.radio} />}
              <Text
                bold
                headline={isDesktop && standalone}
                className={styles(style.title, selected && style.selected)}
                testId={testId ? `${testId}-text-${item}` : undefined}
              >
                {translate(L10N[`LABEL_${item}`])}
              </Text>

              {image && (
                <View
                  alt={`${item?.split('_').join(' ')}`}
                  src={`${process.env.SERVICE_STATIC}/${URL_IMAGES}/${image}`}
                  tag="img"
                  className={style.image}
                />
              )}

              {[METHOD.CARD, METHOD.CARD_PREPAY, METHOD.PCIPROXY, METHOD.TPV].includes(item) &&
                (config.cards || []).map((card) => (
                  <View
                    alt={card}
                    key={card}
                    src={`${process.env.SERVICE_STATIC}/${URL_IMAGES}/cards/${card.toLowerCase()}.png`}
                    tag="img"
                    className={styles(style.image, selected && cardType && card !== cardType && style.disabled)}
                  />
                ))}
            </Pressable>

            {selected && (
              <View className={style.content} testId={testId ? `${testId}-container-${item}` : undefined}>
                {caption && (
                  <Text small className={styles(style.caption, component && style.withComponent)}>
                    {translate(caption, {
                      amount: currencyFormat({ currency, value: info?.amount }),
                      date: dateFormat(info?.date, DATE_FORMAT),
                      day: translate(L10N.LABEL_DAY, { value: time.value }),
                      hour: translate(L10N.LABEL_HOUR, { value: time.value }),
                      type: time.type,
                    })}
                  </Text>
                )}

                {component &&
                  React.createElement(component, {
                    error,
                    showErrors,
                    ...others,
                    ...config,
                    onChange: handleChange,
                    onError,
                  })}
              </View>
            )}
          </View>
        );
      })}

      {children && <View className={style.children}>{children}</View>}
    </View>
  );
};

Payment.displayName = 'Mirai:Core:Payment';

Payment.propTypes = {
  children: PropTypes.any,
  config: PropTypes.shape({
    cards: PropTypes.arrayOf(PropTypes.string),
    provider: PropTypes.oneOf(Object.values(PROVIDER)),
    showCVV: PropTypes.bool,
  }),
  currency: PropTypes.string,
  error: PropTypes.object,
  info: PropTypes.shape({
    cardValidation: PropTypes.bool,
    date: PropTypes.instanceOf(Date),
    amount: PropTypes.number,
    time: PropTypes.shape({
      type: PropTypes.number,
      value: PropTypes.number,
    }),
  }),
  methods: PropTypes.array.isRequired,
  showErrors: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
};

export { Payment };
