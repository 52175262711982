import { Event } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Modal, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import * as style from './ModalOfferInfo.module.css';
import { EVENT } from '../../../../../helpers';
import { L10N } from '../../Item.l10n';

const ModalOfferInfo = ({ children, description, included = [], name, visible: propVisible, onClose }) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();

  const [visible, setVisible] = useState(propVisible);

  useEffect(() => {
    setVisible(propVisible);
    if (propVisible) Event.publish(EVENT.METRICS, { id: 'RATES:ITEM:OFFER_INFO' });
  }, [propVisible]);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300);
  };

  const textProps = { action: !isMobile, small: isMobile };

  return (
    <Modal
      {...{ visible }}
      fit
      title={name}
      visible={visible}
      onClose={handleClose}
      onOverflow={handleClose}
      className={style.modal}
    >
      <View className={style.content}>
        {description && (
          <View className={style.section}>
            <Text bold upperCase>
              {translate(L10N.LABEL_DESCRIPTION)}
            </Text>
            <Text {...textProps}>{description}</Text>
          </View>
        )}

        {included.length > 0 && (
          <View className={[style.section, style.included]}>
            <Text bold upperCase>
              {translate(L10N.LABEL_WHATS_INCLUDED)}
            </Text>
            {included.map((item, index) => (
              <View key={index} row className={style.row}>
                <Text small>•</Text>
                <Text {...textProps}>{item}</Text>
              </View>
            ))}
          </View>
        )}

        {children}
      </View>
    </Modal>
  );
};

ModalOfferInfo.displayName = 'Mirai:Core:Rates:Item:ModalOfferInfo';

ModalOfferInfo.propTypes = {
  availableBoards: PropTypes.array,
  children: PropTypes.node,
  included: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  name: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export { ModalOfferInfo };
