.container {
  backdrop-filter: blur(8px);
  background-color: var(--mirai-chat-base-opacity);
  bottom: 0;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  pointer-events: none;
  position: absolute;
  opacity: 0;
  transition: opacity var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  transition-delay: var(--mirai-ui-motion-expand);
  width: 100%;
  z-index: 0;
}

.container.visible {
  opacity: 1;
  pointer-events: all;
  transition-delay: var(--mirai-ui-motion-expand);
}

.content {
  transform: translateY(100%);
  transition: transform var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  transition-delay: 0;
}

.content.visible {
  transform: translateY(0%);
  transition-delay: var(--mirai-ui-motion-expand);
}

.content .header {
  gap: calc(var(--mirai-chat-space) / 2);
  padding: var(--mirai-chat-space);
}

.content .component {
  margin: 0 var(--mirai-chat-space);
}

.content .component:not(.inputText) {
  border: solid var(--mirai-ui-border-width) var(--mirai-ui-content-border);
  border-radius: var(--mirai-chat-border-radius);
}

.content .component.inputText > * {
  margin-bottom: 0;
}

.content .component > * {
  width: 100%;
}

.content .footer {
  gap: var(--mirai-chat-space);
  padding: var(--mirai-chat-space);
}

.content .footer > * {
  flex: 1;
}

/* -- InputRich.List.jsx */
.list {
  flex: 1;
}

.list .item {
  display: flex;
  flex-direction: row;
  gap: calc(var(--mirai-chat-space) / 2);
  padding: var(--mirai-chat-message-offset);
  width: 100%;
}

.list .item.category {
  cursor: default;
  padding-bottom: 0;
}

.list .item.category:not(:first-child) {
  border-top: solid var(--mirai-ui-border-width) var(--mirai-ui-content-border);
}

.list .item.option.selected,
.list .item.option:hover {
  background-color: var(--mirai-chat-text-color);
}

.list .item.option.selected *,
.list .item.option:hover * {
  color: var(--mirai-chat-text-accent-color);
}

/* S */
@media only screen and (max-width: 480px) {
  .list {
    max-height: calc(100svh - calc(var(--mirai-ui-space-XL) * 3.2));
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .list {
    max-height: calc(70svh - calc(var(--mirai-ui-space-XL) * 3.2));
  }
}
