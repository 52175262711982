const IS_JEST = !!process?.env?.JEST_WORKER_ID;

import { ServiceClub, ServiceEngine } from '@mirai/services';

export const fetchConfig = async ({ id, language, type = 'hotel' } = {}) => {
  if (!id) return undefined;

  const club = !IS_JEST ? await ServiceClub.get({ id, language }) : undefined;
  const config = await ServiceEngine.get({ id, type });

  if (config && club) config.club = club;

  return config;
};
