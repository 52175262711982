import { Event, useStore } from '@mirai/data-sources';
import { useLocale } from '@mirai/locale';
import { Button, Calendar, InputText, styles, Text, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getVerboseList, getVerboseOccupation } from './helpers';
import { L10N } from './InputRich.l10n';
import { List } from './InputRich.List';
import * as style from './InputRich.module.css';
import { Occupation } from '../../../__shared__';
import { EVENT } from '../../Chat.constants';
import { getVerboseDate } from '../../helpers';
import { Avatar } from '../Avatar';

const CALENDAR_PROPS = { format: 'DD/MM/YYYY', months: 12, range: true, vertical: true };
const INPUT_TEXT_TYPES = ['text', 'password', 'email', 'number'];

const InputRich = ({ disabled = false, onValue = () => {}, ...others }) => {
  const { dateFormat, translate } = useLocale();
  const {
    value: { lisa, locale, ...store },
  } = useStore();

  const [dataSource, setDataSource] = useState();
  const [value, setValue] = useState();
  const [valid, setValid] = useState();
  const [visible, setVisible] = useState();

  useEffect(() => {
    const callback = ({ requirement = {}, ...others } = {}) => {
      if (!requirement.name) return;

      setDataSource({ ...requirement, ...others });
      setValid(); // ! TODO: In some cases (occupation & promocode) we should set "something"
      setValue();
      setVisible(true);
    };

    Event.subscribe(EVENT.INPUT_COMPONENT, callback);
    return () => Event.unsubscribe(EVENT.INPUT_COMPONENT, callback);
  }, [lisa, setDataSource]);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleChange = (value) => {
    const { type } = dataSource;
    let next = value;

    if (type === 'calendar') {
      const [from, to] = value || [];
      next = [from ? dateFormat(from, CALENDAR_PROPS) : undefined, to ? dateFormat(to, CALENDAR_PROPS) : undefined];
      setValid(!!from && !!to);
    }

    setValue(next);
  };

  const handleSubmit = () => {
    const { intent, response: { form = {}, value: context = {} } = {} } = lisa;
    const { name, required, onSubmit, ...component } = dataSource;

    if (onSubmit) {
      const text = is.calendar
        ? getVerboseDate(value, locale)
        : is.occupation
        ? getVerboseOccupation({ store, translate, value })
        : is.list
        ? getVerboseList(value, component)
        : value;

      onSubmit({ text, value });
    }

    onValue({
      intent,
      context,
      form: { ...form, [name]: is.text && !required && !value ? ' ' : value },
    });
    setVisible(false);
  };

  const { defaultValue, name, required, text, type, ...component } = dataSource || {};
  const is = {
    calendar: type === 'calendar',
    list: type === 'list',
    occupation: type === 'occupation',
    promocode: name === 'promocode',
    text: INPUT_TEXT_TYPES.includes(type),
  };

  return (
    <View {...others} className={[style.container, visible && style.visible]}>
      <View className={[style.content, visible && style.visible]}>
        <View row className={style.header}>
          <Avatar small />

          <Text bold wide>
            {text}
          </Text>
        </View>

        <View className={styles(style.component, is.text && style.inputText)} st>
          {type &&
            React.createElement(is.calendar ? Calendar : is.occupation ? Occupation : is.list ? List : InputText, {
              ...component,
              ...(is.calendar ? CALENDAR_PROPS : {}),
              hint: is.promocode ? translate(L10N.LABEL_PROMOCODE_HINT) : undefined,
              locale,
              value: value || defaultValue,
              onChange: handleChange,
              onError: () => setValid(false),
              onValid: () => setValid(true),
              onSubmit: undefined,
            })}
        </View>

        <View row wide className={style.footer}>
          <Button transparent large onPress={handleCancel}>
            {translate(L10N.ACTION_CANCEL)}
          </Button>

          <Button disabled={disabled || (required && !valid)} large onPress={handleSubmit}>
            {translate(L10N.ACTION_CONTINUE)}
          </Button>
        </View>
      </View>
    </View>
  );
};

InputRich.propTypes = {
  disabled: PropTypes.bool,
  onValue: PropTypes.func,
};

export { InputRich };
