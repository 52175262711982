.container {
  background-color: var(--mirai-chat-input-background);
  border-radius: var(--mirai-chat-border-radius);
  box-shadow: 0 0 0 var(--mirai-chat-border-size) var(--mirai-chat-input-border-color);
  justify-content: center;
  margin: calc(var(--mirai-chat-space) * -1) var(--mirai-chat-space) var(--mirai-chat-space) var(--mirai-chat-space);
  padding-right: calc(var(--mirai-chat-space) / 2);
  z-index: 0;
}

.input {
  background-color: transparent;
  color: var(--mirai-chat-text-color);
  font-size: var(--mirai-chat-text-font-size);
  font-weight: var(--mirai-chat-text-font-weight);
  height: calc(var(--mirai-chat-text-line-height) + calc(var(--mirai-chat-space) * 2));
  line-height: var(--mirai-chat-text-line-height);
  padding: var(--mirai-chat-space);
}

.input::placeholder {
  color: var(--mirai-chat-text-color-light);
  font-weight: var(--mirai-ui-font-weight);
  opacity: 0.5;
}
