export const L10N = {
  ACTION_SIGNOUT_CANCEL: { id: 'common.action.close' },

  BUTTON_SIGNOUT: { id: 'user.action.delete_account', defaultMessage: '　' },

  COUNTRY_LABEL: { id: 'user.label.country', defaultMessage: '　' },
  COUNTRY_PLACEHOLDER: { id: 'common.label.select' },
  CTA_SIGNOUT: { id: 'user.action.delete_account' },
  CTA_ACCOUNT: { id: 'user.action.save_info', defaultMessage: '　' },
  CTA_PASSWORD: { id: 'user.action.save_password', defaultMessage: '　' },
  CTA_SIGNUP: { id: 'user.action.signup_again' },

  DATEOFBIRTH_LABEL: { id: 'user.label.date_of_birth', defaultMessage: '　' },
  DATEOFBIRTH_ERROR: { id: 'user.label.date_of_birth_error' },
  DESCRIPTION_ACCOUNT: { id: 'user.label.description_account', defaultMessage: '　' },
  DESCRIPTION_PASSWORD: { id: 'user.label.description_password', defaultMessage: '　' },
  DESCRIPTION_SIGNOUT: { id: 'user.label.description_delete_account', defaultMessage: '　' },
  DESCRIPTION_SIGNOUT_MODAL: { id: 'user.label.modal_delete_account' },
  DESCRIPTION_SIGNOUT_MODAL_SUCCESS: { id: 'user.label.account_deleted' },

  EMAIL_LABEL: { id: 'common.label.email', defaultMessage: '　' },

  FIRSTNAME_LABEL: { id: 'common.label.name', defaultMessage: '　' },

  LASTNAME_LABEL: { id: 'common.label.lastname', defaultMessage: '　' },

  PASSWORD_LABEL: { id: 'common.label.password', defaultMessage: '　' },
  PASSWORD_HINT: { id: 'user.label.password_hint', defaultMessage: '　' },
  PASSWORD_NEW_LABEL: { id: 'common.label.new_password', defaultMessage: '　' },
  PASSWORD_NEW_CONFIRM_LABEL: { id: 'user.label.confirm_new_password', defaultMessage: '　' },

  SUBSCRIBED_LABEL: { id: 'user.label.subscribe', defaultMessage: '　' },

  TITLE_SIGNOUT: { id: 'user.label.delete_account', defaultMessage: '　' },

  // Notifications
  SUCCESS_ACCOUNT: { id: 'user.notification.update_success' },
  SUCCESS_PASSWORD: { id: 'user.notification.update_password_success' },
  SUCCESS_SIGNOUT: { id: 'user.notification.delete_account_success' },

  ERROR: { id: 'common.notification.network_error' },
  ERROR_PASSWORD: { id: 'common.notification.password_error' },

  LABEL_PERSONAL_DETAILS: { id: 'user.label.personal_details', defaultMessage: '　' },
  LABEL_SECURITY: { id: 'user.label.security', defaultMessage: '　' },
};
