import { PERFORMANCE } from '../../Core.constants';

const DEFAULT_PERFORMANCE = [
  {
    type: 1,
    instances: [{ idAnalytics: 'G-EW4M987YRE', propertyId: '337963856', serviceAccount: 'ANALYTICS11', id: 42011 }],
  },
  {
    type: 2,
    instances: [{ idAdwords: '604678444', conversionLabel: 'S0SLCLqv8toBEKzSqqAC', cookiePrefix: 'MIRAI', id: 55146 }],
  },
  {
    type: 3,
    instances: [
      { idGTM: 'GTM-M3CPG48', id: 55265 },
      { idGTM: 'GTM-PZG8RRXL', id: 55266 },
      { idGTM: 'GTM-M3CPG48', id: 55267 },
    ],
  },
  { type: 4, instances: [{ id: 47811 }] },
  { type: 5, instances: [{ pixelId: '999999999', testEventCode: 'TEST9826', id: 55182 }] },
  { type: 6, instances: [{ id: 55179 }] },
  { type: 7, instances: [{ idAffilired: '999999', id: 55178 }] },
  { type: 8, instances: [{ idBing: '5979008', id: 55180 }] },
  { type: 9, instances: [{ id: 42010 }] },
];

const CONSENT_MODE_ANSWERED = [PERFORMANCE.ANALYTICS, PERFORMANCE.COMPARE, PERFORMANCE.GTM, PERFORMANCE.GOOGLE_ADS];

const CONSENT_MODE_REQUIRED = [
  PERFORMANCE.AFFILIRED,
  PERFORMANCE.BING,
  PERFORMANCE.FACEBOOK,
  PERFORMANCE.THE_HOTEL_NETWORK,
];

const KPI_EVENTS = ['RATES:RENDER', 'CHECKOUT:RENDER', 'BOOKING:RENDER'];

export { DEFAULT_PERFORMANCE, CONSENT_MODE_ANSWERED, CONSENT_MODE_REQUIRED, KPI_EVENTS };
