/* eslint-disable no-async-promise-executor */
import { LocalAdapter, Storage, request } from '@mirai/data-sources';

import { CACHE_LIMIT, ERROR, EXTERNAL_SERVICE, USER_STORAGE_KEY } from './User.constants';

export const externalLogin = (token, type) =>
  new Promise(async (resolve, reject) => {
    if (!token || !type) return reject(ERROR.NOT_FILLED);

    const response = await request({
      hostname: process.env.SERVICE_USER,
      endpoint: `/club/v1/${EXTERNAL_SERVICE[type]}/session?token=${token}`,
    }).catch(reject);

    if (response?.accessToken) {
      const session = {
        authorization: response.accessToken,
        club: {
          id: response.idClub,
          name: response.clubName,
        },
        email: response.email,
        firstName: response.firstName,
        lastName: response.lastName,
        level: response.level,
      };

      const storage = new Storage({ adapter: LocalAdapter, cache: CACHE_LIMIT });
      storage.set(`${USER_STORAGE_KEY}:session`, session);

      resolve(session);
    } else {
      reject(ERROR.UNKNOWN);
    }
  });
