.container {
  background-color: var(--mirai-chat-base);
  gap: var(--mirai-chat-space);
  overflow: hidden;
  z-index: var(--mirai-chat-layer);
}

/* -- header ---------------------------------------------------------------- */
.header {
  justify-content: center;
  backdrop-filter: blur(8px);
  background: var(--mirai-chat-header-background);
  position: fixed;
  transition: height var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  width: 100%;
  z-index: 0;
}

.header .bullet {
  height: var(--mirai-ui-space-XS);
  width: var(--mirai-ui-space-XS);
  border-radius: 50%;
  margin-right: var(--mirai-ui-space-XXS);
}

.header .bullet.online {
  background-color: var(--mirai-ui-success);
}

.header .bullet.offline {
  background-color: var(--mirai-ui-error-light);
}

.header > * {
  padding: calc(var(--mirai-chat-space) / 2) var(--mirai-chat-space);
}

.header .content {
  height: var(--mirai-chat-header-height);
  gap: calc(var(--mirai-chat-space) / 2);
}

.header .form {
  box-shadow: 0 var(--mirai-chat-border-size) var(--mirai-chat-border-color),
    0 calc(var(--mirai-chat-border-size) * -1) var(--mirai-chat-border-color);
}

.header .form,
.header .field {
  gap: calc(var(--mirai-chat-space) / 1);
}

/* -------------------------------------------------------------------------- */
.messages {
  flex: 1;
  gap: calc(var(--mirai-chat-space) / 2);
  padding-bottom: calc(var(--mirai-chat-space) * 2);
  padding-top: calc(var(--mirai-chat-header-height) + var(--mirai-chat-space));
  transition: padding-top var(--mirai-ui-motion-expand) var(--mirai-ui-motion-easing);
  width: 100%;
}

/* -- button ---------------------------------------------------------------- */
.buttonAvatar {
  bottom: var(--mirai-chat-space);
  position: fixed;
  right: var(--mirai-chat-space);
  transition: transform var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
  transform: translateY(150%);
  z-index: var(--mirai-chat-layer);
}

.buttonAvatar.visible {
  transform: translateY(0%);
}

.buttonAvatar .avatar {
  box-shadow: var(--mirai-chat-box-shadow);
}

.buttonAvatar .welcome {
  background-color: var(--mirai-chat-base);
  border-radius: var(--mirai-chat-border-radius);
  bottom: 64px;
  box-shadow: var(--mirai-chat-box-shadow);
  margin-bottom: var(--mirai-chat-space);
  opacity: 0;
  padding: var(--mirai-chat-space);
  position: absolute;
  right: 0;
  transform-origin: bottom right;
  transform: scale(0);
  transition: all var(--mirai-ui-motion-collapse) cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.buttonAvatar .welcome.visible {
  opacity: 1;
  transform: scale(1);
}

.buttonAvatar .welcome:before {
  border-color: var(--mirai-chat-base) transparent transparent transparent;
  border-style: solid;
  border-width: calc(var(--mirai-chat-space) / 2);
  bottom: calc(var(--mirai-chat-space) / -1);
  content: '';
  display: block;
  height: 0;
  position: absolute;
  right: var(--mirai-ui-space-L);
  width: 0;
}

/* S */
@media only screen and (max-width: 480px) {
  .container {
    height: 100vh;
  }

  .welcome {
    width: calc(100vw - calc(var(--mirai-ui-space-M) * 2));
  }
}

/* M & L */
@media only screen and (min-width: 481px) {
  .container {
    border-radius: var(--mirai-chat-border-radius);
    bottom: var(--mirai-chat-space);
    box-shadow: var(--mirai-chat-box-shadow);
    max-height: var(--mirai-chat-max-height);
    max-width: var(--mirai-chat-width);
    height: var(--mirai-chat-height);
    min-height: var(--mirai-chat-min-height);
    position: fixed;
    right: var(--mirai-chat-space);
    transform: translateY(calc(var(--mirai-chat-max-height) + calc(var(--mirai-chat-space) * 2)));
    transition: transform var(--mirai-ui-motion-collapse) var(--mirai-ui-motion-easing);
    width: 100%;
  }

  .container.visible {
    transform: translateY(0%);
  }

  .welcome {
    width: calc(var(--mirai-ui-breakpoint-S) - calc(var(--mirai-ui-space-XXL) * 2));
  }
}

@media print {
  .container {
    display: none;
  }
}
