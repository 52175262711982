import { useStore } from '@mirai/data-sources';
import { Icon, Pressable, styles, Text, Tooltip, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Card, Environment } from './components';
import { getTime } from './helpers';
import { Action } from './Message.Action';
import { INTENT } from './Message.constants';
import * as style from './Message.module.css';
import { ICON } from '../../../helpers';

// ! TODO: Refacto use of intent/response vs lisa.intent/response
const Message = ({ auto, busy = false, intent, response = {}, text, timestamp, onRetry, ...others }) => {
  const { value: { urlParams = {} } = {} } = useStore();

  const [cardResponse, setCardResponse] = useState();

  const { actions = [] } = response;
  const { disabled, testId, onAction } = others;

  return (
    <View testId={testId} wide>
      {timestamp && (
        <Text light small className={style.timestamp}>
          {getTime(timestamp)}
        </Text>
      )}
      <View row wide className={styles(style.message, auto && style.auto)}>
        <View className={styles(style.content, auto && style.auto, busy && style.busy)}>
          {!busy && auto && intent && urlParams.lisa === 'environment' && (
            <Environment {...{ intent, ...others, ...response }} />
          )}

          {busy ? (
            <View className={style.spinner} />
          ) : INTENT[intent] ? (
            React.createElement(INTENT[intent], { intent, response, ...others })
          ) : text || response.text ? (
            <Text action className={style.text}>
              {text || response.text}
            </Text>
          ) : null}

          {cardResponse ? (
            <Card disabled={disabled} option={false} {...cardResponse} />
          ) : (
            <>
              {actions.map((action, index) => (
                <Action
                  {...action}
                  key={`chat.message:action:${index}`}
                  onPress={onAction}
                  onResponse={setCardResponse}
                />
              ))}
            </>
          )}
        </View>

        {onRetry && auto && (
          <View className={style.actions}>
            <Tooltip text="Retry">
              <Pressable onPress={onRetry}>
                <Icon light _paragraph value={ICON.REFRESH} />
              </Pressable>
            </Tooltip>
          </View>
        )}
      </View>
    </View>
  );
};

Message.propTypes = {
  auto: PropTypes.bool,
  busy: PropTypes.bool,
  disabled: PropTypes.bool,
  intent: PropTypes.string,
  response: PropTypes.shape({
    actions: PropTypes.arrayOf(PropTypes.shape({})),
    requirements: PropTypes.shape({}),
    text: PropTypes.string,
    value: PropTypes.oneOf([PropTypes.array, PropTypes.shape({})]),
  }),
  text: PropTypes.string,
  timestamp: PropTypes.number,
  onAction: PropTypes.func,
  onRetry: PropTypes.func,
};

export { Message };
