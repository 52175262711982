.inputOption {
  align-items: center;
  display: flex;
  flex-direction: var(--mirai-ui-row-direction);
  gap: var(--mirai-ui-input-option-gap-x);
  margin-bottom: var(--mirai-ui-input-option-gap-y);
  position: relative;
  width: 100%;
}

.icon {
  color: var(--mirai-ui-base);
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
}

.label {
  color: var(--mirai-ui-input-text-focus);
}

@media only screen and (max-width: 480px) {
  .icon {
    margin: calc(calc(var(--mirai-ui-checkbox-size-mobile) - var(--mirai-ui-input-text-icon)) / 2);
  }
}

@media only screen and (min-width: 481px) {
  .icon {
    margin: var(--mirai-ui-border-width);
  }
}
