export const L10N = {
  ACTION_CLOSE: { id: 'common.action.close' },

  LABEL_ASSISTANT: { id: 'lisa.label.assistant' },

  LABEL_GREETINGS: { id: 'lisa.label.greetings' },
  LABEL_GUEST: { id: 'lisa.label.guest' },

  LABEL_OFFLINE: { id: 'lisa.label.offline' },
  LABEL_ONLINE: { id: 'lisa.label.online' },

  LABEL_WHO: { id: 'finder.label.who' },
};
