import * as Sentry from '@sentry/react';

export const loadSentry = (dsn, release) => {
  if (!dsn || !release) return;

  Sentry.init({
    allowUrls: [/https?:\/\/((static)\.)?mirai\.com/],
    beforeSend(event, hint) {
      // eslint-disable-next-line no-console
      console.log({ event, hint });

      let launchEvent = true;
      const { breadcrumbs = [] } = event;

      for (let index = breadcrumbs.length - 1; index >= 0; index--) {
        const breadcrumb = breadcrumbs[index];
        if (!breadcrumb) continue;

        if (breadcrumb.level !== 'error' || breadcrumb.category === 'xhr' || breadcrumb.category === 'fetch') {
          launchEvent = false;
          break;
        }
      }

      return launchEvent ? event : null;
    },
    denyUrls: [
      new RegExp(String.raw`\s${window.location.origin}\s`, 'g'),
      /elementor/g,
      /mirai-loader/g,
      /mirai-room-selection/g,
      /mirai-club-login/g,
      /amplify/g,
      /pushtech/,
      /wp-includes/g,
      /hijiffy/g,
      /hotelmonitor/g,
      /flickity/g,
      /bat.bing.com/g,
      /new.smartrental.com/g,
    ],
    dsn,
    release,
    integrations: [
      new Sentry.Replay({
        networkDetailAllowUrls: /mirai/g,
      }),
    ],
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  return Sentry;
};
