import { parseDate } from '@mirai/locale';

import { CALENDAR_FORMAT as format } from '../../helpers';

export const calcNightWithDeals = (from, to, highlights) => {
  let value = 0;

  highlights.forEach((highlight) => {
    const highlightDate = parseDate(highlight, format);
    if (highlightDate >= from && highlightDate <= to) value++;
  });

  return value;
};
