import PropTypes from 'prop-types';
import React, { useLayoutEffect, useRef, useState } from 'react';

import { styles } from '../../helpers';
import { useDevice } from '../../hooks';
import { Primitive } from '../Primitive';
import { getElementLayout, getLayerPosition, getModalParent, getScrollParent, isComponentFixed } from './helpers';
import style from './Layer.module.css';
import { LayerContent } from './LayerContent';

const Layer = ({
  bottom: forceBottom,
  centered,
  fixed: propFixed,
  forceRender = true,
  left: forceLeft,
  right: forceRight,
  tag = 'layer',
  top: forceTop,
  timestamp,
  visible,
  onPosition = () => {},
  ...others
}) => {
  const device = useDevice();
  const componentRef = useRef();
  const contentRef = useRef();

  const [fixed, setFixed] = useState();
  const [position, setPosition] = useState();

  useLayoutEffect(() => {
    const content = contentRef?.current;
    if (!content || typeof ResizeObserver === 'undefined') return;

    const observer = new ResizeObserver(calcPosition);
    observer.observe(content);

    return () => observer.unobserve(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(
    () => calcPosition(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [centered, device, forceBottom, forceLeft, forceRight, forceTop, timestamp, visible],
  );

  const calcPosition = () => {
    const { current: { firstChild: componentEl } = {} } = componentRef;
    const { current: contentEl } = contentRef;

    if (!componentEl || !contentEl) return setPosition();

    const componentLayout = { ...getElementLayout(componentEl), scrollTop: getScrollParent(componentEl)?.scrollTop };
    const contentLayout = getElementLayout(contentEl);
    const modal = getModalParent(componentEl);
    const {
      left = 0,
      top = 0,
      orientation = {},
    } = getLayerPosition(componentLayout, contentLayout, device, modal, {
      centered,
      forceBottom,
      forceLeft,
      forceRight,
      forceTop,
    });

    setFixed(propFixed || isComponentFixed(componentEl));
    setPosition({ left: `${left}px`, top: `${top}px` });
    onPosition(orientation);
  };

  const children = React.Children.map(others.children || [], (child) => child);
  const { className, style: contentStyle } = others;
  const createWrapper = className || contentStyle;

  return (
    <>
      <Primitive ref={componentRef} role={others.role} tag={tag} className={style.container} testId={others.testId}>
        {children.find(({ type: { name } }) => name !== LayerContent.name)}
      </Primitive>

      {(forceRender || visible) && (
        <Primitive
          ref={contentRef}
          role={others.role ? `${others.role}-content` : undefined}
          tag={`${tag}-content`}
          className={styles(style.layer, fixed && style.fixed, !visible && style.hidden)}
          style={{ ...position }}
        >
          {React.createElement(
            createWrapper ? 'div' : React.Fragment,
            createWrapper ? { className, style: contentStyle } : undefined,
            children?.find(({ type: { name } }) => name === LayerContent.name),
          )}
        </Primitive>
      )}
    </>
  );
};

Layer.displayName = 'Primitive:Layer';

Layer.propTypes = {
  bottom: PropTypes.bool,
  centered: PropTypes.bool,
  children: PropTypes.node,
  fixed: PropTypes.bool,
  forceRender: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  timestamp: PropTypes.number,
  tag: PropTypes.string,
  top: PropTypes.bool,
  visible: PropTypes.bool,
  onPosition: PropTypes.func,
};

export { Layer };
