/* eslint-disable react-hooks/exhaustive-deps */
import { useStore } from '@mirai/data-sources';
import { dateFormat, dateDiff, useLocale } from '@mirai/locale';
import { ServiceDeals } from '@mirai/services';
import { Button, Icon, styles, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Calendar } from './Deals.Calendar';
import { FIELD, REDIRECT_PATH } from './Deals.constants';
import { L10N } from './Deals.l10n';
import * as style from './Deals.module.css';
import Skeleton from './Deals.skeleton';
import { getCurrency, validate } from './helpers';
import { prepareRoom } from '../../helpers/prepareRoom';
import { FieldOccupation, FieldPromocode } from '../__shared__';
import { toParties } from '../__shared__/Occupation/helpers/toParties';
import { ICON, toQueryString } from '../helpers';

const Deals = ({ id: offerId, ...others }) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const {
    set,
    value: {
      cookies = {},
      currency,
      deals: value = {},
      hotel,
      language,
      locale,
      origin,
      skeleton = false,
      urlParams = {},
      ...store
    },
  } = useStore();
  const ref = useRef(null);

  const [busy, setBusy] = useState(false);
  const [dataSource, setDataSource] = useState();
  const [error, setError] = useState({});
  const [visible, setVisible] = useState();
  const [visited, setVisited] = useState({});

  useEffect(() => {
    !isMobile && document.addEventListener('click', handleClickOutside, true);

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      !isMobile && document.removeEventListener('click', handleClickOutside, true);
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  useEffect(() => {
    if (skeleton) return;

    (async () => setDataSource(await getDataSource()))();
  }, [currency, locale]);

  useEffect(() => {
    const { occupation: { minRooms = 1, dataSource: occupation = [] } = {} } = dataSource || {};

    !value?.occupation &&
      occupation.length > 0 &&
      handleChange(
        'occupation',
        [...Array(minRooms)].map(() => prepareRoom(occupation)),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  useEffect(() => {
    visible && setVisited({ ...visited, [`${visible}`]: true });
  }, [visible]);

  const handleChange = (field, fieldValue) => {
    if (!dataSource) return;

    set({ deals: { ...value, [field]: fieldValue } });
  };

  const handleClear = () => {
    set({ deals: undefined });
    setError({});
    setVisible();
    setVisited(false);
  };

  const handleClickOutside = ({ target } = {}) =>
    !ref.current?.contains(target) && target.type !== 'text' && setVisible();

  const handlePageShow = async ({ persisted } = {}) => {
    if (!persisted) return;

    setBusy(false);
    handleClear();
    setDataSource(await getDataSource());
  };

  const handleSubmit = () => {
    setBusy(true);
    const { calendar, occupation, promoCode } = value;

    const params = {
      idoffers: offerId,
      idtokenprovider: hotel.id,
      lang: language,
      checkin: dateFormat(calendar[0], { format: 'DD/MM/YYYY' }),
      nights: dateDiff(calendar[0], calendar[1]).days,
      clientCode: promoCode || '',
      currency: getCurrency({ urlParams, cookies, currency }) || '',
      parties: window.btoa(JSON.stringify(toParties(occupation))),
      hsri: origin.commissionCode,
    };
    window.location.href = `${process.env.SERVICE_BOOKING}/${REDIRECT_PATH}${toQueryString(params)}`;

    setBusy(true);
  };

  const getDataSource = () =>
    ServiceDeals.get({
      ...store,
      currency: getCurrency({ cookies, currency, urlParams }),
      hotel,
      locale,
      offerId,
    }).catch(() => {});

  const { calendar = [], occupation, promoCode = '' } = value;

  const isValid = validate({ dataSource, error, value });

  return !skeleton ? (
    <View {...others} role="deals" className={styles(style.deals, others.className)}>
      <View row className={style.header}>
        <Text bold headline level={isMobile ? 3 : 2} markdown={false} className={style.text}>
          {translate(L10N.LABEL_SELECT_DATES_PERSONS)}
        </Text>

        <Button small={isMobile} transparent onPress={handleClear} testId="deals-button-clear">
          <Icon value={ICON.CLEAR} />
          {translate(L10N.ACTION_CLEAR)}
        </Button>
      </View>

      <Calendar
        {...dataSource?.calendar}
        value={calendar}
        visible={visible === FIELD.CALENDAR}
        visited={visited.calendar}
        onChange={(calendar, event) => handleChange('calendar', calendar, event)}
        onPress={() => setVisible(visible !== FIELD.CALENDAR ? FIELD.CALENDAR : undefined)}
        testId="deals-calendar"
      />

      <View row={!isMobile} className={style.extras}>
        <FieldOccupation
          {...dataSource?.occupation}
          error={!!error.occupation}
          hideLabel={!isMobile}
          hotels={[hotel?.id]}
          ref={ref}
          value={occupation}
          visible={visible === FIELD.OCCUPATION}
          onChange={(occupation) => handleChange('occupation', occupation)}
          onError={(occupation) => {
            if (!isMobile && visible !== FIELD.OCCUPATION) setVisible(FIELD.OCCUPATION);
            setError({ ...error, occupation });
          }}
          onPress={() => setVisible(visible !== FIELD.OCCUPATION ? FIELD.OCCUPATION : undefined)}
          onValid={() => setError({ ...error, occupation: undefined })}
          testId="deals-occupation"
        />

        <FieldPromocode
          value={promoCode}
          visible={visible === FIELD.PROMOCODE}
          onChange={(promoCode) => handleChange('promoCode', promoCode)}
          onEnter={() => setVisible(FIELD.PROMOCODE)}
          onLeave={() => setVisible(undefined)}
          testId="deals-promocode"
        />

        {!isMobile && <View className={style.footer} />}

        <Button
          busy={busy}
          disabled={!isValid}
          large
          wide={isMobile}
          onPress={handleSubmit}
          className={style.cta}
          testId="deals-button"
        >
          {translate(L10N.ACTION_SEARCH)}
        </Button>
      </View>
    </View>
  ) : (
    <Skeleton />
  );
};

Deals.propTypes = {
  id: PropTypes.string,
};

export { Deals };
