import { useLocale } from '@mirai/locale';
import { Button, styles, Theme, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { Hotelverse } from './components';
import { L10N } from './Rates.l10n';
import * as style from './Rates.module.css';
import { opacity } from '../helpers';

const Header = ({ children, dataSource = {}, multiRoom, onMapView = () => {}, onSelect = () => {}, ...others }) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();

  const { contentBackground } = Theme.get();
  const { hotelverse: { hash, token } = {}, hotels = [], items = [] } = dataSource;
  const hasHotelverse = items.length > 0 && !multiRoom && hash !== undefined && token !== undefined;

  return children || hasHotelverse || hotels.length > 1 ? (
    <View
      row
      className={styles(style.header, style.blur, multiRoom && !isMobile && style.sticky, others.className)}
      style={{ backgroundColor: opacity(contentBackground, 0.66) }}
    >
      {children}

      {hasHotelverse && <Hotelverse {...{ ...dataSource, items, multiRoom, onSelect, ...others }} />}

      {hotels.length > 1 && (
        <Button secondary wide={isMobile} onPress={() => onMapView(hotels)}>
          {translate(L10N.ACTION_SHOW_ON_MAP)}
        </Button>
      )}
    </View>
  ) : null;
};

Header.displayName = 'Mirai:Core:Rates:Header';

Header.propTypes = {
  children: PropTypes.node,
  dataSource: PropTypes.shape({}),
  features: PropTypes.shape({}),
  multiRoom: PropTypes.bool,
  nights: PropTypes.number,
  onMapView: PropTypes.func,
  onSelect: PropTypes.func,
};

export { Header };
