.input {
  background-color: var(--mirai-ui-input-background);
  box-sizing: border-box;
  color: var(--mirai-ui-input-color);
  direction: var(--mirai-ui-text-direction);
  font-family: var(--mirai-ui-input-font);
  font-size: var(--mirai-ui-input-font-size);
  outline: none;
  text-align: var(--mirai-ui-text-align);
  user-select: none;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input.autoResize {
  min-height: auto;
  overflow-y: hidden;
  resize: none;
}

.input:disabled {
  color: var(--mirai-ui-input-disabled);
}

.input::placeholder {
  color: var(--mirai-ui-input-disabled);
}

/* -- reset defaults -------------------------------------------------------- */
input.input,
textarea.input {
  border: none;
  margin: 0;
}

input.input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input.input::-ms-clear {
  appearance: none;
}

input.input:-webkit-autofill,
input.input:-webkit-autofill:hover,
input.input:-webkit-autofill:focus,
input.input:focus:required:invalid:focus {
  -webkit-box-shadow: 0 0 0px 1000px var(--mirai-ui-input-background) inset;
  -webkit-text-fill-color: var(--mirai-ui-input-color);
  transition: none;
}

input.input:focus:required:invalid {
  color: var(--mirai-ui-input-color);
}
