import { Event, useStore } from '@mirai/data-sources';
import { currencyFormat, useLocale } from '@mirai/locale';
import { Button, Modal, styles, Theme, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { customMarker, loadGMaps } from './helpers';
import { L10N } from './ModalMapView.l10n';
import * as style from './ModalMapView.module.css';
import { fetchUrlParams } from '../../../../helpers';
import { Card } from '../../../__shared__';
import { EVENT, SUBMIT_DELAY, toUrlParams } from '../../../helpers';
import { getFinderParams } from '../../helpers';

let memoizedSelected;

const ModalMapView = ({ items = [], nights = 1, visible, onClose, ...others }) => {
  const { isMobile } = useDevice();
  const { translate } = useLocale();
  const {
    value: { currency, finder = {}, forwarder = {}, hotel, locale, tags: { options: places = [] } = {} },
  } = useStore();

  const mapRef = useRef();
  const [busy, setBusy] = useState(false);
  const [map, setMap] = useState();
  const [selected, setSelected] = useState();

  const theme = Theme.get();
  const { icon, label } = customMarker(theme);
  const { accent, base } = theme;
  const { place: { title } = {} } = finder || {};

  const currencyFormatProps = {
    currency,
    fancy: true,
    locale,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    symbol: true,
  };
  useEffect(() => {
    if (!visible) return;

    loadGMaps()
      .then(() => {
        const bounds = new window.google.maps.LatLngBounds();
        const map = new window.google.maps.Map(mapRef.current, {
          fullscreenControl: false,
          mapTypeControl: false,
          styles: [{ featureType: 'poi.business', elementType: 'labels', stylers: [{ visibility: 'off' }] }],
        });

        map.addListener('click', () => setSelected());
        map.addListener('dragstart', () => setSelected());
        setMap(map);

        if (!visible) return;

        setSelected(undefined);
        map.markers = map.markers || [];
        map.markers.forEach((marker) => marker.setMap());
        map.markers = [];

        items.forEach((dataSource = {}) => {
          const item = { ...dataSource, position: { lat: dataSource.latitude, lng: dataSource.longitude } };
          const { price, position } = item;
          const text = currencyFormat({ ...currencyFormatProps, value: price });
          const marker = new window.google.maps.Marker({ map, position, icon, label: { ...label, text } });

          marker.addListener('click', () => setSelected(item));
          marker.addListener('mouseover', () => highlightMarker(marker, item));
          marker.addListener('mouseout', () => (memoizedSelected !== item ? resetMarker(marker, item) : undefined));

          map.markers.push(marker);
          bounds.extend(position);
        });

        if (items.length > 0) map.fitBounds(bounds);
      })
      .catch(() => {
        Event.publish(EVENT.NOTIFICATION, { error: true, ...L10N.NOTIFICATION_ERROR_MAP });
        onClose();
      });

    Event.publish(EVENT.METRICS, { id: 'HOTELS:MAP' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, visible]);

  useEffect(() => {
    memoizedSelected = selected;
    if (!map || !items.length) return;
    if (selected) map.panTo(selected.position);

    const itemIndex = selected ? items.findIndex(({ id }) => id === selected.id) : -1;
    map.markers.forEach((marker, index) => {
      index === itemIndex ? highlightMarker(marker, items[index]) : resetMarker(marker, items[index], index);
    });

    Event.publish(EVENT.METRICS, { id: 'HOTELS:MAP:SELECT' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const highlightMarker = (marker, { price } = {}) => {
    marker.setIcon({ ...icon, fillColor: accent, strokeColor: accent, scale: 1.1 });
    marker.setLabel({ ...label, color: base, text: priceMarker(price) });
    marker.setZIndex(1024);
  };

  const resetMarker = (marker, { price } = {}, index) => {
    marker.setIcon(icon);
    marker.setLabel({ ...label, text: priceMarker(price) });
    marker.setZIndex(index);
  };

  const priceMarker = (price) => currencyFormat({ ...currencyFormatProps, value: price });

  const handlePress = () => {
    setBusy(false);
    Event.publish(EVENT.METRICS, { id: 'HOTELS:MAP:VIEW_MORE' });

    setTimeout(() => {
      const { id } = selected;
      const { value } = places.find(({ isHotel, id: ids = [] } = {}) => isHotel && ids.includes(id.toString())) || {};

      window.location.href = `${forwarder.rates}${toUrlParams({
        ...fetchUrlParams(window.location.search),
        ...getFinderParams({ ...finder, ...{ place: { id: undefined, value } }, hotel }),
        idtokenprovider: id,
      })}`;
    }, SUBMIT_DELAY);
  };

  return (
    <Modal {...others} {...{ title, visible, onClose }} fit className={[style.modal, others.className]}>
      <View ref={mapRef} className={style.map} />

      <Card className={styles(style.card, selected && style.visible)}>
        <View
          className={style.image}
          style={selected?.images ? { backgroundImage: `url(${selected?.images[0]})` } : undefined}
        />
        <View className={style.content}>
          <Text bold headline={!isMobile}>
            {selected?.name}
          </Text>
          <Text action={!isMobile} light small={isMobile} className={style.location}>
            {selected?.features?.location}
          </Text>

          <View className={style.anchor} />

          <View row>
            <View>
              <Text bold>
                {currencyFormat({ ...currencyFormatProps, fancy: false, symbol: false, value: selected?.price })}
              </Text>
              <Text light={!isMobile} small={!isMobile} tiny={isMobile}>
                {currencyFormat({
                  ...currencyFormatProps,
                  fancy: false,
                  symbol: false,
                  value: selected?.price / nights,
                })}{' '}
                {translate(L10N.LABEL_PER_NIGHT)}
              </Text>
            </View>
            <View className={style.anchor} />
            <Button busy={busy} small onPress={handlePress}>
              {translate(L10N.ACTION_VIEW_MORE)}
            </Button>
          </View>
        </View>
      </Card>
    </Modal>
  );
};

ModalMapView.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  nights: PropTypes.number,
  title: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

ModalMapView.displayName = 'Mirai:Core:Rates:ModalMapView';

export { ModalMapView };
