import { dateDiff } from '@mirai/locale';

import { parseRate } from './helpers';
import { getFirstOccupationOption } from '../helpers';

export const ratesAddToCart = ({
  instances = [],
  response: { board: { id, name, rateId, roomId, roomName } = {}, cart = [], items = [], tracking = {} } = {},
  store: { currency, finder: { calendar = [], promocode = '' } = {} } = {},
} = {}) => {
  // ! -- TODO: use of tracking should be a temporary approach ---------------------------------------------------------
  const { hotelInfo, rates = [] } = tracking;

  const { rates: itemRates = [] } = items.find((item = {}) => item.id === roomId) || {};
  const { totalBasePrice } = getFirstOccupationOption({ rates, roomId })?.rates[rateId]?.boardOptions[id] || {};
  const { days } = dateDiff(...calendar);

  const value = {
    coupon: promocode,
    currency,
    items: [
      parseRate({
        hotelInfo,
        id,
        promocode,
        rate: {
          boardName: name,
          rateName: itemRates.find(({ id } = {}) => id === rateId)?.name,
          roomTypeName: roomName,
          totalPriceWithoutTaxes: totalBasePrice / days,
        },
      }),
    ],
    send_to: instances.map(({ idAnalytics }) => idAnalytics),
    value: cart.reduce((value, { basePrice = 0 } = {}) => value + basePrice, 0),
  };

  window.gtag('event', 'add_to_cart', value);

  return value;
};
