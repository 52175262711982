import { useStore } from '@mirai/data-sources';
import { Notification, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React from 'react';

import * as style from './Rates.module.css';
import { BannerLoyalty } from '../BannerLoyalty';

const Content = ({ dangerousChildren = [], increments, ...others }) => {
  const { isMobile } = useDevice();
  const {
    value: { club: { banner = {} } = {} },
  } = useStore();

  return !!banner || dangerousChildren.length > 0 || increments ? (
    <View {...others} className={style.extraContent}>
      {increments && (
        <Notification small={isMobile} warning wide>
          {increments}
        </Notification>
      )}

      {dangerousChildren.length ? (
        <View>
          {dangerousChildren.map((child, index) => (
            <View key={index} dangerouslySetInnerHTML={{ __html: child.outerHTML }} />
          ))}
        </View>
      ) : null}

      <View id="123-compare-plugin" />

      <BannerLoyalty {...others} />
    </View>
  ) : null;
};

Content.displayName = 'Mirai:Core:Rates:Content';

Content.propTypes = {
  bannerLoyalty: PropTypes.bool,
  dangerousChildren: PropTypes.arrayOf(PropTypes.any),
  increments: PropTypes.string,
};

export { Content };
