import { Event, useStore } from '@mirai/data-sources';
import { currencyFormat, useLocale } from '@mirai/locale';
import { Action, Button, Icon, Text, useDevice, View } from '@mirai/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { L10N } from './CardHotel.l10n';
import * as style from './CardHotel.module.css';
import { getHotelUrl } from './helpers';
import { fetchUrlParams } from '../../../../helpers';
import { Card } from '../../../__shared__';
import { EVENT, ICON, SUBMIT_DELAY, toUrlParams } from '../../../helpers';
import { getFinderParams, getLabelTaxes } from '../../helpers';
import { DISCOUNT_TYPE, MAX_PROMOCODE } from '../../Rates.constants';
import { PriceDetails, Slider } from '../Item/components';

const DEFAULT_WIDTH = 374;

const CardHotel = ({
  board = {},
  features: { distance, location, url } = {},
  hasDiscountClub = false,
  id,
  images = [],
  name,
  nights = 1,
  price: propPrice,
  priceFactor = 1,
  stars,
  taxes,
  unavailability,
} = {}) => {
  const { isMobile, width } = useDevice();
  const { locale, translate } = useLocale();
  const ref = useRef(null);
  const { value: { currency, finder, forwarder = {}, hotel, id: hotelId, tags: { options: places = [] } = {} } = {} } =
    useStore();

  const [busy, setBusy] = useState(false);
  const [sliderWidth, setSliderWidth] = useState(DEFAULT_WIDTH);

  useEffect(() => {
    if (ref.current) setSliderWidth(ref.current?.clientWidth - 1);
  }, [width]);

  const handlePress = () => {
    setBusy(true);
    Event.publish(EVENT.METRICS, { id: 'HOTELS:ITEM:SELECT' });

    setTimeout(() => {
      const { value } = places.find(({ isHotel, id: ids = [] } = {}) => isHotel && ids.includes(id.toString())) || {};
      setBusy(false);
      window.location.href = `${forwarder.rates}${toUrlParams({
        ...fetchUrlParams(window.location.search),
        ...getFinderParams({ ...finder, ...{ place: { id: undefined, value } }, hotel }),
        idtokenprovider: id,
      })}`;
    }, SUBMIT_DELAY);
  };

  const caption = { action: !isMobile, small: isMobile };
  const { features = {} } = hotel || {};
  const { place: { id: [placeId] = [], isHotel, title: placeName } = {}, promocode } = finder || {};
  const { name: hotelName } = (placeId ? (isHotel ? features[placeId] : { name: placeName }) : features[hotelId]) || {};
  const { confidential, discount: { breakdown = [] } = {}, originalPrice = 0 } = board;
  const price = propPrice / priceFactor;
  const showPricePerNight = priceFactor > 1;
  const currencyFormatProps = { currency, locale, maximumFractionDigits: 0, minimumFractionDigits: 0 };
  const promocodeForAll =
    breakdown.find(({ type }) => type === DISCOUNT_TYPE.PROMOTION) ||
    (breakdown.find(({ type }) => type === DISCOUNT_TYPE.DEAL) && confidential);
  const { percentage: clubPercentage = 0 } = breakdown.find(({ type } = {}) => type === DISCOUNT_TYPE.CLUB) || {};
  const { percentage: promoPercentage = 0 } = breakdown.find(({ type } = {}) => type === DISCOUNT_TYPE.PROMOTION) || {};

  return (
    <Card>
      <View ref={ref} className={style.card}>
        <View className={style.preview}>
          <Slider height={208} images={images} metrics="HOTELS:ITEM" width={sliderWidth} className={style.slider} />
        </View>

        {distance && (
          <View row className={[style.offset, style.spaceBetween]}>
            <View row className={style.row}>
              <Icon value={ICON.LOCATION} />
              <Text small>
                {translate(L10N.LABEL_DISTANCE, {
                  distance: Intl.NumberFormat(locale, {
                    style: 'unit',
                    unit: 'kilometer',
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  }).format(distance),
                  name: hotelName,
                })}
              </Text>
            </View>
          </View>
        )}

        <View className={style.offset}>
          <Text bold headline={!isMobile} level={3}>
            {name}
          </Text>
          {stars > 0 && (
            <Text headline={!isMobile} level={3}>
              {Array.from({ length: stars }, () => '★')}
            </Text>
          )}

          <Text {...caption}>{location}</Text>

          {url && (
            <Action
              href={unavailability ? getHotelUrl(url) : url}
              small={isMobile}
              target="_blank"
              underline
              onPress={() => Event.publish(EVENT.METRICS, { id: 'HOTELS:ITEM:SITE' })}
            >
              {translate(L10N.ACTION_VIEW_HOTEL)}
            </Action>
          )}
        </View>

        <View className={[style.offset, style.summary]}>
          <View row className={style.tags}>
            {hasDiscountClub && (
              <Text bold small className={style.tag}>
                {translate(L10N.LABEL_ONLY_MEMBERS_DISCOUNT, {
                  value: clubPercentage > 0 ? ` -${Math.round(clubPercentage)}%` : '',
                }).trimEnd()}
              </Text>
            )}
            {promocode && promocodeForAll && (
              <Text bold small className={style.tag}>
                <Text
                  bold
                  small
                  className={
                    promocode.length > (isMobile ? MAX_PROMOCODE.MOBILE : MAX_PROMOCODE.DESKTOP) && style.truncate
                  }
                >
                  {promocode.toUpperCase()}
                </Text>
                {promoPercentage ? ` -${Math.round(promoPercentage)}%` : ''}
              </Text>
            )}
          </View>
          <View row className={[style.gap, style.content]}>
            <View>
              <View row className={style.price}>
                <Text bold headline level={isMobile || price >= 10000 ? 3 : 2}>
                  {currencyFormat({ ...currencyFormatProps, value: price })}
                </Text>
                {originalPrice > 0 && price !== originalPrice && (
                  <Text action light className={style.lineThrough}>
                    {currencyFormat({ ...currencyFormatProps, value: originalPrice / priceFactor })}
                  </Text>
                )}
              </View>

              {nights > 1 && (
                <Text small={!isMobile} tiny={isMobile}>{`${currencyFormat({
                  ...currencyFormatProps,
                  value: showPricePerNight ? propPrice : price / nights,
                })} ${translate(showPricePerNight ? L10N.LABEL_FOR_NIGHTS : L10N.LABEL_PER_NIGHT, { nights })}`}</Text>
              )}

              <Text light={!isMobile} small={!isMobile} tiny={isMobile}>
                {translate(getLabelTaxes(taxes))}
              </Text>

              <PriceDetails board={board} />
            </View>

            <Button busy={busy} onPress={handlePress}>
              {translate(L10N.ACTION_VIEW_MORE)}
            </Button>
          </View>
        </View>
      </View>
    </Card>
  );
};

CardHotel.displayName = 'Mirai:Core:Rates:CardHotel';

CardHotel.propTypes = {
  board: PropTypes.shape({}),
  features: PropTypes.shape({
    distance: PropTypes.string,
    location: PropTypes.string,
    url: PropTypes.string,
  }),
  hasDiscountClub: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  images: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  nights: PropTypes.number,
  price: PropTypes.number,
  priceFactor: PropTypes.number,
  stars: PropTypes.number,
  taxes: PropTypes.number,
  unavailability: PropTypes.bool,
};

export { CardHotel };
