import { InputOption, InputPhone, InputSelect, InputText, Text } from '@mirai/ui';

import { InputRadio } from './components';

const INPUT = {
  TEXT: 0,
  SELECT: 1,
  OPTION: 2,
  PHONE: 3,
  RADIO: 4,
  HTML: 5,
};

const INPUT_TYPE = {
  TEXT: 0,
  EMAIL: 1,
  NUMBER: 2,
};

const FIELD = {
  [INPUT.OPTION]: InputOption,
  [INPUT.PHONE]: InputPhone,
  [INPUT.RADIO]: InputRadio,
  [INPUT.SELECT]: InputSelect,
  [INPUT.TEXT]: InputText,
  [INPUT.HTML]: Text,
};

const FORM_STORAGE_CACHE = 1000 * 60 * 60 * 24 * 7; // 7 days

const FORM_STORAGE_KEY = 'MIRAI:CHECKOUT:FORM';

const FORMAT_CURRENCY = { currency: 'EUR' };

const FORMAT_DATE = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

const FORMAT_DATE_SHORT = { weekday: 'short', month: 'short', day: 'numeric' };

const METRIC = 'CHECKOUT';

const REDIRECT_PATH = 'miraiFrontDispatcher.htm';

const VARIANTS = ['A', 'B'];

export {
  FIELD,
  FORM_STORAGE_CACHE,
  FORM_STORAGE_KEY,
  FORMAT_CURRENCY,
  FORMAT_DATE,
  FORMAT_DATE_SHORT,
  INPUT,
  INPUT_TYPE,
  METRIC,
  REDIRECT_PATH,
  VARIANTS,
};
