export const L10N = {
  ACTION_CLEAR_ALL: { id: 'common.action.clear_all' },
  ACTION_CLUB_VIEW_CONDITIONS: { id: 'finder.action.club_view_conditions' },
  ACTION_VIEW_MORE: { id: 'common.action.view_more' },

  LABEL_ACCOMMODATION_PLAN: { id: 'finder.label.accommodation_plan' },
  LABEL_AMENITIES: { id: 'finder.label.amenities' },
  LABEL_BED_TYPE: { id: 'finder.label.bed_type' },
  LABEL_BED_TYPE_DOUBLE: { id: 'finder.label.bed_type_double' },
  LABEL_BED_TYPE_SINGLE: { id: 'finder.label.bed_type_single' },
  LABEL_BOOKING_OPTIONS: { id: 'finder.label.booking_options' },
  LABEL_CLUB_MEMBERS_EXCLUSIVE: { id: 'finder.label.club_members_exclusive' },
  LABEL_CLUB_BENEFITS: { id: 'finder.label.club_benefits' },
  LABEL_CLUB_CONDITIONS: { id: 'finder.label.club_conditions' },
  LABEL_FREE_CANCELLATION: { id: 'finder.label.free_cancellation' },
  LABEL_FILTERS: { id: 'common.label.filters' },
  LABEL_NIGHTLY_PRICES: { id: 'common.label.nightly_prices' },
  LABEL_NO_EXACT_MATCHES: { id: 'finder.label.no_exact_matches' },
  LABEL_OTHERS: { id: 'common.label.others' },
  LABEL_PRICE_RANGE: { id: 'finder.label.price_range' },
  LABEL_ROOM_SIZE: { id: 'common.label.room_size' },
  LABEL_SET_PRICE_RANGE: { id: 'finder.label.set_price_range' },
  LABEL_SHOW_COUNT_RESULTS: { id: 'finder.label.show_count_results' },
  LABEL_SHOW_ONLY_DEALS: { id: 'common.label.show_only_deals' },
  LABEL_SHOW_ONLY_PROMOTION: { id: 'common.label.show_only_promotion' },
  LABEL_SHOW_ONLY_ROOM: { id: 'common.label.show_only_room' },
};
